import { BomFollowUpSymptomAllRes, IBomFollowUpSymptomDbModel, UnwrapAOSPayload } from "@kortex/aos-common";

import { APIPayload } from "../../utilitites/kortex-client/client";
import { handleAPIError } from "../handleAPIError";
import { AppState, StandardDispatch, StandardThunk } from "../store";

import {
    bomFollowUpSymptomInsertedAction,
    bomFollowUpSymptomUpdatedAction,
    setBomFollowUpSymptomListAction,
} from "./bom-follow-up-actions";

/**
 * Get all BOM follow-up symptom
 */
export function bomFollowUpSymptomGetAll(
    treeNodeId?: number,
    jobRefId?: string
): StandardThunk<UnwrapAOSPayload<BomFollowUpSymptomAllRes>> {
    return async function (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpSymptomAllRes>> {
        return api.services.bomFollowUpSymptom
            .getAll({ treeNodeId, jobRefId })()
            .then((bomFollowUpSymptom) => {
                dispatch(setBomFollowUpSymptomListAction([...bomFollowUpSymptom]));

                return bomFollowUpSymptom;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return [];
            });
    };
}

/**
 * Insert one BOM follow-up symptom
 */
export function bomFollowUpSymptomInsert(
    bomFollowUpSymptom: APIPayload<"bomFollowUpSymptom", "insert">
): StandardThunk<IBomFollowUpSymptomDbModel | undefined> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<IBomFollowUpSymptomDbModel | undefined> => {
        try {
            return api.services.bomFollowUpSymptom
                .insert(bomFollowUpSymptom)()
                .then((bomFollowUpSymptomInserted) => {
                    dispatch(bomFollowUpSymptomInsertedAction(bomFollowUpSymptomInserted));
                    return bomFollowUpSymptomInserted;
                });
        } catch (reason) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }

            return undefined;
        }
    };
}

/**
 * Update BOM follow-up symptom
 */
export function bomFollowUpSymptomUpdate(
    bomFollowUpSymptom: APIPayload<"bomFollowUpSymptom", "update">
): StandardThunk<IBomFollowUpSymptomDbModel | undefined> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<IBomFollowUpSymptomDbModel | undefined> => {
        try {
            return api.services.bomFollowUpSymptom
                .update(bomFollowUpSymptom)()
                .then((bomFollowUpSymptomUpdated) => {
                    dispatch(bomFollowUpSymptomUpdatedAction(bomFollowUpSymptomUpdated));
                    return bomFollowUpSymptomUpdated;
                });
        } catch (reason) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }

            return undefined;
        }
    };
}
