import { MessageLevelEnum, unwrapVariableIdentifier } from "@aos/process-sequencer";
import { deepClone } from "@kortex/utilities";
import { PROCESS_SYSTEM_VARIABLES, ProcessValidationProcessActionErrorKey, ProcessValidationProcessErrorKey, } from "../../Process";
import { ConditionMode, ConditionType, DataStoreActionTypeEnum, EnumConnectorType, EnumElementType, EnumFileAction, EnumRequestResponse, EnumRestMethod, FormItemTypeEnum, ProcessVariableType, } from "../../interfaces/models";
import { hasOutput, isCondition, isConnector, isDataStore, isFailureTicketFork, isInput, isLoop, isMath, isMessage, isOutput, isParser, isParserJSON, isParserRegexExtract, isParserRegexSubstitution, isParserRegexValidation, isRoutingProcess, isStopProcess, isTime, isWorkInstructions, } from "../processAction";
import { isFieldEmpty } from "../utilities";
import { findVariableIdentifiers } from "./findVariables";
let bomItems;
/**
 * Validate a step (generic)
 * - Validate all used variables
 * - Execute a validation callback specific to the action type
 *
 * @param {Readonly<ProcessAction>} processAction - action to validate
 * @param {Function} [cb] - callback to validate action-type-specific stuff
 * @param {ProcessValidationProcessActionStepValidationOptions} [options] - validation options
 */
function validateProcessActionSteps(processAction, cb, options) {
    const validation = {
        errors: [],
        warnings: [],
    };
    // Validate all steps from process action
    for (const [index, step] of processAction.steps.entries()) {
        // Validate variables
        if (options?.variables) {
            const usedVariables = findVariableIdentifiers(JSON.stringify(step));
            if (usedVariables !== null) {
                for (const usedVariable of usedVariables) {
                    if (!options.variables.concat(PROCESS_SYSTEM_VARIABLES).find((variable) => variable.identifier === usedVariable)) {
                        // Invalid variable identifier
                        validation.errors.push({
                            key: ProcessValidationProcessActionErrorKey.INVALID_VARIABLE_IDENTIFIER,
                            message: usedVariable,
                            processActionId: processAction.processActionId,
                            stepIndex: index,
                        });
                    }
                }
            }
        }
        // Step-type-specific stuff to validate
        if (cb) {
            cb(step, index, validation);
        }
    }
    return validation;
}
/**
 * Validate a condition step
 *
 * @param {ProcessActionCondition} processAction - action to validate
 * @param {ProcessValidationProcessActionStepValidationOptions} [options] - validation options
 */
function validateProcessActionStepsCondition(processAction, options) {
    return validateProcessActionSteps(processAction, (step, index, acc) => {
        for (const condition of step.config.conditions) {
            if (condition.mode === ConditionMode.EXPERT) {
                // Expert mode validations
                if (isFieldEmpty(condition.expert)) {
                    // The condition field is empty
                    acc.errors.push({
                        key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                        message: "action.condition.expert.condition",
                        processActionId: processAction.processActionId,
                        stepIndex: index,
                    });
                }
            } // End expert mode validations
            else {
                // Simplified mode validations
                if (isFieldEmpty(condition.simplified.leftOperand)) {
                    // Empty condition left operand field
                    acc.errors.push({
                        key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                        message: "action.condition.simplified.leftOperand",
                        processActionId: processAction.processActionId,
                        stepIndex: index,
                    });
                }
                if (isFieldEmpty(condition.simplified.rightOperand)) {
                    // Empty condition right operand field
                    acc.errors.push({
                        key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                        message: "action.condition.simplified.rightOperand",
                        processActionId: processAction.processActionId,
                        stepIndex: index,
                    });
                }
            } // End simplified mode validations
        }
    }, options);
}
/**
 * Validate a connector step
 *
 * @param {Readonly<ProcessActionConnector>} processAction - action to validate
 * @param {ProcessValidationProcessActionStepValidationOptions} [options] - validation options
 */
function validateProcessActionStepsConnector(processAction, options) {
    return validateProcessActionSteps(processAction, (step, index, acc) => {
        if (step.config.type === EnumConnectorType.FILE) {
            // Connector file validations
            const connectorFileProps = step.config.extendedProps;
            if (isFieldEmpty(connectorFileProps.filePath)) {
                // Empty file path field
                acc.errors.push({
                    key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                    processActionId: processAction.processActionId,
                    message: "action.connector.file.filePath",
                    stepIndex: index,
                });
            }
            if (connectorFileProps.action === EnumFileAction.READ && isFieldEmpty(step.config.storeTo.identifier)) {
                // Empty store data field while in read mode
                acc.errors.push({
                    key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                    processActionId: processAction.processActionId,
                    message: "action.connector.storeTo",
                    stepIndex: index,
                });
            }
            if (connectorFileProps.action === EnumFileAction.WRITE && isFieldEmpty(connectorFileProps.filePath)) {
                // Empty data field while in write mode
                acc.errors.push({
                    key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                    processActionId: processAction.processActionId,
                    message: "action.connector.file.action.write.data",
                    stepIndex: index,
                });
            }
        } // End connector file validation
        else if (step.config.type === EnumConnectorType.REST) {
            // Connector REST validations
            const connectorRestProps = step.config.extendedProps;
            if (connectorRestProps.method === EnumRestMethod.GET && isFieldEmpty(step.config.storeTo.identifier)) {
                // Empty store to field while using GET method
                acc.errors.push({
                    key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                    processActionId: processAction.processActionId,
                    message: "action.connector.storeTo",
                    stepIndex: index,
                });
            }
            if (isFieldEmpty(connectorRestProps.url)) {
                // Empty URL field
                acc.errors.push({
                    key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                    processActionId: processAction.processActionId,
                    message: "action.connector.rest.url",
                    stepIndex: index,
                });
            }
            for (const query of connectorRestProps.params.query) {
                if (isFieldEmpty(query.key)) {
                    // Empty query key field
                    acc.errors.push({
                        key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                        processActionId: processAction.processActionId,
                        message: "action.connector.rest.key",
                        stepIndex: index,
                    });
                }
                if (isFieldEmpty(query.value)) {
                    // Empty query value field
                    acc.errors.push({
                        key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                        processActionId: processAction.processActionId,
                        message: "action.connector.rest.value",
                        stepIndex: index,
                    });
                }
            }
            for (const header of connectorRestProps.params.header) {
                if (isFieldEmpty(header.key)) {
                    // Empty header key field
                    acc.errors.push({
                        key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                        processActionId: processAction.processActionId,
                        message: "action.connector.rest.key",
                        stepIndex: index,
                    });
                }
                if (isFieldEmpty(header.value)) {
                    // Empty header value field
                    acc.errors.push({
                        key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                        processActionId: processAction.processActionId,
                        message: "action.connector.rest.value",
                        stepIndex: index,
                    });
                }
            }
            if ((connectorRestProps.method === EnumRestMethod.POST || connectorRestProps.method === EnumRestMethod.PUT) &&
                connectorRestProps.contentType === EnumRequestResponse.APP_JSON) {
                // Validate body key/value only if content type is set to JSON and used method is either POST or PUT
                for (const body of connectorRestProps.params.body) {
                    if (isFieldEmpty(body.key)) {
                        // Empty body key field
                        acc.errors.push({
                            key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                            processActionId: processAction.processActionId,
                            message: "action.connector.rest.key",
                            stepIndex: index,
                        });
                    }
                    if (isFieldEmpty(body.value)) {
                        // Empty body value field
                        acc.errors.push({
                            key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                            processActionId: processAction.processActionId,
                            message: "action.connector.rest.value",
                            stepIndex: index,
                        });
                    }
                }
            }
        } // End connector rest validation
        else if (step.config.type === EnumConnectorType.SHELL) {
            // Connector shell validations
            const connectorShellProps = step.config.extendedProps;
            for (const command of connectorShellProps.commands) {
                if (isFieldEmpty(command)) {
                    // Empty command field
                    acc.errors.push({
                        key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                        processActionId: processAction.processActionId,
                        message: "action.connector.shell.command",
                        stepIndex: index,
                    });
                }
            }
        } // End connector shell validation
    }, options);
}
/**
 * Validate a data store step
 *
 * @param {Readonly<ProcessActionDataStore>} processAction - action to validate
 * @param {ProcessValidationProcessActionStepValidationOptions} [options] - validation options
 */
function validateProcessActionStepsDataStore(processAction, options) {
    return validateProcessActionSteps(processAction, (step, index, acc) => {
        // Validation for WRITE
        if (step.config.actionType === DataStoreActionTypeEnum.WRITE) {
            // Key value validation
            for (const keyValue of step.config.keyValueProps) {
                if (keyValue.groupId === -1) {
                    // Empty group field
                    acc.errors.push({
                        key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                        processActionId: processAction.processActionId,
                        message: "action.datastore.groupId",
                        stepIndex: index,
                    });
                }
                if (keyValue.subGroupId === -1) {
                    // Empty sub group field
                    acc.errors.push({
                        key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                        processActionId: processAction.processActionId,
                        message: "action.datastore.subGroupId",
                        stepIndex: index,
                    });
                }
                if (isFieldEmpty(keyValue.tag)) {
                    // Empty key field
                    acc.errors.push({
                        key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                        processActionId: processAction.processActionId,
                        message: "action.datastore.key",
                        stepIndex: index,
                    });
                }
                if (isFieldEmpty(keyValue.value)) {
                    // Empty value field
                    acc.errors.push({
                        key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                        processActionId: processAction.processActionId,
                        message: "action.datastore.value",
                        stepIndex: index,
                    });
                }
            } // End key value validation
            // Table validation
            for (const tableValue of step.config.tableValueProps) {
                if (tableValue.tableId === -1) {
                    // Empty table field
                    acc.errors.push({
                        key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                        processActionId: processAction.processActionId,
                        message: "action.datastore.table",
                        stepIndex: index,
                    });
                }
                // Column values validation
                for (const value of tableValue.columnsValue) {
                    if (isFieldEmpty(value)) {
                        // Empty column field
                        acc.errors.push({
                            key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                            processActionId: processAction.processActionId,
                            message: "action.datastore.column",
                            stepIndex: index,
                        });
                    }
                }
            } // End table validation
        } // End validation for WRITE
        else {
            // Validation for READ
            if (step.config.readItemProps) {
                // FIXME: Remove this validation when readItemProps is repaired
                for (const item of step.config.readItemProps) {
                    if (isFieldEmpty(item.reqColumn)) {
                        // Empty requested column field
                        acc.errors.push({
                            key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                            processActionId: processAction.processActionId,
                            message: "action.datastore.reqColumn",
                            stepIndex: index,
                        });
                    }
                    if (isFieldEmpty(item.storeTo.identifier)) {
                        // Empty store to field
                        acc.errors.push({
                            key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                            processActionId: processAction.processActionId,
                            message: "action.datastore.storeTo",
                            stepIndex: index,
                        });
                    }
                }
            }
        } // End validation for READ
    }, options);
}
/**
 * Validate an input step
 *
 * @param {Readonly<ProcessActionInput>} processAction - action to validate
 * @param {ProcessValidationProcessActionStepValidationOptions} [options] - validation options
 */
function validateProcessActionStepsInput(processAction, options) {
    return validateProcessActionSteps(processAction, () => {
        // No input-specific validation required
    }, options);
}
/**
 * Validate a loop step
 *
 * @param {Readonly<ProcessActionLoop>} processAction -
 */
function validateProcessActionStepsLoop(processAction, options) {
    return validateProcessActionSteps(processAction, (step, index, acc) => {
        if (isFieldEmpty(step.config.maxLoopCount.toString())) {
            // Empty max loop count field
            acc.errors.push({
                key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                processActionId: processAction.processActionId,
                message: "action.loop.maxLoopCount",
                stepIndex: index,
            });
        }
        // Condition validation
        for (const condition of step.config.conditions) {
            if (condition.mode === ConditionMode.EXPERT) {
                //
                // Expert mode validations
                //
                if (isFieldEmpty(condition.expert)) {
                    // The condition field is empty
                    acc.errors.push({
                        key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                        message: "action.condition.expert.condition",
                        processActionId: processAction.processActionId,
                        stepIndex: index,
                    });
                }
            } // End expert mode validations
            else {
                //
                // Simplified mode validations
                //
                // Find the variable in the left operand
                const leftOperandAsVariable = options?.variables?.find((variable) => "${" + variable.identifier + "}" === condition.simplified.leftOperand);
                // Find the variable in the right operand
                const rightOperandAsVariable = options?.variables?.find((variable) => "${" + variable.identifier + "}" === condition.simplified.rightOperand);
                // If the condition type is NUMBER, and if leftOperand or rightOperand are variables, they
                // must also be of type ProcessVariableType.NUMBER.
                if (condition.simplified.type === ConditionType.NUMBER &&
                    ((leftOperandAsVariable && leftOperandAsVariable.type !== ProcessVariableType.NUMBER) ||
                        (rightOperandAsVariable && rightOperandAsVariable.type !== ProcessVariableType.NUMBER))) {
                    acc.errors.push({
                        key: ProcessValidationProcessActionErrorKey.TYPE_MISMATCH,
                        message: "action.condition.simplified.typeMismatch",
                        processActionId: processAction.processActionId,
                        stepIndex: index,
                    });
                }
                // If the condition type is NUMBER, and the operands are not variables, they should be castable to numbers.
                if (condition.simplified.type === ConditionType.NUMBER &&
                    ((!leftOperandAsVariable && isNaN(+condition.simplified.leftOperand)) ||
                        (!rightOperandAsVariable && isNaN(+condition.simplified.rightOperand)))) {
                    acc.errors.push({
                        key: ProcessValidationProcessActionErrorKey.TYPE_MISMATCH,
                        message: "action.condition.simplified.typeMismatch",
                        processActionId: processAction.processActionId,
                        stepIndex: index,
                    });
                }
                if (isFieldEmpty(condition.simplified.leftOperand)) {
                    // Empty condition left operand field
                    acc.errors.push({
                        key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                        message: "action.condition.simplified.leftOperand",
                        processActionId: processAction.processActionId,
                        stepIndex: index,
                    });
                }
                if (isFieldEmpty(condition.simplified.rightOperand)) {
                    // Empty condition right operand field
                    acc.errors.push({
                        key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                        message: "action.condition.simplified.rightOperand",
                        processActionId: processAction.processActionId,
                        stepIndex: index,
                    });
                }
            } // End simplified mode validations
        } // End condition validation
    }, options);
}
/**
 * Validate a math step
 *
 * @param {Readonly<ProcessActionMath>} processAction - action to validate
 * @param {ProcessValidationProcessActionStepValidationOptions} [options] - validation options
 */
function validateProcessActionStepsMath(processAction, options) {
    return validateProcessActionSteps(processAction, (step, index, acc) => {
        if (isFieldEmpty(step.config.equation)) {
            // Empty equation field
            acc.errors.push({
                key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                processActionId: processAction.processActionId,
                message: "action.math.equation",
                stepIndex: index,
            });
        }
        if (isFieldEmpty(step.config.storeTo.identifier)) {
            // Empty store to field
            acc.errors.push({
                key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                processActionId: processAction.processActionId,
                message: "action.math.storeTo",
                stepIndex: index,
            });
        }
    }, options);
}
/**
 *
 * @param {Readonly<ProcessActionOutput>} processAction - action to validate
 */
function validateProcessActionStepsOutput(processAction) {
    return validateProcessActionSteps(processAction);
}
/**
 * Validate a parser step
 *
 * @param {Readonly<ProcessActionParser>} processAction - action to validate
 * @param {ProcessValidationProcessActionStepValidationOptions} [options] - validation options
 */
function validateProcessActionStepsParser(processAction, options) {
    return validateProcessActionSteps(processAction, (step, index, acc) => {
        // Parser general validations
        {
            if (isFieldEmpty(step.config.source)) {
                // Empty source field
                acc.errors.push({
                    key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                    processActionId: processAction.processActionId,
                    message: "action.parser.source",
                    stepIndex: index,
                });
            }
            if (isFieldEmpty(step.config.storeTo.identifier)) {
                // Empty source field
                acc.errors.push({
                    key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                    processActionId: processAction.processActionId,
                    message: "action.parser.storeTo",
                    stepIndex: index,
                });
            }
        } // End parser general validation
        if (isParserJSON(step.config)) {
            // JSON parser validation
            if (isFieldEmpty(step.config.parser.path)) {
                // Empty path field
                acc.errors.push({
                    key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                    processActionId: processAction.processActionId,
                    message: "action.parser.jsonPath",
                    stepIndex: index,
                });
            }
        } // End JSON parser validation
        else if (isParserRegexExtract(step.config)) {
            // Regex extraction parser validation
            if (isFieldEmpty(step.config.parser.endRegex)) {
                // Empty end regex field
                acc.errors.push({
                    key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                    processActionId: processAction.processActionId,
                    message: "action.parser.regexEnd",
                    stepIndex: index,
                });
            }
            if (isFieldEmpty(step.config.parser.startRegex)) {
                // Empty start regex field
                acc.errors.push({
                    key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                    processActionId: processAction.processActionId,
                    message: "action.parser.regexStart",
                    stepIndex: index,
                });
            }
        } // End regex extraction parser validation
        else if (isParserRegexSubstitution(step.config)) {
            // Regex substitution parser validation
            if (isFieldEmpty(step.config.parser.regex)) {
                // Empty regex field
                acc.errors.push({
                    key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                    processActionId: processAction.processActionId,
                    message: "action.parser.regex",
                    stepIndex: index,
                });
            }
        } // End regex substitution parser validation
        else if (isParserRegexValidation(step.config)) {
            // Regex validation parser validation
            if (isFieldEmpty(step.config.parser.regex)) {
                // Empty regex field
                acc.errors.push({
                    key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                    processActionId: processAction.processActionId,
                    message: "action.parser.regex",
                    stepIndex: index,
                });
            }
        } // End Regex validation parser validation
    }, options);
}
/**
 * Validate a routing process step
 *
 * @param {Readonly<ProcessActionRoutingProcess>} processAction - action to validate
 * @param {ProcessValidationProcessActionStepValidationOptions} [options] - validation options
 */
function validateProcessActionStepsRoutingProcess(processAction, options) {
    return validateProcessActionSteps(processAction, (step, index, acc) => {
        if (step.config.treeNodeId === null) {
            // Empty process field
            acc.errors.push({
                key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                processActionId: processAction.processActionId,
                message: "action.routingProcess.process",
                stepIndex: index,
            });
        }
    }, options);
}
/**
 * Validate a stop process step
 *
 * @param {Readonly<ProcessActionStopProcess>} processAction - action to validate
 */
function validateProcessActionStepsStopProcess(processAction) {
    return validateProcessActionSteps(processAction);
}
/**
 * Validate a time step
 *
 * @param {Readonly<ProcessActionTime>} processAction - action to validate
 * @param {ProcessValidationProcessActionStepValidationOptions} [options] - validation options
 */
function validateProcessActionStepsTime(processAction, options) {
    return validateProcessActionSteps(processAction, (step, index, acc) => {
        if (step.config.type === "conversion") {
            // Time conversion validation
            if (isFieldEmpty(step.config.value)) {
                // Empty process field
                acc.errors.push({
                    key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                    processActionId: processAction.processActionId,
                    message: "action.time.value",
                    stepIndex: index,
                });
            }
            if (isFieldEmpty(step.config.storeTo.identifier)) {
                // Empty process field
                acc.errors.push({
                    key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                    processActionId: processAction.processActionId,
                    message: "action.time.storeTo",
                    stepIndex: index,
                });
            }
        } // End time conversion validation
        else if (step.config.type === "countdown") {
            // Countdown validation
            if (isFieldEmpty(step.config.value)) {
                // Empty process field
                acc.errors.push({
                    key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                    processActionId: processAction.processActionId,
                    message: "action.time.value",
                    stepIndex: index,
                });
            }
            if (isFieldEmpty(step.config.storeTo.identifier)) {
                // Empty process field
                acc.errors.push({
                    key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                    processActionId: processAction.processActionId,
                    message: "action.time.storeTo",
                    stepIndex: index,
                });
            }
        } // End countdown validation
        else if (step.config.type === "timer") {
            // Timer validation
            if (isFieldEmpty(step.config.function)) {
                // Empty process field
                acc.errors.push({
                    key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                    processActionId: processAction.processActionId,
                    message: "action.time.function",
                    stepIndex: index,
                });
            }
            if (isFieldEmpty(step.config.storeTo.identifier)) {
                // Empty process field
                acc.errors.push({
                    key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                    processActionId: processAction.processActionId,
                    message: "action.time.storeTo",
                    stepIndex: index,
                });
            }
        } // End timer validation
        else if (step.config.type === "wait") {
            // Wait validation
            if (isFieldEmpty(step.config.value)) {
                // Empty process field
                acc.errors.push({
                    key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                    processActionId: processAction.processActionId,
                    message: "action.time.value",
                    stepIndex: index,
                });
            }
        } // End wait validation
    }, options);
}
/**
 * Validate a work instruction step
 *
 * @param {Readonly<ProcessActionWorkInstruction>} processAction - action to validate
 * @param {ProcessValidationProcessActionStepValidationOptions} [options] - validation options
 */
function validateProcessActionStepsWorkInstruction(processAction, options) {
    return validateProcessActionSteps(processAction, (step, index, acc) => {
        for (const element of step.config.workInstructionElements) {
            if (element.type === EnumElementType.FORM) {
                for (const formInput of element.extendedProps.formItems) {
                    // General form input validations
                    {
                        if (isFieldEmpty(formInput.label)) {
                            // Empty label field
                            acc.errors.push({
                                key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                                processActionId: processAction.processActionId,
                                message: "action.workInstructions.form.formItemLabel",
                                stepIndex: index,
                            });
                        }
                    } // End general form input validations
                    // Type-specific form input validations
                    if (formInput.type === FormItemTypeEnum.APPROVAL) {
                        // TODO: Add validation when implemented
                    } // End approval form input validation
                    else if (formInput.type === FormItemTypeEnum.CHECKBOX) {
                        // Checkbox form input validation
                        if (isFieldEmpty(formInput.storeTo.identifier)) {
                            // Empty store to field
                            acc.errors.push({
                                key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                                processActionId: processAction.processActionId,
                                message: "action.workInstructions.form.storeTo",
                                stepIndex: index,
                            });
                        }
                    } // End checkbox form input validation
                    else if (formInput.type === FormItemTypeEnum.CHECKLIST) {
                        // Checklist form input validation
                        if (isFieldEmpty(formInput.subValueString)) {
                            // Empty list field
                            acc.errors.push({
                                key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                                processActionId: processAction.processActionId,
                                message: "action.workInstructions.form.list",
                                stepIndex: index,
                            });
                        }
                        if (isFieldEmpty(formInput.storeTo.identifier)) {
                            // Empty store to field
                            acc.errors.push({
                                key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                                processActionId: processAction.processActionId,
                                message: "action.workInstructions.form.storeTo",
                                stepIndex: index,
                            });
                        }
                    } // End checklist form input validation
                    else if (formInput.type === FormItemTypeEnum.DROPDOWN) {
                        // Dropdown form input validation
                        if (isFieldEmpty(formInput.subValueString)) {
                            // Empty list field
                            acc.errors.push({
                                key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                                processActionId: processAction.processActionId,
                                message: "action.workInstructions.form.list",
                                stepIndex: index,
                            });
                        }
                        if (isFieldEmpty(formInput.storeTo.identifier)) {
                            // Empty store to field
                            acc.errors.push({
                                key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                                processActionId: processAction.processActionId,
                                message: "action.workInstructions.form.storeTo",
                                stepIndex: index,
                            });
                        }
                    } // End dropdown form input validation
                    else if (formInput.type === FormItemTypeEnum.LABEL) {
                        // Label form input validation
                        // No validation required for label
                    } // End label form input validation
                    else if (formInput.type === FormItemTypeEnum.RADIO) {
                        // Radio form input validation
                        if (isFieldEmpty(formInput.subValueString)) {
                            // Empty list field
                            acc.errors.push({
                                key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                                processActionId: processAction.processActionId,
                                message: "action.workInstructions.form.list",
                                stepIndex: index,
                            });
                        }
                        if (isFieldEmpty(formInput.storeTo.identifier)) {
                            // Empty store to field
                            acc.errors.push({
                                key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                                processActionId: processAction.processActionId,
                                message: "action.workInstructions.form.storeTo",
                                stepIndex: index,
                            });
                        }
                    } // End radio form input validation
                    else if (formInput.type === FormItemTypeEnum.TEXTBOX) {
                        // Textbox form input validation
                        if (isFieldEmpty(formInput.storeTo.identifier)) {
                            // Empty store to field
                            acc.errors.push({
                                key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                                processActionId: processAction.processActionId,
                                message: "action.workInstructions.form.storeTo",
                                stepIndex: index,
                            });
                        }
                    } // End textbox form input validation
                }
            }
        }
        // Validate bom items assigned to the step.
        if (bomItems) {
            for (const partNumber in step.config.bomItems) {
                if (!bomItems[partNumber]) {
                    acc.errors.push({
                        key: ProcessValidationProcessActionErrorKey.ITEM_BOM,
                        processActionId: processAction.processActionId,
                        message: "processEditor.validation.errorCode.processAction.bomItemMessage",
                        stepIndex: index,
                        bomItemPartNumber: partNumber,
                    });
                }
            }
        }
    }, options);
}
/**
 * Validate a routing process step
 *
 * @param {Readonly<ProcessActionFailureTicketCreate>} processAction - action to validate
 * @param {ProcessValidationProcessActionStepValidationOptions} [options] - validation options
 */
function validateProcessActionStepsFailureTicketFork(processAction, options) {
    return validateProcessActionSteps(processAction, (step, index, acc) => {
        if (isFieldEmpty(step.config.workOrder)) {
            // Empty work order field
            acc.errors.push({
                key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                processActionId: processAction.processActionId,
                message: "action.routingProcess.process",
                stepIndex: index,
            });
        }
    }, options);
}
/**
 * Validate a Message step
 *
 * @param {Readonly<ProcessActionMessage>} processAction - action to validate
 * @param {ProcessValidationProcessActionStepValidationOptions} [options] - validation options
 */
function validateProcessActionStepsMessage(processAction, options) {
    return validateProcessActionSteps(processAction, (step, index, acc) => {
        if (isFieldEmpty(step.config.message)) {
            // Empty message field
            acc.errors.push({
                key: ProcessValidationProcessActionErrorKey.EMPTY_FIELD,
                processActionId: processAction.processActionId,
                message: "action.message.message",
                stepIndex: index,
            });
        }
        if (Object.values(MessageLevelEnum).includes(step.config.level)) {
            // Level unknown
            acc.errors.push({
                key: ProcessValidationProcessActionErrorKey.VALUE_NOT_VALID,
                processActionId: processAction.processActionId,
                message: "action.message.level",
                stepIndex: index,
            });
        }
    }, options);
}
/**
 * Resursively validate process actions
 * - Validate each step of the action (steps, variables, fields, etc.)
 *
 * @param {number[]} validatedActionIds - list of validate action ids
 * @param {ProcessAction} current - current action to validate
 * @param {ProcessAction[]} actions - current action's process
 * @param {IProcessVariable[]} variables - variables of process
 * @param {(current: ProcessAction) => void} [beforeValidationCb] - callback executed before the validation
 */
function validateProcessAction(// TODO: Add Loop validation and Set validation
validatedActionIds, current, actions, variables, beforeValidationCb) {
    let validation = {
        errors: [],
        warnings: [],
    };
    // Check if this action as been already validated
    if (validatedActionIds.includes(current.processActionId)) {
        // Yes, escape
        return validation;
    }
    if (beforeValidationCb) {
        beforeValidationCb(current);
    }
    // Add the action to the list of validated action, to avoid infinite recursive loop
    validatedActionIds.push(current.processActionId);
    // PROCESS ACTION STEPS VALIDATION
    {
        if (isCondition(current)) {
            validation = validateProcessActionStepsCondition(current, { variables });
        }
        else if (isConnector(current)) {
            validation = validateProcessActionStepsConnector(current, { variables });
        }
        else if (isDataStore(current)) {
            validation = validateProcessActionStepsDataStore(current, { variables });
        }
        else if (isInput(current)) {
            validation = validateProcessActionStepsInput(current, { variables });
        }
        else if (isLoop(current)) {
            validation = validateProcessActionStepsLoop(current, { variables });
        }
        else if (isMath(current)) {
            validation = validateProcessActionStepsMath(current, { variables });
        }
        else if (isOutput(current)) {
            validation = validateProcessActionStepsOutput(current);
        }
        else if (isParser(current)) {
            validation = validateProcessActionStepsParser(current, { variables });
        }
        else if (isRoutingProcess(current)) {
            validation = validateProcessActionStepsRoutingProcess(current);
        }
        else if (isStopProcess(current)) {
            validation = validateProcessActionStepsStopProcess(current);
        }
        else if (isTime(current)) {
            validation = validateProcessActionStepsTime(current, { variables });
        }
        else if (isWorkInstructions(current)) {
            validation = validateProcessActionStepsWorkInstruction(current, { variables });
        }
        else if (isFailureTicketFork(current)) {
            validation = validateProcessActionStepsFailureTicketFork(current, { variables });
        }
        else if (isMessage(current)) {
            validation = validateProcessActionStepsMessage(current, { variables });
        }
    } // END PROCESS ACTION STEPS VALIDATION
    // OUTPUT LINKS VALIDATION
    {
        // GENERAL OUTPUT LINK VALIDATION
        // If action does not require any output, skip validation
        if (!hasOutput(current)) {
            return validation;
        }
        // Browse the list of action links until the Output is reached
        for (const output of current.outputs) {
            // Check the next action link to validate
            if (output.remoteIds[0]?.isReturn) {
                continue; // If it is a loop "Return" input, skip this iteration
            }
            const nextProcessActionId = output.remoteIds[0]?.actionId;
            if (nextProcessActionId) {
                // Check if the action can be found in the process action list
                const nextProcessAction = actions.find((action) => action.processActionId === nextProcessActionId);
                if (nextProcessAction) {
                    // Recursivity! (O_o)
                    const nextProcessActionValidation = validateProcessAction(validatedActionIds, nextProcessAction, actions, variables, beforeValidationCb);
                    validation.errors.push(...nextProcessActionValidation.errors);
                    validation.warnings.push(...nextProcessActionValidation.warnings);
                }
                else {
                    // Action cannot be found! (should never happen, but just in case...)
                    validation.errors.push({
                        key: ProcessValidationProcessActionErrorKey.INVALID_ACTION_ID,
                        processActionId: nextProcessActionId,
                    });
                    // throw new Error(`Could not find the following process action: ${nextProcessActionId}.`);
                }
            }
            else {
                // The action is missing an output link
                validation.errors.push({
                    key: ProcessValidationProcessActionErrorKey.MISSING_OUTPUT_LINK,
                    processActionId: current.processActionId,
                });
            }
        }
    } // END OUTPUT LINKS VALIDATION
    return validation;
}
/**
 * Validate a process
 * - Validate that the INPUT and OUTPUT exist
 * - Validate each action (steps, variables, fields, etc.)
 * - Validate all output links
 *
 * @param {ProcessUiModel} process - process to validate
 */
export function validateProcess(process, bom) {
    bomItems = bom;
    // Error/warning accumulator
    const validation = {
        process: {
            errors: [],
            warnings: [],
        },
        processAction: {
            errors: [],
            warnings: [],
        },
        failPath: {
            errors: [],
            warnings: [],
        },
    };
    const loopProcessActionIds = []; // processActionId of all loop actions
    const loopReturnProcessActionIds = []; // processActionId of all actions that have output link to a loop "return" input
    const variables = deepClone(process.variables.concat(PROCESS_SYSTEM_VARIABLES));
    // Find the input action
    const input = process.actions.find((action) => action.type === "core-input");
    // Find the Set action
    const actionsSet = process.actions.filter((action) => action.type === "core-set");
    for (const actionSet of actionsSet) {
        for (const step of actionSet.steps) {
            const stepActionSet = step;
            // Add Set action variables to the list of process variables.
            // The Set action can create a variable.
            // So it is possible that the variable of the Set action is not in the variable manager and blocks the validation of the process.
            if (stepActionSet.config.variables[0] &&
                !Boolean(variables.find((variable) => variable.identifier === unwrapVariableIdentifier(stepActionSet.config.variables[0].identifier)))) {
                variables.push({
                    ...stepActionSet.config.variables[0],
                    identifier: unwrapVariableIdentifier(stepActionSet.config.variables[0].identifier),
                });
            }
        }
    }
    // Browse the list of action links until the Output is reached
    if (input) {
        let outputFound = false;
        const validatedActionIds = []; // List of action already validated
        validation.processAction = validateProcessAction(validatedActionIds, input, process.actions, variables, (processAction) => {
            // Check whether or not the Output is reached
            if (isOutput(processAction)) {
                outputFound = true;
            }
            else if (isLoop(processAction)) {
                // Save loop processActionId
                loopProcessActionIds.push(processAction.processActionId);
            }
            // Save any processActionId from an action that has an output link to a loop "return" input
            for (const output of processAction.outputs) {
                if (output.remoteIds[0]?.actionId && output.remoteIds[0]?.isReturn) {
                    // FIXME: this condition takes for granted that only loop actions contain a "return" input.
                    // In the future, if a new action contains a return input, change this condition accordingly.
                    loopReturnProcessActionIds.push(output.remoteIds[0].actionId);
                }
            }
        });
        // If no Output was found, add an error to the list
        if (!outputFound) {
            validation.process.errors.push({
                key: ProcessValidationProcessErrorKey.OUTPUT_NOT_FOUND,
                processId: process.processId,
            });
        }
        // Check if all loop "return" input are connected to another action
        for (const loopId of loopProcessActionIds) {
            if (!loopReturnProcessActionIds.some((remoteId) => remoteId === loopId)) {
                validation.processAction.errors.push({
                    key: ProcessValidationProcessActionErrorKey.MISSING_LOOP_RETURN_INPUT,
                    processActionId: loopId,
                });
            }
        }
        // Validate Failing Path
        const failAction = process.actions.find((action) => action.type === "core-fail");
        if (failAction) {
            outputFound = false;
            const validatedFailPathActionIds = []; // List of action already validated
            validation.failPath = validateProcessAction(validatedFailPathActionIds, failAction, process.actions, variables, (processAction) => {
                // Check whether or not the Output is reached
                if (isOutput(processAction)) {
                    outputFound = true;
                }
            });
            // If no Output was found, add an error to the list
            if (!outputFound) {
                validation.process.errors.push({
                    key: ProcessValidationProcessErrorKey.OUTPUT_NOT_FOUND_FAILPATH,
                    processId: process.processId,
                });
            }
        }
    }
    else {
        // Input was not found (should never happen, but just in case...)
        validation.process.errors.push({
            key: ProcessValidationProcessErrorKey.INPUT_NOT_FOUND,
            processId: process.processId,
        });
    }
    bomItems = undefined;
    return validation;
}
