import { greyPalette, theme } from "@aos/react-components";
import {
    ApprovalStatusEnum,
    IUserDbModel,
    OrUndefined,
    ProcessId,
    ProcessReleaseStatus,
    ProcessUiModel,
    Task,
    TreeNodeId,
    getProcessReleaseStatus,
} from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import {
    AppBar,
    Backdrop,
    CircularProgress,
    Dialog,
    DialogContent,
    IconButton,
    Paper,
    Slide,
    SlideProps,
    Toolbar,
    Typography,
    makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import * as React from "react";
import { useEffect, useState } from "react";

import PrintProcessDialog from "../../../../../components/pages/ProcessPrint/PrintProcessDialog";
import { useForeground } from "../../../../../hooks/useForeground";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { useEntitiesTasks, useEntitiesTreeProcess, useEntitiesUsers, useEntitiesUsersGroups } from "../../../../../redux/effects";
import {
    processVersionApprove,
    processVersionGetAll,
    processVersionUpdateReleaseDates,
} from "../../../../../redux/process-manager/process-thunks-process";
import { useSelectorProcesses } from "../../../../../redux/selectors";
import { getLastestProcessVersion, sortProcessByVersions } from "../../../../../utilitites/sortProcessByVersions";

import ProcessApprovalCard, { getProcessReleaseStatusLabelKey, getProcessVersionStatusLabelKey } from "./ProcessApprovalCard";
import ProcessApprovalCardEditor from "./ProcessApprovalCardEditor";
import ProcessApprovalSearchBar, { IFilters, defaultFilters } from "./ProcessApprovalSearchBar";

const Transition = React.forwardRef((props: SlideProps, ref): JSX.Element => <Slide {...props} direction="up" ref={ref} />);
Transition.displayName = "SlideUp";

const useStyles = makeStyles({
    content: {
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        columnGap: "16px",
        marginTop: "16px",
        height: "calc(100vh - 118px)",
    },
    backdrop: {
        color: theme.palette.common.white,
        zIndex: theme.zIndex.drawer + 1,
    },
    dialogContent: {
        backgroundColor: greyPalette[200],
    },
    processApprovalCardEditorContainer: {
        overflowY: "auto",
    },
    searchContainer: {
        justifyItems: "center",
        marginBottom: "16px",
        padding: "10px",
    },
    toolbar: {
        display: "grid",
        gridTemplateColumns: "1fr auto",
        height: "75px",
    },
    versionListContainer: {
        width: "40vw",
        minWidth: "490px",
        paddingRight: "2px",
    },
    versionList: {
        height: "726px",
        overflowY: "auto",
    },
});

interface IOwnProps {
    // Element own props
    open: boolean;
    onClose: () => void;
    readOnly: boolean;
    processId: ProcessId;
    treeNodeId: TreeNodeId;
}

export default function ProcessApprovalEditor(props: IOwnProps): JSX.Element {
    const { open, processId, treeNodeId, readOnly } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const processVersions = useSelectorProcesses((process) => process.treeNodeId === treeNodeId);
    const processLabel = useEntitiesTreeProcess().find((treeNode) => treeNode.treeNodeId === treeNodeId)?.label;
    const translate = useTranslate();

    const [selectedVersionId, setSelectedVersionId] = useState<OrUndefined<number>>(undefined);
    const [lastVersion, setLastVersion] = useState(getLastestProcessVersion(processVersions));
    const [printOpen, setPrintOpen] = useState(false);
    const [printedProcess, setPrintedProcess] = useState<OrUndefined<ProcessUiModel>>(undefined);
    const [filters, setFilters] = useState<IFilters>(defaultFilters);
    const [isLoading, setIsLoading] = useState(false);

    useForeground(open);

    const userList = useEntitiesUsers();
    const tasks = useEntitiesTasks({
        linkId: selectedVersionId,
        type: Task.TYPE.PROCESS_APPROVAL,
    });
    const userGroups = useEntitiesUsersGroups();

    /**
     * On open, fetches process version
     * On close, unselects any selected version and reset process versions
     */
    useEffect((): void => {
        if (open) {
            const selectedProcess = processVersions.find((process) => process.processId === processId);

            if (selectedProcess) {
                setSelectedVersionId(selectedProcess.processId);
                dispatch(processVersionGetAll(selectedProcess.treeNodeId));
            }
        } else {
            setSelectedVersionId(undefined);
        }
    }, [open]);

    /**
     * Keeps the last version updated
     */
    useEffect(() => {
        setLastVersion(getLastestProcessVersion(processVersions));
    }, [processId, processVersions.length]);

    /**
     * Apply filters from search bar to version list
     */
    const applyFilters = (versions: ProcessUiModel[]): ProcessUiModel[] => {
        let filteredVersions = [...versions];

        // APPROVAL GROUP
        if (filters.approvalGroup.length > 0) {
            // Find groups in user task
            filteredVersions = filteredVersions.filter(() => {
                if (!tasks) {
                    return false;
                }

                return tasks.some((task) => {
                    // Find the approval group from the user task
                    const userGroup = userGroups.find((userGroup) => userGroup.userGroupId === task.groupId);

                    if (userGroup) {
                        // Find if there is a match between the filter text and the approval group name
                        for (const approvalGroup of filters.approvalGroup) {
                            if (userGroup.name === approvalGroup) {
                                return true;
                            }
                        }
                    }

                    return false;
                });
            });
        }

        // APPROVAL STATUS
        if (filters.approvalStatus.length > 0) {
            filteredVersions = filteredVersions.filter((version) => filters.approvalStatus.includes(String(version.versionStatus)));
        }

        // APPROVERS
        if (filters.approvedBy) {
            // Find groups in user task
            filteredVersions = filteredVersions.filter(() => {
                if (!tasks) {
                    return false;
                }

                return tasks.some((task) =>
                    // Go through all approver info
                    task.info.approverInfo.some((approver) => {
                        if (!userList) {
                            return false;
                        }

                        // Find the user info of the approver
                        const user = userList.find((user) => user.userId === approver.userId);

                        // Validate that the filter text is found in the user full name
                        return Boolean(user && (user.firstName + " " + user.lastName).includes(filters.approvedBy));
                    })
                );
            });
        }

        // CHANGELOG
        if (filters.changelog) {
            filteredVersions = filteredVersions.filter((version) =>
                Boolean(version.versionChangeLog && version.versionChangeLog.includes(filters.changelog))
            );
        }

        // PLAIN TEXT
        if (filters.plainText !== "") {
            filteredVersions = filteredVersions.filter((version) => {
                if (!userList || !userGroups || !tasks) {
                    return false;
                }

                // Approval groups name
                const approvalGroupsName = tasks.map((task) => {
                    const userGroup = userGroups.find((userGroup) => userGroup.userGroupId === task.groupId);
                    return userGroup ? userGroup.name : "";
                });

                // Approvers name
                const approversFullName: string[] = [];
                for (const task of tasks) {
                    for (const approverInfo of task.info.approverInfo) {
                        const user = userList.find((user) => user.userId === approverInfo.userId);
                        if (user) {
                            approversFullName.push(user.firstName + " " + user.lastName);
                        }
                    }
                }

                // User who released the version
                const versionReleaserUserInfo = userList.find((user) => user.userId === version.versionReleasedByUserId);

                // User who submitted the version
                const versionSubmitterUserInfo = userList.find((user) => user.userId === version.versionCreatorId);

                return (
                    approvalGroupsName.includes(filters.plainText) || // Approval group names
                    approversFullName.includes(filters.plainText) || // Approver full names
                    translate(getProcessVersionStatusLabelKey(version.versionStatus)).includes(filters.plainText) || // Approval status
                    Boolean(version.versionChangeLog && version.versionChangeLog.includes(filters.plainText)) || // Changelog
                    Boolean(version.versionReferenceCode && version.versionReferenceCode.includes(filters.plainText)) || // Reference id
                    translate(getProcessReleaseStatusLabelKey(version)).includes(filters.plainText) || // Release status
                    Boolean(
                        versionReleaserUserInfo &&
                            (versionReleaserUserInfo.firstName + " " + versionReleaserUserInfo.lastName).includes(filters.plainText)
                    ) || // User who released the version
                    Boolean(
                        versionSubmitterUserInfo &&
                            (versionSubmitterUserInfo.firstName + " " + versionSubmitterUserInfo.lastName).includes(filters.plainText)
                    ) || // User who submitted the version
                    Boolean(version.version && version.version.includes(filters.plainText)) // Process version
                );
            });
        }

        // REFERENCE ID
        if (filters.referenceId) {
            filteredVersions = filteredVersions.filter((version) =>
                Boolean(version.versionReferenceCode && version.versionReferenceCode.includes(filters.referenceId))
            );
        }

        // RELEASE DATE
        if (filters.releasedDateFrom) {
            filteredVersions = filteredVersions.filter((version) =>
                Boolean(version.releasedOn && version.releasedOn >= filters.releasedDateFrom)
            );
        }

        if (filters.releasedDateTo) {
            filteredVersions = filteredVersions.filter((version) =>
                Boolean(version.releasedOn && version.releasedOn <= filters.releasedDateTo)
            );
        }

        // RELEASE STATUS
        if (filters.releaseStatus.length > 0) {
            filteredVersions = filteredVersions.filter((version) =>
                filters.releaseStatus.includes(translate(getProcessReleaseStatusLabelKey(version)))
            );
        }

        // USER WHO RELEASED THE VERSION
        if (filters.releasedBy && userList) {
            filteredVersions = filteredVersions.filter((version) => {
                const user = userList.find((user: IUserDbModel) => user.userId === version.versionReleasedByUserId);
                return Boolean(user && (user.firstName + " " + user.lastName).includes(filters.releasedBy));
            });
        }

        // RETIRE DATE
        if (filters.retiredDateFrom) {
            filteredVersions = filteredVersions.filter((version) =>
                Boolean(version.retiredOn && version.retiredOn >= filters.retiredDateFrom)
            );
        }

        if (filters.retiredDateTo) {
            filteredVersions = filteredVersions.filter((version) =>
                Boolean(version.retiredOn && version.retiredOn <= filters.retiredDateFrom)
            );
        }

        // ARCHIVED
        if (!filters.showArchived) {
            filteredVersions = filteredVersions.filter((version) => Boolean(!version.archived));
        }

        // SUBMISSION DATE
        if (filters.submissionDateFrom) {
            filteredVersions = filteredVersions.filter((version) =>
                Boolean(version.createdOn && version.createdOn >= filters.submissionDateFrom)
            );
        }

        if (filters.submissionDateTo) {
            filteredVersions = filteredVersions.filter((version) =>
                Boolean(version.createdOn && version.createdOn <= filters.submissionDateTo)
            );
        }

        // VERSION CREATOR
        if (filters.submittedBy && userList) {
            filteredVersions = filteredVersions.filter((version) => {
                const user = userList.find((user) => user.userId === version.versionCreatorId);
                return Boolean(user && (user.firstName + " " + user.lastName).includes(filters.releasedBy));
            });
        }

        // VERSION
        if (filters.version) {
            filteredVersions = filteredVersions.filter((version) => Boolean(version.version && version.version.includes(filters.version)));
        }

        return filteredVersions;
    };

    /**
     * Gets the selected process version from the process version list
     */
    const getSelectedProcessVersion = (): ProcessUiModel | undefined => {
        return processVersions.find((version) => version.processId === selectedVersionId);
    };

    /**
     * Handles the change of version selection
     *
     * @param {ProcessUiModel} version - selected version
     */
    const handleSelectVersion =
        (version: ProcessUiModel): (() => void) =>
        (): void => {
            setSelectedVersionId(version.processId);
        };

    /**
     * Handles the printing of a version
     */
    const handlePrintVersion = async (): Promise<void> => {
        if (!Boolean(processVersions.length)) {
            return;
        }

        const selectedVersion = getSelectedProcessVersion();

        if (selectedVersion) {
            setPrintOpen(true);
            setPrintedProcess(selectedVersion);
        }
    };

    /**
     * Closes the print dialog
     */
    const handlePrintClose = (): void => {
        setPrintOpen(false);
    };

    /**
     * Handles the approval of a process version
     *
     * @param {string} referenceId - reference id
     * @param {boolean} isApproved - true if approved, false if rejected
     */
    const handleApproveVersion = async (
        referenceId: string,
        isApproved: boolean,
        comment = "",
        validatedByUserId?: number,
        username?: string,
        password?: string,
        consented?: boolean
    ): Promise<void> => {
        if (!Boolean(processVersions.length)) {
            return void 0;
        }

        const selectedVersion = getSelectedProcessVersion();

        if (selectedVersion) {
            await dispatch(
                processVersionApprove({
                    comment: comment,
                    isApproved,
                    userId: validatedByUserId,
                    username,
                    password,
                    processId: selectedVersion.processId,
                    consented,
                })
            );
        }

        return void 0;
    };

    /**
     * Handles the changes of the release and retire date
     *
     * @param {number} fromDate - release date in milliseconds
     * @param {number} toDate - retire date in milliseconds
     */
    const handleUpdateReleaseDates = async (
        fromDate: number,
        toDate: number,
        comments: string,
        validatedByUserId: number,
        username: string,
        password: string,
        consented: boolean
    ): Promise<void> => {
        if (!Boolean(processVersions.length)) {
            return void 0;
        }

        const selectedVersion = getSelectedProcessVersion();

        if (selectedVersion) {
            setIsLoading(true);
            await dispatch(
                processVersionUpdateReleaseDates({
                    fromDate,
                    processId: selectedVersion.processId,
                    toDate,
                    userId: validatedByUserId,
                    username,
                    password,
                    consented,
                    comments,
                })
            ).finally(() => {
                setIsLoading(false);
            });
        }

        return void 0;
    };

    /**
     * Handles the changes of filters from the search bar
     *
     * @param {IFilters} filters - filters
     */
    const handleFilterChange = (filters: IFilters): void => {
        setFilters(filters);
    };

    const selectedProcessVersion = getSelectedProcessVersion();

    return (
        <div>
            <Dialog open={open} fullScreen={true} TransitionComponent={Transition}>
                <AppBar position="static">
                    <Toolbar className={classes.toolbar}>
                        <Typography variant="h6" color="inherit">
                            {`${translate("process.versioning.processVersioning")} - ${processLabel}`}
                        </Typography>
                        <IconButton color="inherit" id="processVersioningCloseButtonId" onClick={props.onClose} aria-label="Close">
                            <CloseIcon fontSize="large" />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.content} id="processVersioningDialogContentId">
                        {isLoading && (
                            <Backdrop className={classes.backdrop} open={isLoading} id={"backdropId"}>
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        )}

                        <div className={classes.versionListContainer}>
                            <Paper className={classes.searchContainer}>
                                <ProcessApprovalSearchBar onFiltersChange={handleFilterChange} />
                            </Paper>
                            <div className={classes.versionList}>
                                {sortProcessByVersions(applyFilters(processVersions)).map((version, index) => (
                                    <div key={index} onClick={handleSelectVersion(version)}>
                                        <ProcessApprovalCard isSelected={version.processId === selectedVersionId} versionInfo={version} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={classes.processApprovalCardEditorContainer}>
                            {selectedProcessVersion && (
                                <ProcessApprovalCardEditor
                                    lastVersion={lastVersion}
                                    versionInfo={selectedProcessVersion}
                                    onPrintVersion={handlePrintVersion}
                                    onApproveVersion={handleApproveVersion}
                                    onUpdateReleaseDates={handleUpdateReleaseDates}
                                    isDraftLocked={readOnly}
                                    anotherVersionPendingApproval={processVersions.some(
                                        (version) =>
                                            version.processId !== selectedProcessVersion.processId &&
                                            (version.versionStatus === ApprovalStatusEnum.AWAITING ||
                                                getProcessReleaseStatus(version) === ProcessReleaseStatus.AWAITING)
                                    )}
                                />
                            )}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            {Boolean(processVersions.length) && (
                <PrintProcessDialog
                    open={Boolean(printOpen && printedProcess)}
                    onClose={handlePrintClose}
                    processInfo={printedProcess}
                    processVersions={processVersions}
                    selectedVersionId={selectedVersionId}
                    userList={userList}
                    userGroups={userGroups}
                    tasks={tasks}
                />
            )}
        </div>
    );
}
