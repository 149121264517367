import { theme } from "@aos/react-components";
import { IBomItem, ProcessActionStepWorkInstructions } from "@kortex/aos-common";
import { useProcessEditorContext } from "@kortex/aos-ui/components/pages/ProcessEditor/ProcessEditor/context";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { deepClone } from "@kortex/utilities";
import { IconButton, makeStyles, TableCell, TableRow, Tooltip, Typography } from "@material-ui/core";
import React, { useContext } from "react";

import MoveUpIcon from "../../../../../../../../../../core/Icons/MoveUp/MoveUp";
import { ActionEditorContext } from "../../../../../../context";
import { BomItemInfoTooltip } from "../../../utilities";

const useStyles = makeStyles({
    iconButton: {
        padding: 0,
    },
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
        textAlign: "center",
    },
});

interface IOwnProps {
    item: IBomItem;
}

function WorkInstructionsBomItemsRow(props: IOwnProps): JSX.Element {
    const { item } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const { bom } = useProcessEditorContext();
    const { onChangedStep, editedActionStep } = useContext(ActionEditorContext);

    const assignToStepButtonDisabled = Boolean((editedActionStep as ProcessActionStepWorkInstructions).config.bomItems[item.partNumber]);

    /**
     * Add BOM item to the step
     */
    const handleMoveItemBomToItemStep = (partNumber: string) => (): void => {
        if (editedActionStep && bom) {
            const step = deepClone(editedActionStep as ProcessActionStepWorkInstructions);
            step.config.bomItems = Object.assign(
                { [partNumber]: { partNumber, quantity: item.quantity === 0 ? 0 : 1 } },
                step.config.bomItems
            );

            onChangedStep(step);
        }
    };

    return (
        <TableRow>
            <TableCell>
                <BomItemInfoTooltip item={item}>
                    <Typography id={`${item.partNumber}BomItemPartNumberId`} variant="body2">
                        {item.partNumber}
                    </Typography>
                </BomItemInfoTooltip>
            </TableCell>
            <TableCell align="center">
                <Typography id={`${item.partNumber}BomItemQuantityId`} variant="body2">
                    {item.quantity}
                </Typography>
            </TableCell>
            <TableCell align="right">
                <Tooltip
                    classes={{ tooltip: classes.tooltip }}
                    placement="left"
                    title={
                        assignToStepButtonDisabled ? (
                            ""
                        ) : (
                            <Typography variant="body2">{translate("action.workInstructions.bom.addToStep")}</Typography>
                        )
                    }
                >
                    <IconButton
                        className={classes.iconButton}
                        disabled={assignToStepButtonDisabled}
                        id={`${item.partNumber}AddToStepItemButtonId`}
                        onClick={handleMoveItemBomToItemStep(item.partNumber)}
                    >
                        <MoveUpIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
}

export default WorkInstructionsBomItemsRow;
