import { IBomFollowUpSymptomDbModel } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { bomClearBoms, bomGetByTrackingId } from "@kortex/aos-ui/redux/bom-manager/bom-thunks";
import { client } from "@kortex/aos-ui/utilitites/kortex-client/client";
import { useLocationQuery } from "@kortex/aos-ui/utilitites/useLocationQuery";
import React, { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

/**
 * - wo:  Work Order (Job)
 * - pn:  Part Number
 * - tid: Tracking ID
 * - at:  Adjustement Type
 * - sn:  Serial Number
 */
export type SearchType = "wo" | "pn" | "t" | "tid" | "at" | "sn";
export type AdjustmentType = "overconsumption" | "replacement" | "standard" | "update";

interface ISearch {
    type: SearchType | "none";
    value: string;
}

type QueryParam<T> = {
    key: "type";
    value: T;
};

type QueryParams<T = SearchType> = [
    QueryParam<T>,
    ...{
        key: T;
        value: string;
    }[],
];

interface IBomContext {
    bomFollowUpSymptoms: IBomFollowUpSymptomDbModel[];
    loading: boolean;
    search: ISearch;
    updateQuery: (...params: QueryParams) => void;
}

const BomContext = createContext<IBomContext>({
    bomFollowUpSymptoms: [],
    loading: false,
    search: {
        type: "none",
        value: "",
    },
    updateQuery: () => void 0,
});

type BomProviderProps = PropsWithChildren<{}>;

export const BomProvider: FC<BomProviderProps> = (props) => {
    const { children } = props;

    const dispatch = useThunkDispatch();
    const history = useHistory();
    const query = useLocationQuery();

    const [bomFollowUpSymptoms, setBomFollowUpSymptoms] = useState<IBomFollowUpSymptomDbModel[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<ISearch>({
        type: "none",
        value: "",
    });

    /**
     * Wait until the page URL query is changed. Generate a follow-up report if query is valid.
     */
    useEffect(() => {
        const type = query.get("type") as SearchType | null;
        const trackingId = query.get("tid");
        // workOrder = query.get("wo"),
        // traceability = query.get("t"),
        // serialNumber = query.get("sn");

        generate(type, trackingId);
    }, [query]);

    const generate = (type: SearchType | null, value: string | null): void => {
        if (type && value?.trim()) {
            // Empty BOMs from redux
            dispatch(bomClearBoms());

            setSearch({ type, value });
            setLoading(true);

            switch (type) {
                case "tid":
                    dispatch(bomGetByTrackingId(value))
                        .then(async (result): Promise<void> => {
                            if (result?.woBoms[0]?.woBom.jobRefId) {
                                setBomFollowUpSymptoms([
                                    ...(await client.services.bomFollowUpSymptom.getAll({
                                        treeNodeId: undefined,
                                        jobRefId: result.woBoms[0].woBom.jobRefId,
                                    })()),
                                ]);
                            }
                        })
                        .finally(() => setLoading(false));
                    break;
                default:
                    break;
            }
        }
    };

    /**
     * Update the search query. `params` will be added to the page URL.
     */
    const updateQuery = (...params: { key: string; value: string }[]): void => {
        history.replace({ search: params.map((param) => `${param.key}=${param.value}`).join("&") });
    };

    return (
        <BomContext.Provider
            value={{
                bomFollowUpSymptoms,
                loading,
                search,
                updateQuery,
            }}
        >
            {children}
        </BomContext.Provider>
    );
};

export const useBomContext = (): IBomContext => useContext<IBomContext>(BomContext);
