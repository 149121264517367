import { useSelectorUserSession } from "@kortex/aos-ui/redux/selectors";
import React, { FC, ReactNode } from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";

import { defaultFrontPage } from "../menu";

const NoMatchRoute: FC = () => {
    const session = useSelectorUserSession();

    const renderRoute = (props: RouteComponentProps): ReactNode | undefined => (
        <Redirect
            to={{
                pathname: defaultFrontPage(session),
                state: { from: props.location },
            }}
        />
    );

    return <Route path="*" render={renderRoute} />;
};

export default NoMatchRoute;
