import { EnumPageTypes } from "@kortex/aos-ui/redux/general-manager/general-types";
import { useSelectorUserSession } from "@kortex/aos-ui/redux/selectors";
import React, { FC, PropsWithChildren, ReactNode } from "react";
import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router-dom";

import { getPageUrl } from "../menu";

const PrivateRoute: FC<PropsWithChildren<RouteProps>> = (props) => {
    const { children, ...rest } = props;
    const session = useSelectorUserSession();

    const renderRoute = (props: RouteComponentProps): ReactNode | undefined => {
        if (session?.userId) return children;

        return (
            <Redirect
                to={{
                    pathname: getPageUrl(EnumPageTypes.LOGIN),
                    state: { from: props.location },
                }}
            />
        );
    };

    return <Route {...rest} render={renderRoute} />;
};

export default PrivateRoute;
