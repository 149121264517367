import { IBomFollowUp, ISerializedItem } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { useSelectorUserSession } from "@kortex/aos-ui/redux/selectors";
import { userCanWrite } from "@kortex/aos-ui/utilitites/IUserRights";
import { Menu, MenuItem, PopoverPosition, Typography } from "@material-ui/core";
import React, { FC, useState } from "react";

import { ServiceVariant } from "../utils";

import { EditSerializedItemDialog } from "./editSerializedItemDialog";
import { EditTraceabilityDialog } from "./editTraceabilityDialog";
import { MultipleTraceabilitiesDialog } from "./multipleTraceabilitiesDialog";
import { OverconsumptionDialog } from "./overconsumptionDialog";
import { RemoveDialog } from "./removeDialog";
import { RemoveSerializedItemDialog } from "./removeSerializedItemDialog";
import { ReplacementDialog } from "./replacementDialog";
import { ReplacementSerializedItemDialog } from "./replacementSerializedItemDialog";

type DialogType =
    | "edit"
    | "editSerial"
    | "multipleTraceabilities"
    | "remove"
    | "removeSerial"
    | "replacement"
    | "replacementSerial"
    | "overconsumption";
export type MenuType = "non-traceable" | "serial" | "traceability";

interface IOwnProps {
    followUp: IBomFollowUp;
    menuPosition?: PopoverPosition;
    onDialogClose?: () => void;
    onMenuClose?: () => void;
    serializedItem?: ISerializedItem;
    type: MenuType;
    variant: ServiceVariant;
    treeNodeId?: number;
    jobRefId?: string;
}

const TraceabilityMenu: FC<IOwnProps> = (props) => {
    const { followUp, menuPosition, onDialogClose, onMenuClose, serializedItem, type, variant, treeNodeId, jobRefId } = props;

    const session = useSelectorUserSession();
    const translate = useTranslate();

    const [dialogOpen, setDialogOpen] = useState<Record<DialogType, boolean>>({
        edit: false,
        editSerial: false,
        multipleTraceabilities: false,
        overconsumption: false,
        remove: false,
        removeSerial: false,
        replacement: false,
        replacementSerial: false,
    });

    const menuItemDisabled = variant === "bom" && !userCanWrite(session?.roleRights.bom);

    /**
     * Opens or closes specified dialog
     */
    const handleDialogOpen =
        (type: DialogType, opened: boolean): (() => void) =>
        (): void => {
            setDialogOpen((prevState) => ({
                ...prevState,
                [type]: opened,
            }));

            if (opened) onMenuClose?.();
            else onDialogClose?.();
        };

    const renderMenuItems = (): JSX.Element[] => {
        switch (type) {
            case "non-traceable":
                return [
                    /* OVERCONSUMPTION */
                    <MenuItem
                        disabled={menuItemDisabled || followUp.isOverconsumption}
                        id="traceabilityMenuOverconsumptionId"
                        key="traceabilityMenuOverconsumptionId"
                        onClick={handleDialogOpen("overconsumption", true)}
                    >
                        <Typography>{translate("bomPage.bomTable.overconsumption")}</Typography>
                    </MenuItem>,
                ];
            case "serial":
                return [
                    /* EDIT SERIAL NUMBER */
                    <MenuItem
                        disabled={menuItemDisabled}
                        id="traceabilityMenuEditSerialId"
                        key="traceabilityMenuEditSerialId"
                        onClick={handleDialogOpen("editSerial", true)}
                    >
                        <Typography>{translate("bomPage.bomTable.edit")}</Typography>
                    </MenuItem>,
                    /* REMOVE SERIAL NUMBER*/
                    <MenuItem
                        disabled={menuItemDisabled}
                        id="traceabilityMenuRemoveSerialNumberId"
                        key="traceabilityMenuRemoveSerialNumberId"
                        onClick={handleDialogOpen("removeSerial", true)}
                    >
                        <Typography>{translate("bomPage.bomTable.remove")}</Typography>
                    </MenuItem>,
                    /* REPLACEMENT SERIAL NUMBER */
                    <MenuItem
                        disabled={menuItemDisabled}
                        id="traceabilityMenuReplacementSerialId"
                        key="traceabilityMenuReplacementSerialId"
                        onClick={handleDialogOpen("replacementSerial", true)}
                    >
                        <Typography>{translate("bomPage.bomTable.replacement")}</Typography>
                    </MenuItem>,
                ];
            case "traceability":
                return [
                    /* EDIT TRACEABILITY */
                    <MenuItem
                        disabled={menuItemDisabled}
                        id="traceabilityMenuEdit"
                        key="traceabilityMenuEdit"
                        onClick={handleDialogOpen("edit", true)}
                    >
                        <Typography>{translate("bomPage.bomTable.edit")}</Typography>
                    </MenuItem>,
                    /* MUTLIPLE TRACEABILITY */
                    <MenuItem
                        disabled={menuItemDisabled || followUp.quantity === 1}
                        id="traceabilityMenuMultipleTraceabilitiesId"
                        key="traceabilityMenuMultipleTraceabilitiesId"
                        onClick={handleDialogOpen("multipleTraceabilities", true)}
                    >
                        <Typography>{translate("bomPage.bomTable.multipleTraceabilities")}</Typography>
                    </MenuItem>,
                    /* REMOVE */
                    <MenuItem
                        disabled={menuItemDisabled}
                        id="traceabilityMenuRemoveId"
                        key="traceabilityMenuRemoveId"
                        onClick={handleDialogOpen("remove", true)}
                    >
                        <Typography>{translate("bomPage.bomTable.remove")}</Typography>
                    </MenuItem>,
                    /* REPLACEMENT */
                    <MenuItem
                        disabled={menuItemDisabled}
                        id="traceabilityMenuReplacementId"
                        key="traceabilityMenuReplacementId"
                        onClick={handleDialogOpen("replacement", true)}
                    >
                        <Typography>{translate("bomPage.bomTable.replacement")}</Typography>
                    </MenuItem>,
                    /* OVERCONSUMPTION */
                    <MenuItem
                        disabled={menuItemDisabled || followUp.isOverconsumption || followUp.quantity === 0}
                        id="traceabilityMenuOverconsumptionId"
                        key="traceabilityMenuOverconsumptionId"
                        onClick={handleDialogOpen("overconsumption", true)}
                    >
                        <Typography>{translate("bomPage.bomTable.overconsumption")}</Typography>
                    </MenuItem>,
                ];
            default:
                return [];
        }
    };

    return (
        <>
            {/*************** MENU ***************/}
            <Menu
                anchorPosition={menuPosition}
                anchorReference="anchorPosition"
                id="traceabilityMenuId"
                onClose={onMenuClose}
                open={Boolean(menuPosition)}
            >
                {renderMenuItems()}
            </Menu>
            {/*************** DIALOGS ***************/}
            <EditTraceabilityDialog
                followUp={followUp}
                onClose={handleDialogOpen("edit", false)}
                open={dialogOpen["edit"]}
                variant={variant}
            />
            <MultipleTraceabilitiesDialog
                followUp={followUp}
                onClose={handleDialogOpen("multipleTraceabilities", false)}
                open={dialogOpen["multipleTraceabilities"]}
                variant={variant}
            />
            <ReplacementDialog
                followUp={followUp}
                onClose={handleDialogOpen("replacement", false)}
                open={dialogOpen["replacement"]}
                variant={variant}
                treeNodeId={treeNodeId}
                jobRefId={jobRefId}
            />
            <RemoveDialog
                followUp={followUp}
                onClose={handleDialogOpen("remove", false)}
                open={dialogOpen["remove"]}
                variant={variant}
                treeNodeId={treeNodeId}
                jobRefId={jobRefId}
            />

            <OverconsumptionDialog
                followUp={followUp}
                onClose={handleDialogOpen("overconsumption", false)}
                open={dialogOpen["overconsumption"]}
                variant={variant}
                treeNodeId={treeNodeId}
                jobRefId={jobRefId}
            />
            {serializedItem && (
                <>
                    <EditSerializedItemDialog
                        onClose={handleDialogOpen("editSerial", false)}
                        open={dialogOpen["editSerial"]}
                        serializedItem={serializedItem}
                        variant={variant}
                    />
                    <RemoveSerializedItemDialog
                        serializedItem={serializedItem}
                        onClose={handleDialogOpen("removeSerial", false)}
                        open={dialogOpen["removeSerial"]}
                        variant={variant}
                        treeNodeId={treeNodeId}
                        jobRefId={jobRefId}
                    />
                    <ReplacementSerializedItemDialog
                        onClose={handleDialogOpen("replacementSerial", false)}
                        open={dialogOpen["replacementSerial"]}
                        serializedItem={serializedItem}
                        variant={variant}
                        treeNodeId={treeNodeId}
                        jobRefId={jobRefId}
                    />
                </>
            )}
        </>
    );
};

export default TraceabilityMenu;
