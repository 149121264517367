export const processPlayer = {
    rpc: {
        play: {
            funcId: 6200,
            funcName: "processPlayerPlay",
        },
        stop: {
            funcId: 6201,
            funcName: "processPlayerStop",
        },
        pause: {
            funcId: 6202,
            funcName: "processPlayerPause",
        },
        resume: {
            funcId: 6203,
            funcName: "processPlayerResume",
        },
        retry: {
            funcId: 6204,
            funcName: "processPlayerRetry",
        },
        updateProcessState: {
            funcId: 6205,
            funcName: "processPlayerUpdateProcessState",
        },
        failStep: {
            funcId: 6206,
            funcName: "processPlayerFailStep",
        },
        getDashboardInfo: {
            funcId: 6207,
            funcName: "processPlayerGetDashboardInfo",
        },
        runnerDashboardInfoUpdated: {
            funcId: 6208,
            funcName: "runnerDashboardInfoUpdated",
        },
        getHistory: {
            funcId: 6209,
            funcName: "processPlayerGetHistory",
        },
        notificationReceived: {
            funcId: 6210,
            funcName: "processPlayerNotificationReceived",
        },
        insertRework: {
            funcId: 6211,
            funcName: "processPlayerInsertRework",
        },
        playNextEnabled: {
            funcId: 40011,
            funcName: "processPlayerPlayNextEnabled",
        },
        stateInitialized: {
            funcId: 40012,
            funcName: "processPlayerStateInitalized",
        },
        historyUpdated: {
            funcId: 40013,
            funcName: "processPlayerHistoryUpdated",
        },
        hubConnectionUpdated: {
            funcId: 40014,
            funcName: "processPlayerHubConnectionUpdated",
        },
        uiActionPropsUpdated: {
            funcId: 40015,
            funcName: "processPlayerUiActionPropsUpdated",
        },
        actionStateUpdated: {
            funcId: 40016,
            funcName: "processPlayerActionStateUpdated",
        },
        processStateUpdated: {
            funcId: 40017,
            funcName: "processPlayerProcessStateUpdated",
        },
        bomFollowUpEditItem: {
            funcId: 40018,
            funcName: "processPlayerBomFollowUpEditItem",
        },
        bomFollowUpEditSerializedItem: {
            funcId: 40019,
            funcName: "processPlayerBomFollowUpEditSerializedItem",
        },
        bomFollowUpInsert: {
            funcId: 40020,
            funcName: "processPlayerBomFollowUpInsert",
        },
        bomFollowUpInsertSerializedItem: {
            funcId: 40021,
            funcName: "processPlayerBomFollowUpInsertSerializedItem",
        },
        bomFollowUpMultipleTraceabilitiesItem: {
            funcId: 40022,
            funcName: "processPlayerBomFollowUpMultipleTraceabilitiesItem",
        },
        bomFollowUpOverconsumeItem: {
            funcId: 40023,
            funcName: "processPlayerBomFollowUpOverconsumeItem",
        },
        bomFollowUpOverconsumeNonTraceableItem: {
            funcId: 40024,
            funcName: "processPlayerBomFollowUpOverconsumeNonTraceableItem",
        },
        bomFollowUpReplaceItem: {
            funcId: 40025,
            funcName: "processPlayerBomFollowUpReplaceItem",
        },
        bomFollowUpReplaceSerializedItem: {
            funcId: 40026,
            funcName: "processPlayerBomFollowUpReplaceSerializedItem",
        },
        bomFollowUpRemoveItem: {
            funcId: 40027,
            funcName: "processPlayerBomFollowUpRemoveItem",
        },
        bomFollowUpRemoveSerializedItem: {
            funcId: 40028,
            funcName: "processPlayerBomFollowUpRemoveSerializedItem",
        },
    },
    serviceId: 62,
    serviceVersion: 1,
};
