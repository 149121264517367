import {
    EnumActionStatus,
    IPlayerActionBaseState,
    IUserRoleBase,
    ProcessUiModel,
    TNotification,
    TTaskProcessApproval,
    TTaskProcessReview,
    TTaskUnknown,
    TaskTypeEnum,
    UserGroupStatusEnum,
    UserId,
} from "@kortex/aos-common";
import { RouterLocation } from "connected-react-router";
import { History } from "history";
import { useSelector } from "react-redux";

import { SetBomFollowUpSymptomListAction } from "./bom-follow-up/bom-follow-up-types";
import { SetElectronicSignatureListAction } from "./electronic-signature-manager/electronic-signature-types";
import { SetErpSettingsListAction } from "./erp-settings-manager/erp-settings-types";
import { setFailureSymptomsListAction, setFailureTypesListAction } from "./failures-manager/failures-types";
import { SetFilesAction } from "./file-manager/file-types";
import { SetConnectionListAction, SetLanguageAction, SetRouteAction, SetUpdateReadyAction } from "./general-manager/general-types";
import { SetNotificationListAction } from "./notifications-center-manager/notification-types";
import { IPlayerState } from "./player-manager/player-types";
import {
    SetCopiedProcessActionAction,
    SetCopiedProcessActionStepAction,
    SetEditedProcessIdAction,
    SetProcessActionSettingsAction,
    SetProcessesAction,
} from "./process-manager/process-types";
import { IProcessTrainingState } from "./process-training-manager/process-training-types";
import { SetResultSettingItemListAction, SetResultSettingMetadataListAction } from "./result-setting-manager/result-setting-types";
import {
    SetReworkItemStatusListAction,
    SetReworkJobProcessListAction,
    SetReworkListAction,
    SetReworkLogListAction,
    SetRootCauseListAction,
} from "./rework-manager/rework-types";
import { SetJobListAction } from "./scheduler-manager/scheduler-types";
import { SetSettingOrganizationListAction } from "./setting-organization-manager/setting-organization-types";
import { AppState } from "./store";
import { SetTaskListAction } from "./task-manager/task-types";
import {
    SetEditedTreeProcessNodeIdAction,
    SetTreeFileAction,
    SetTreeProcessAction,
    SetTreeWorkZoneAction,
} from "./tree-manager/tree-types";
import { SetUserGroupListAction, SetUserListAction, SetUserRoleListAction, SetUserSessionAction } from "./user-manager/users-types";
import { UserTrainingSetAction } from "./user-training-manager/user-training-types";
import { SetJobProcessesListAction } from "./work-scheduler-manager/work-schedule-types";
interface ISelectorTasksOptions<T extends TTaskUnknown["type"] | undefined> {
    linkId?: TTaskUnknown["linkId"];
    type?: T;
    userId?: TTaskUnknown["userId"];
    involveInTaskUserId?: TTaskUnknown["userId"];
}

export type SelectorTasksOptions<T extends TTaskUnknown["type"] | undefined = undefined> = ISelectorTasksOptions<T>;
export type SelectorGroupsOptions = {
    isActive?: boolean;
    isApprover?: boolean;
    trainedAfterReading?: boolean;
};

/*
 * returns Connection list state
 */
export function useSelectorConnectionList(): SetConnectionListAction["value"] {
    return useSelector<AppState, SetConnectionListAction["value"]>((state) => state.general.connectionList);
}

/*
 * returns current page state
 */
export function useSelectorCurrentPage(): SetRouteAction["value"] {
    return useSelector<AppState, SetRouteAction["value"]>((state) => state.general.currentPage);
}

/**
 * returns update ready state
 */
export function useSelectorDesktopUpdateReady(): SetUpdateReadyAction["value"] {
    return useSelector<AppState, SetUpdateReadyAction["value"]>((state) => state.general.desktopUpdateReady);
}

/**
 * returns erp setting from state
 */
export function useSelectorErpSettings(): SetErpSettingsListAction["value"] {
    return useSelector<AppState, SetErpSettingsListAction["value"]>((state) => state.erpSettings.erpSettings);
}

/**
 * return reconnection state from state
 */
export function useSelectorReconnecting(): boolean {
    return useSelector<AppState, boolean>((state) => state.general.uplink.reconnecting);
}

/*
 * returns timezone offset
 */
export function useSelectorTimezone(): number {
    return useSelector<AppState, number>((state) => state.general.timezoneOffset);
}

/**
 * returns edited process ID from state
 */
export function useSelectorEditedProcessId(): SetEditedProcessIdAction["value"] {
    return useSelector<AppState, SetEditedProcessIdAction["value"]>((state) => state.process.editedProcessId);
}

/**
 * returns edited tree process node ID from state
 */
export function useSelectorEditedTreeProcessNodeId(): SetEditedTreeProcessNodeIdAction["value"] {
    return useSelector<AppState, SetEditedTreeProcessNodeIdAction["value"]>((state) => state.tree.editedTreeProcessNodeId);
}

/**
 * returns electronic signatures from state
 */
export function useSelectorElectronicSignature(): SetElectronicSignatureListAction["value"] {
    return useSelector<AppState, SetElectronicSignatureListAction["value"]>((state) => state.electronicSignature.electronicSignatures);
}

/**
 * returns failure symptoms from state
 */
export function useSelectorFailureSymptoms(): setFailureSymptomsListAction["value"] {
    return useSelector<AppState, setFailureSymptomsListAction["value"]>((state) => state.failure.symptoms);
}

/**
 * returns failure types from state
 */
export function useSelectorFailureTypes(): setFailureTypesListAction["value"] {
    return useSelector<AppState, setFailureTypesListAction["value"]>((state) => state.failure.types);
}

/*
 * returns files state
 */
export function useSelectorFiles(): SetFilesAction["value"] {
    return useSelector<AppState, SetFilesAction["value"]>((state) => state.file.files);
}

/**
 * returns jobs from state
 */
export function useSelectorJobs(): SetJobListAction["value"] {
    return useSelector<AppState, SetJobListAction["value"]>((state) => state.scheduler.jobs) || [];
}

/**
 * returns job processes from state
 */
export function useSelectorJobProcesses(): SetJobProcessesListAction["value"] {
    return useSelector<AppState, SetJobProcessesListAction["value"]>((state) => state.workSchedule.jobProcesses) || [];
}

/*
 * returns Connection list state
 */
export function useSelectorLanguage(): SetLanguageAction["value"] {
    return useSelector<AppState, SetLanguageAction["value"]>((state) => state.general.language);
}

/**
 * returns player from state
 */
export function useSelectorPlayer(): IPlayerState {
    return useSelector<AppState, IPlayerState>((state) => state.player);
}

/**
 * returns copied process action from state
 */
export function useSelectorProcessActionCopy(): SetCopiedProcessActionAction["value"] {
    return useSelector<AppState, SetCopiedProcessActionAction["value"]>((state) => state.process.copiedActions) || [];
}

/**
 * returns copied process action step from state
 */
export function useSelectorProcessActionStepCopy(): SetCopiedProcessActionStepAction["value"] {
    return useSelector<AppState, SetCopiedProcessActionStepAction["value"]>((state) => state.process.copiedActionStep);
}

/**
 * returns process action settings from state
 */
export function useSelectorProcessActionSettings(): SetProcessActionSettingsAction["value"] {
    return useSelector<AppState, SetProcessActionSettingsAction["value"]>((state) => state.process.settings) || [];
}

/**
 * returns processes from state
 */
export function useSelectorProcesses(
    filter?: (value: ProcessUiModel, index: number, array: ProcessUiModel[]) => unknown
): SetProcessesAction["value"] {
    return (
        useSelector<AppState, SetProcessesAction["value"]>((state) =>
            filter ? state.process.processes.filter(filter) : state.process.processes
        ) || []
    );
}

/**
 * returns result setting items from state
 */
export function useSelectorResultSettingItems(): SetResultSettingItemListAction["value"] {
    return useSelector<AppState, SetResultSettingItemListAction["value"]>((state) => state.resultSetting.resultSettingItems);
}

/**
 * returns result setting metadata from state
 */
export function useSelectorResultSettingMetadata(): SetResultSettingMetadataListAction["value"] {
    return useSelector<AppState, SetResultSettingMetadataListAction["value"]>((state) => state.resultSetting.resultSettingMetadata);
}

/**
 * returns reworks from state
 */
export function useSelectorReworks(): SetReworkListAction["value"] {
    return useSelector<AppState, SetReworkListAction["value"]>((state) => state.rework.reworks) || [];
}

/**
 * returns rework status from state
 */
export function useSelectorReworkItemStatus(): SetReworkItemStatusListAction["value"] {
    return useSelector<AppState, SetReworkItemStatusListAction["value"]>((state) => state.rework.reworkItemStatus) || [];
}

/**
 * returns bom follow-up symptom from state
 */
export function useSelectorBomFollowUpSymptom(): SetBomFollowUpSymptomListAction["value"] {
    return useSelector<AppState, SetBomFollowUpSymptomListAction["value"]>((state) => state.bomFollowUp.bomFollowUpSymptom) || [];
}

/**
 * returns root cause from state
 */
export function useSelectorRootCause(): SetRootCauseListAction["value"] {
    return useSelector<AppState, SetRootCauseListAction["value"]>((state) => state.rework.rootCause) || [];
}

/**
 * returns rework logfrom state
 */
export function useSelectorReworkLogs(): SetReworkLogListAction["value"] {
    return useSelector<AppState, SetReworkLogListAction["value"]>((state) => state.rework.reworkLogs) || [];
}

/**
 * returns rework jobs process from state
 */
export function useSelectorReworkJobsProcess(): SetReworkJobProcessListAction["value"] {
    return useSelector<AppState, SetReworkJobProcessListAction["value"]>((state) => state.rework.reworkJobsProcess) || [];
}

/**
 * returnsrouter location from state
 */
export function useSelectorRouterLocation(): RouterLocation<History.UnknownFacade> {
    return useSelector<AppState, RouterLocation<History.UnknownFacade>>((state) => state.router.location);
}

/**
 * returns organization settings from state
 */
export function useSelectorSettingOrganizations(): SetSettingOrganizationListAction["value"] {
    return useSelector<AppState, SetSettingOrganizationListAction["value"]>((state) => state.setting.settingOrganization);
}

/**
 * returns trainings from state
 */
export function useSelectorProcessTrainings(): IProcessTrainingState {
    return useSelector<AppState, IProcessTrainingState>((state) => state.processTraining) || [];
}

/**
 * returns user trainings from state
 */
export function useSelectorUserTrainings(): UserTrainingSetAction["value"] {
    return useSelector<AppState, UserTrainingSetAction["value"]>((state) => state.userTraining.trainings) || [];
}

/**
 * returns tree files from state
 */
export function useSelectorTreeFile(): SetTreeFileAction["value"] {
    return useSelector<AppState, SetTreeFileAction["value"]>((state) => state.tree.files) || [];
}

/**
 * returns tree processes from state
 */
export function useSelectorTreeProcess(): SetTreeProcessAction["value"] {
    return useSelector<AppState, SetTreeProcessAction["value"]>((state) => state.tree.processes) || [];
}

/**
 * returns tree work zones from state
 */
export function useSelectorTreeWorkZone(): SetTreeWorkZoneAction["value"] {
    return useSelector<AppState, SetTreeWorkZoneAction["value"]>((state) => state.tree.workZones) || [];
}

/**
 * returns users from state
 */
export function useSelectorUsers(): SetUserListAction["value"] {
    return useSelector<AppState, SetUserListAction["value"]>((state) => state.user.users) || [];
}

/**
 * returns user groups from state
 */
export function useSelectorUsersGroups(options?: SelectorGroupsOptions): SetUserGroupListAction["value"] {
    let groupsSelector = useSelector<AppState, SetUserGroupListAction["value"]>((state) => state.user.groups) || [];

    // filter out archived groups if isActive option is passed as true
    if (options?.isActive) {
        groupsSelector = groupsSelector.filter((group) => group.status !== UserGroupStatusEnum.ARCHIVED);
    }

    // filter out non-approver groups if isApprover option is passed as true
    if (options?.isApprover === true) {
        groupsSelector = groupsSelector.filter((group) => group.isApprover);
    }

    if (options?.trainedAfterReading) {
        groupsSelector = groupsSelector.filter((group) => group.trainedAfterReading);
    }

    return groupsSelector;
}

/**
 * returns user roles from state
 */
export function useSelectorUsersRoles(): SetUserRoleListAction["value"] {
    return useSelector<AppState, SetUserRoleListAction["value"]>((state) => state.user.roles) || [];
}

/**
 * return user session from state
 */
export function useSelectorUserSession(): SetUserSessionAction["value"] {
    return useSelector<AppState, SetUserSessionAction["value"]>((state) => state.user.session);
}

/**
 * return user session from state
 */
export function useSelectorUsersRolesRights<T extends keyof IUserRoleBase>(type: T): IUserRoleBase[T] {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return useSelector<AppState, IUserRoleBase[T]>((state) => state.user.session!.roleRights[type]);
}

/**
 * return user session from state
 */
export function useSelectorUserId(): UserId {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return useSelector<AppState, UserId>((state) => state.user.session!.userId);
}

/**
 * returns user tasks from state
 */
export function useSelectorTasks<T extends SelectorTasksOptions>(option?: T): TTaskUnknown[];
export function useSelectorTasks<T extends SelectorTasksOptions<TaskTypeEnum.PROCESS_APPROVAL>>(option?: T): TTaskProcessApproval[];
export function useSelectorTasks<T extends SelectorTasksOptions<TaskTypeEnum.PROCESS_REVIEW>>(option?: T): TTaskProcessReview[];
export function useSelectorTasks<T extends SelectorTasksOptions>(
    option?: T
): TTaskProcessApproval[] | TTaskProcessReview[] | TTaskUnknown[] {
    let taskSelector = useSelector<AppState, SetTaskListAction["value"]>((state) => state.task.tasks) || [];

    if (option?.linkId) {
        taskSelector = taskSelector.filter((task) => {
            return task.linkId === option.linkId;
        });
    }

    if (option?.type) {
        taskSelector = taskSelector.filter((task) => task.type === option.type);
    }

    if (option?.userId) {
        taskSelector = taskSelector.filter((task) => task.userId === option.userId);
    }

    return taskSelector;
}

/**
 * returns user notification from state
 */
export function useSelectorNotification(): TNotification[] {
    return (
        useSelector<AppState, SetNotificationListAction["value"]>((state) =>
            state.notification.notifications.sort(function (a, b) {
                return (b.notification?.timestamp ?? 0) - (a.notification?.timestamp ?? 0);
            })
        ) || []
    );
}

/**
 * Return currently played action
 */
export function useSelectorPlayedAction(): IPlayerActionBaseState | undefined {
    return useSelector<AppState, IPlayerActionBaseState | undefined>((state) => {
        return state.player.actionsState.find((action) => {
            return action.baseStates.status === EnumActionStatus.PLAYING;
        });
    });
}

/**
 * Returns BOMs from state
 */
export function useSelectorBoms(): AppState["bom"]["boms"] {
    return useSelector<AppState, AppState["bom"]["boms"]>((state) => state.bom.boms);
}

/**
 * Returns training pending of user from state
 */
export function useSelectorTrainingPendingOfUser(): AppState["trainingPending"]["trainingsPendingOfUser"] {
    return useSelector<AppState, AppState["trainingPending"]["trainingsPendingOfUser"]>(
        (state) => state.trainingPending.trainingsPendingOfUser
    );
}

/**
 * Returns training pending list from state
 */
export function useSelectorTrainingPendingList(): AppState["trainingPending"]["trainingPendingList"] {
    return useSelector<AppState, AppState["trainingPending"]["trainingPendingList"]>((state) => state.trainingPending.trainingPendingList);
}

/**
 * Returns training certification pending of user from state
 */
export function useSelectorTrainingCertificationPendingOfUser(): AppState["trainingCertificationPending"]["trainingCertificationsPendingOfUser"] {
    return useSelector<AppState, AppState["trainingCertificationPending"]["trainingCertificationsPendingOfUser"]>(
        (state) => state.trainingCertificationPending.trainingCertificationsPendingOfUser
    );
}

/**
 * Returns training certification pending list from state
 */
export function useSelectorTrainingCertificationPendingList(): AppState["trainingCertificationPending"]["trainingCertificationPendingList"] {
    return useSelector<AppState, AppState["trainingCertificationPending"]["trainingCertificationsPendingOfUser"]>(
        (state) => state.trainingCertificationPending.trainingCertificationPendingList
    );
}

/**
 * Returns training reading certificate pending list from state
 */
export function useSelectorTrainingReadingCertificatePendings(): AppState["trainingReadingCertificatePending"]["readingCertificatePendings"] {
    return useSelector<AppState, AppState["trainingReadingCertificatePending"]["readingCertificatePendings"]>(
        (state) => state.trainingReadingCertificatePending.readingCertificatePendings
    );
}
