import { OrUndefined, ProcessActionStep } from "@kortex/aos-common";
import { createContext } from "react";

interface Icon {
    element: JSX.Element;
    tooltip?: string;
}

interface StepModifier {
    icons: Icon[];
}

export interface ActionEditorContextProps {
    setStepModifiersCb: <StepProps extends ProcessActionStep>(cb?: (stepProps: StepProps) => Partial<StepModifier> | undefined) => void;
    editedActionStep: OrUndefined<ProcessActionStep>; // edited step
    onChangedStep: (actionStepProps: ProcessActionStep) => void;
}

export const ActionEditorContext = createContext<ActionEditorContextProps>({
    setStepModifiersCb: () => void 0,
    editedActionStep: undefined,
    onChangedStep: () => void 0,
});
