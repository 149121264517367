import {
    BomActionType,
    BomItemEditedAction,
    BomItemInsertedAction,
    BomItemMultipleTraceabilitiesAction,
    BomItemOverconsumedAction,
    BomItemRemovedAction,
    BomItemReplacedAction,
    BomSerializedItemInsertedAction,
    BomSetAction,
    BomsClearAction,
} from "./bom-types";

export function bomsClearAction(): BomsClearAction {
    return { type: BomActionType.CLEAR };
}

export function bomSetAction(bom: BomSetAction["value"]): BomSetAction {
    return { type: BomActionType.SET_BOMS, value: bom };
}

export function bomItemEditedAction(item: BomItemEditedAction["value"]): BomItemEditedAction {
    return { type: BomActionType.EDIT_ITEM, value: item };
}

export function bomItemInsertedAction(item: BomItemInsertedAction["value"]): BomItemInsertedAction {
    return { type: BomActionType.INSERT_ITEM, value: item };
}

export function bomItemMultipleTraceabilitiesAction(
    item: BomItemMultipleTraceabilitiesAction["value"]
): BomItemMultipleTraceabilitiesAction {
    return { type: BomActionType.MULTIPLE_TRACEABILITIES_ITEM, value: item };
}

export function bomItemOverconsumedAction(item: BomItemOverconsumedAction["value"]): BomItemOverconsumedAction {
    return { type: BomActionType.OVERCONSUME_ITEM, value: item };
}

export function bomItemRemovedAction(item: BomItemRemovedAction["value"]): BomItemRemovedAction {
    return { type: BomActionType.REMOVE_ITEM, value: item };
}

export function bomItemReplacedAction(item: BomItemReplacedAction["value"]): BomItemReplacedAction {
    return { type: BomActionType.REPLACE_ITEM, value: item };
}

export function bomSerializedItemInsertedAction(item: BomSerializedItemInsertedAction["value"]): BomSerializedItemInsertedAction {
    return { type: BomActionType.INSERT_SERIALIZED_ITEM, value: item };
}
