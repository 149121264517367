import { KortexTextField, theme } from "@aos/react-components";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Button, makeStyles, MenuItem } from "@material-ui/core";
import React, { FC, useState } from "react";

import { SearchType, useBomContext } from "../context/bomContext";

const useStyles = makeStyles({
    root: {
        borderBottom: `1px solid ${theme.palette.grey["400"]}`,
        display: "inline-flex",
        gap: "10px",
        paddingBottom: "16px",
        width: "100%",
    },
    spacer: {
        flex: 1,
    },
    textField: {
        width: "300px",
    },
});

const BomHeader: FC = () => {
    const classes = useStyles();
    const translate = useTranslate();

    const { updateQuery } = useBomContext();

    const [search, setSearch] = useState<string>("");
    const [searchType, setSearchType] = useState<SearchType>("tid");

    /**
     * Assigns adjustment to the search value
     */
    /*
        // TODO: AOS-2226, AOS-2227, AOS-2228, AOS-2523
        const handleAdjustmentChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
            setSearch(event.target.value as AdjustmentType);
        };
    */

    /**
     * TODO: Does something when the "Export" button is clicked (TODO: AOS-2550)
     */
    /*
    const handleExport = (): void => {
        // TODO: Do stuff...
        console.log("Export");
    };
    */

    /**
     * Sends search infos to context
     */
    const handleGenerate = (): void => {
        updateQuery({ key: "type", value: "tid" }, { key: "tid", value: search });
    };

    /**
     * Generate a report on Enter
     */
    const handleKeyDown = (event: React.KeyboardEvent): void => {
        event.stopPropagation();

        if (event.key === "Enter") {
            event.preventDefault();
            handleGenerate();
        }
    };

    /**
     * Updates the search value
     */
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearch(event.target.value);
    };

    /**
     * Updates the search type
     */
    const handleSearchTypeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const newSearchType = event.target.value as SearchType;

        setSearchType(newSearchType);
        setSearch("");
    };

    return (
        <div className={classes.root}>
            {/* SEARCH TYPE SELECTOR*/}
            <KortexTextField
                className={classes.textField}
                label={translate("bomPage.searchType")}
                onChange={handleSearchTypeChange}
                TextFieldProps={{
                    id: "bomHeaderSearchTypeId",
                    select: true,
                }}
                value={searchType}
            >
                {[
                    // TODO: AOS-2226, AOS-2227, AOS-2228: Remove array when new search types are added
                    <MenuItem id="bomHeaderSearchTypeTracking" key="bomHeaderSearchTypeTracking" value="tid">
                        {translate("bomPage.searchType.trackingId")}
                    </MenuItem>,
                ]}
                {/*
                    TODO: AOS-2226
                    <MenuItem value="pn" id="bomHeaderSearchTypePartNumber">
                        {translate("bomPage.searchType.partNumber")}
                    </MenuItem>
                */}
                {/*
                    // TODO: AOS-2227
                    <MenuItem value="t" id="bomHeaderSearchTypeTraceability">
                        {translate("bomPage.searchType.traceability")}
                    </MenuItem>
                */}
                {/*
                    // TODO: AOS-2228
                    <MenuItem value="at" id="bomHeaderSearchTypeAdjustmentType">
                        {translate("bomPage.searchType.adjustmentType")}
                    </MenuItem>
                */}
                {/*
                    TODO: AOS-2523
                    <MenuItem value="wo" id="bomHeaderSearchTypeJob">
                        {translate("bomPage.searchType.job")}
                    </MenuItem>
                */}
            </KortexTextField>
            {/* SEARCH VALUE TEXTFIELD */}
            {/*
                // TODO: AOS-2228
                {searchType === "adjustmentType" ? (
                    <KortexTextField
                        className={classes.textField}
                        label={translate("bomPage.adjustmentType")}
                        onChange={handleAdjustmentChange}
                        TextFieldProps={{
                            id: "bomHeaderSearchAdjustementTypeId",
                            select: true,
                        }}
                        value={search}
                    >
                        Items' value must be one of the 'AdjustmentType'
                        <MenuItem value="standard" id="bomHeaderAjustmentTypeStandard">
                            {translate("bomPage.adjustmentType.standard")}
                        </MenuItem>
                        <MenuItem value="overconsumption" id="bomHeaderAjustmentTypeOverconsumption">
                            {translate("bomPage.adjustmentType.overconsumption")}
                        </MenuItem>
                        <MenuItem value="replacement" id="bomHeaderAjustmentTypeReplacement">
                            {translate("bomPage.adjustmentType.replacement")}
                        </MenuItem>
                        <MenuItem value="update" id="bomHeaderAjustmentTypeUpdate">
                            {translate("bomPage.adjustmentType.update")}
                        </MenuItem>
                    </KortexTextField>
                ) : (
                )}
            */}
            <KortexTextField
                className={classes.textField}
                label={translate("bomPage.value")}
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}
                TextFieldProps={{
                    autoComplete: "off",
                    id: "bomHeaderSearchId",
                }}
                value={search}
            />
            {/* GENERATE BUTTON */}
            <Button color="secondary" disabled={!search.trim()} id="generateBomId" onClick={handleGenerate} variant="outlined">
                {translate("bomPage.generate")}
            </Button>
            {/* SPACER */}
            <div className={classes.spacer} />
            {/* EXPORT BUTTON */}
            {/* 
                TODO: AOS-2550
                <Button color="secondary" onClick={handleExport} variant="outlined">
                    {translate("bomPage.export")}
                </Button>
            */}
        </div>
    );
};

export default BomHeader;
