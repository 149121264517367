import { KortexDateTimePicker, KortexTextField, greyPalette, secondaryPalette, theme, warningPalette } from "@aos/react-components";
import {
    ApprovalStatusEnum,
    IElectronicSignatureDbModel,
    IProcessReleaseLogDbModel,
    IProcessUiModel,
    IUserDbModel,
    IUserGroupDbModel,
    OrUndefined,
    ProcessApprovalType,
    ProcessEditorRightsEnum,
    ProcessId,
    ProcessReleaseLogActionEnum,
    ProcessReleaseRightsEnum,
    ProcessTrainingRequirementEnum,
    ProcessType,
    ProcessUiModel,
    ProcessValidation,
    Task,
    TaskStatusEnum,
    TreeNodeId,
    UserDbModel,
    UserGroupDbModel,
    formatDate,
    getFormattedDate,
    getTimestampFromDateStr,
    getWorkInstructionsStepsWithTrainingCommunique,
} from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    List,
    ListItem,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    makeStyles,
} from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import ExpandMore from "@material-ui/icons/ExpandMore";
import RecoverIcon from "@material-ui/icons/FileCopy";
import FindIcon from "@material-ui/icons/FindInPage";
import InfoIcon from "@material-ui/icons/Info";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import WarningIcon from "@material-ui/icons/Warning";
import { Alert, AlertTitle } from "@material-ui/lab";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React, { ReactText, useEffect, useState } from "react";

import KortexLabel from "../../../../../components/core/KortexLabel";
import KortexLabelIcon from "../../../../../components/core/KortexLabelIcon";
import KortexLabelText from "../../../../../components/core/KortexLabelText";
import { useTranslate } from "../../../../../hooks/useTranslate";
import {
    useEntitiesElectronicSignature,
    useEntitiesSettingOrganizations,
    useEntitiesTasks,
    useEntitiesUsers,
    useEntitiesUsersGroups,
} from "../../../../../redux/effects";
import {
    processJobProcessNewProcessVersionUpdate,
    processUpdate,
    processUpdateVersionTraining,
    processVersionCancel,
    processVersionHold,
    processVersionInsert,
    processVersionRecover,
} from "../../../../../redux/process-manager/process-thunks-process";
import { useSelectorUserSession } from "../../../../../redux/selectors";
import { ProcessStatus, getProcessStatus } from "../../../../../utilitites/getProcessStatus";
import { isProcessValid } from "../../../../../utilitites/process";
import { UserValidationDialog } from "../../../../core/UserValidationDialog";
import ProcessUpdateVersionDialog from "../../../ProcessUpdateVersion/ProcessUpdateVersionDialog";
import ProcessValidationDrawer from "../ProcessValidation/ProcessValidationDrawer";
import { useProcessEditorContext } from "../context";

import { getProcessReleaseStatusLabelKey, getProcessVersionStatusLabelKey, statusColor } from "./ProcessApprovalCard";
import ProcessApprovalGroupAreYouSureDialog from "./ProcessApprovalGroupAreYouSureDialog";
import ProcessApprovalRecoverVersionDialog from "./ProcessApprovalRecoverVersionDialog";
import ProcessApprovalReviewRequestDialog from "./ProcessApprovalReviewRequestDialog";
import ProcessApprovalWhereUsedDialog from "./ProcessApprovalWhereUsedDialog";
import ProcessRejectVersionDialog from "./ProcessRejectVersionDialog";
import UserGroupSelector, { IGroupLabel } from "./UserGroupSelector";
import { ProcessTrainingCopyPicker } from "./processTrainingCopyPicker";

const useStyles = makeStyles({
    root: {
        backgroundColor: greyPalette[50],
        marginBottom: "16px",
        position: "relative",
    },
    rootGrid: {
        display: "grid",
        gridTemplateColumns: "10px 1fr",
        height: "100%",
    },
    editorContainer: {
        display: "grid",
        gridTemplateRows: "auto 1fr auto",
        paddingTop: "15px",
    },
    cardApprobation: {
        marginTop: "8px",
        display: "grid",
        gridTemplateColumns: "1fr",
        justifyContent: "space-between",
        color: greyPalette[600],
        maxWidth: "600px",
    },
    bottomMenu: {
        backgroundColor: theme.palette.primary[500],
        color: theme.palette.common.white,
        borderTop: "solid 1px ",
        borderTopColor: greyPalette[400],
        flexFlow: "row",
        flexDirection: "row",
        display: "flex",
        padding: 0,
    },
    contentBlock: {
        margin: "0 15px 30px 15px",
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        border: `2px solid ${theme.palette.secondary[100]}`,
    },
    contentGrid: {
        marginTop: "15px",
        display: "grid",
        gridAutoFlow: "column",
        alignItems: "center",
    },
    changeLog: {
        marginTop: "8px",
        display: "flex",
    },
    circularProgress: {
        height: "24px !important", // Overwrite the style (set by material-ui) that is applied on top of this class
        marginLeft: "10px",
        width: "24px !important", // Overwrite the style (set by material-ui) that is applied on top of this class
    },
    dateSelect: {
        marginRight: "5px",
    },
    retireLine: {
        marginTop: "10px",
        alignItems: "center",
        display: "inline-flex",
    },
    groupNameContainer: {
        marginBottom: "8px",
    },
    menuItem: {
        width: "56px",
    },
    referenceIdTextField: {
        width: "150px",
    },
    select: {
        backgroundColor: "transparent",
        border: "0px",
    },
    versionSelect: {
        marginRight: "8px",
        width: "150px",
    },
    datePickerLabel: {
        color: secondaryPalette[500],
        display: "inline-block",
        marginTop: "5px",
    },
    datePickerInput: {
        fontSize: "1rem",
        padding: "5px",
    },
    spacer: {
        flex: 1,
    },
    buttonsBar: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "10px",
    },
    actionButton: {
        marginRight: "5px",
    },
    accordion: {
        marginTop: "15px",
    },
    approvalsTitle: {
        margin: "10px 0px",
    },
    headerLabel: {
        display: "flex",
        alignItems: "center",
    },
    changeLogText: {
        whiteSpace: "pre-line",
        wordWrap: "break-word",
    },
    trainingRequirementSection: {
        alignItems: "center",
        display: "flex",
        gap: "10px",
    },
    trainingRequirementSelector: {
        marginTop: "15px",
        width: "350px",
    },
    processTrainingsCopyEnabledContainer: {
        display: "flex",
    },
    processTrainingsCopyDivider: {
        margin: "10px 0",
    },
    processTrainingsCopyTypography: {
        margin: "10px 0",
    },
    warningAnotherProcessPendingApproval: {
        margin: "0px 15px 15px 15px",
    },
    iconInfo: {
        cursor: "pointer",
        color: theme.palette.info.main,
        marginRight: "4px",
    },
    iconWarning: {
        cursor: "pointer",
        color: warningPalette[500],
        marginRight: "4px",
    },
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
    },
});

interface ISelectableVersion {
    key: string;
    label: string;
}

interface IOwnProps {
    versionInfo: ProcessUiModel;
    lastVersion?: string;
    approvedInfoOnly?: boolean;
    reviewOnly?: boolean;
    isDraftLocked: boolean;
    anotherVersionPendingApproval?: boolean;
    onReviewDone?: () => void;
    onPrintVersion?: () => void;
    onApproveVersion?: (
        referenceId: string,
        isApproved: boolean,
        comment: string,
        validatedByUserId?: number,
        username?: string,
        password?: string,
        consented?: boolean
    ) => Promise<void>;
    onUpdateReleaseDates?: (
        releaseDate: number,
        retiredOn: number,
        comments: string,
        validatedByUserId?: number,
        username?: string,
        password?: string,
        consented?: boolean
    ) => Promise<void>;
}

enum UserValidationReleaseEnum {
    CANCEL,
    HOLD,
    NONE,
    RELEASE,
}

enum ElectronicSignatureEnum {
    APPROVAL = "APPROVAL",
    RELEASE = "RELEASE",
}

const TRAINING_REQUIREMENT_NOT_SELECTED = -1;

function getprocessReleaseLogActionLabel(action: ProcessReleaseLogActionEnum, translate: ReturnType<typeof useTranslate>): string {
    switch (action) {
        case ProcessReleaseLogActionEnum.CANCEL:
            return translate("process.versioning.actionCancelledVersion");
        case ProcessReleaseLogActionEnum.DATE_UPDATE:
            return translate("process.versioning.actionReleaseDateUpdate");
        case ProcessReleaseLogActionEnum.HOLD:
            return translate("process.versioning.actionVersionOnHold");
        default:
            return "";
    }
}

export default function ProcessApprovalCardEditor(props: IOwnProps): JSX.Element {
    const { versionInfo, isDraftLocked } = props;

    // Hooks
    const classes = useStyles();
    const organisationSettings = useEntitiesSettingOrganizations();
    const taskProcessApproval = useEntitiesTasks({
        type: Task.TYPE.PROCESS_APPROVAL,
        linkId: versionInfo.processId,
    });
    const electronicSignatures = useEntitiesElectronicSignature();

    const dispatch = useThunkDispatch();
    const userGroups = useEntitiesUsersGroups({ isActive: true });

    const users = useEntitiesUsers();
    const translate = useTranslate();
    const userInfo = useSelectorUserSession();
    const { bom } = useProcessEditorContext();
    const isTrainingEnabled = organisationSettings[0].trainingEnabled && versionInfo.type === ProcessType.PROCESS;

    const DEFAULT_POSSIBLE_VERSIONS: ISelectableVersion[] = [
        { key: "0.1", label: `${translate("process.versioning.minor")} (0.1)` },
        { key: "1.0", label: `${translate("process.versioning.major")} (1.0)` },
    ];

    const [userValidationApprovalOpen, setUserValidationApprovalOpen] = useState<boolean>(false);
    const [userValidationReleaseOpen, setUserValidationReleaseOpen] = useState<UserValidationReleaseEnum>(UserValidationReleaseEnum.NONE);
    const [possibleVersions, setPossibleVersions] = useState<ISelectableVersion[]>(DEFAULT_POSSIBLE_VERSIONS);
    const [processWhereUsedDialogOpened, setProcessWhereUsedDialogOpened] = useState<boolean>(false);
    const [selectedVersion, setSelectedVersion] = useState<string>(DEFAULT_POSSIBLE_VERSIONS[0].key);
    const [releasedDateMs, setReleaseDateMs] = useState<number>(versionInfo.releasedOn ? versionInfo.releasedOn : 0); // local time in milliseconds
    const [retiredDateMs, setRetireDateMs] = useState<number>(versionInfo.retiredOn ? versionInfo.retiredOn : 0); // local time in milliseconds
    const [referenceId, setReferenceId] = useState<string>(versionInfo.versionReferenceCode || "");
    const [submittedDate, setSubmittedDate] = useState<number>(versionInfo.createdOn || 0);
    const [trainingRequirement, setTrainingRequirement] = useState(
        versionInfo.isDraft ? TRAINING_REQUIREMENT_NOT_SELECTED : versionInfo.trainingRequirement
    );
    const [reviewRequestDialogOpened, setReviewRequestDialogOpened] = useState<boolean>(false);
    const [releaseLogsExpanded, setReleaseLogsExpanded] = useState<boolean>(false);
    const [approvalGroups, setApprovalGroups] = useState<UserGroupDbModel["userGroupId"][]>([]);
    const [approvalUsers, setApprovalUsers] = useState<UserDbModel["userId"][]>([]);
    const [recoverVersionDialogOpened, setRecoverVersionDialogOpened] = useState(false);
    const [rejectVersionDialogOpened, setRejectVersionDialogOpened] = useState(false);
    const [comments, setComments] = useState("");
    const [processValidationDrawerOpened, setProcessValidationDrawerOpened] = useState(false);
    const [processApprovalGroupAreYouSureDialogOpen, setProcessApprovalGroupAreYouSureDialogOpen] = useState(false);
    const [buttonApproveDisabled, setButtonApproveDisabled] = useState(false);
    const [isSubmitInProgress, setIsSubmitInProgress] = useState<boolean>(false);
    const [missingTrainingCommunique, setMissingTrainingCommunique] = useState(false);
    const [trainingsCopyEnabled, setTrainingsCopyEnabled] = useState(Boolean(versionInfo.trainingsCopy?.processId) ?? false);
    const [trainingsCopiedFromProcessId, setTrainingsCopiedFromProcessId] = useState<ProcessId | undefined>(
        versionInfo.trainingsCopy?.processId
    );

    // UPDATE PROCESS DIALOG
    const [updateProcessDialogOpened, setUpdateProcessDialogOpened] = useState<boolean>(false);

    const [processValidation, setProcessValidation] = useState<OrUndefined<Readonly<ProcessValidation>>>(undefined);

    // Process version status
    const processStatus = getProcessStatus(versionInfo);

    // Rights
    const hasAccessLevelToInsertInProcessEditor = userInfo && userInfo.roleRights.processEditor >= ProcessEditorRightsEnum.INSERT;
    const hasReleaseRights = userInfo?.roleRights.processRelease === ProcessReleaseRightsEnum.WRITE;

    // Can process version be released
    const canBeCancelled =
        (versionInfo.versionStatus === ApprovalStatusEnum.APPROVED || versionInfo.versionStatus === ApprovalStatusEnum.HOLD) &&
        !versionInfo.releasedOn &&
        hasReleaseRights;
    const canBeOnHold = versionInfo.versionStatus === ApprovalStatusEnum.APPROVED && !versionInfo.releasedOn && hasReleaseRights;
    const canBeReleased =
        (versionInfo.versionStatus === ApprovalStatusEnum.APPROVED || versionInfo.versionStatus === ApprovalStatusEnum.HOLD) &&
        !versionInfo.releasedOn &&
        hasReleaseRights;

    /**
     * Set props to inner state whenever the version info changes
     */
    useEffect((): void => {
        setReleaseDateMs(versionInfo.releasedOn ? versionInfo.releasedOn : 0);
        setRetireDateMs(versionInfo.retiredOn ? versionInfo.retiredOn : 0);
        setSubmittedDate(versionInfo.createdOn || 0);
        setComments(""); // Clear Comments
    }, [versionInfo]);

    /**
     * Update reference ID state on prop change
     */
    useEffect((): void => {
        setReferenceId(versionInfo.versionReferenceCode ? versionInfo.versionReferenceCode : "");
    }, [versionInfo.versionReferenceCode]);

    /**
     * Update training requirement state on prop change
     */
    useEffect((): void => {
        setTrainingRequirement(versionInfo.isDraft ? TRAINING_REQUIREMENT_NOT_SELECTED : versionInfo.trainingRequirement);
    }, [versionInfo.isDraft, versionInfo.trainingRequirement]);

    /**
     * Update "trainings copied from" state on prop change
     */
    useEffect((): void => {
        setTrainingsCopiedFromProcessId(versionInfo.trainingsCopy?.processId);
        setTrainingsCopyEnabled(Boolean(versionInfo.trainingsCopy?.processId) ?? false);
    }, [versionInfo.trainingsCopy?.processId]);

    /**
     * checks if the user can approve the process
     */
    useEffect((): void => {
        if (userInfo && taskProcessApproval) {
            const tasks = taskProcessApproval.filter((task) => userInfo.userGroups.includes(task.groupId));
            for (const task of tasks) {
                if (task.status === TaskStatusEnum.OPEN) {
                    // If the user has several groups associated with his account, keep the button activated if there is at least one task open.
                    setButtonApproveDisabled(false);
                    break;
                } else {
                    setButtonApproveDisabled(true);
                }
            }
        }
    }, [userInfo, taskProcessApproval]);

    /**
     * Set possible versions to select input
     */
    useEffect((): void => {
        let newPossibleVersions: ISelectableVersion[] = [];

        if (props.lastVersion) {
            const dotIndex = props.lastVersion.indexOf(".");
            const major = parseInt(props.lastVersion.substring(0, dotIndex), 10);
            const minor = parseInt(props.lastVersion.substring(dotIndex + 1, props.lastVersion.length), 10);

            newPossibleVersions = [
                {
                    key: `${major}.${minor + 1}`,
                    label: `${translate("process.versioning.minor")} (${major}.${minor + 1})`,
                },
                { key: `${major + 1}.0`, label: `${translate("process.versioning.major")} (${major + 1}.0)` },
            ];
        } else {
            newPossibleVersions = [];
        }

        if (newPossibleVersions !== possibleVersions) {
            setPossibleVersions(newPossibleVersions);
        }

        if (
            newPossibleVersions.length > 0 &&
            !newPossibleVersions.some((possibleVersion: ISelectableVersion): boolean => possibleVersion.key === selectedVersion)
        ) {
            setSelectedVersion(newPossibleVersions[0].key);
        }
    }, [props.lastVersion]);

    /**
     * Check if Submit button is disabled
     */
    const isSubmitDisabled = (): boolean => {
        // Does the user have the rights to submit a new version?
        if (!hasAccessLevelToInsertInProcessEditor) {
            return true;
        }

        // Is a version already being submitted
        if (isSubmitInProgress) {
            return true;
        }

        // Is this version a draft?
        if (!versionInfo.isDraft) {
            return true;
        }

        // Is there another version pending approval
        if (props.anotherVersionPendingApproval) {
            return true;
        }

        // Is there a reference ID?
        if (!referenceId.trim()) {
            return true;
        }

        // Is it a process and are there approval groups?
        if (
            versionInfo.type === ProcessType.PROCESS &&
            Boolean(organisationSettings.length) &&
            (organisationSettings[0].processApproval === ProcessApprovalType.MANUAL ||
                organisationSettings[0].processApproval === ProcessApprovalType.ELECTRONIC_SIGNATURE) &&
            !userGroups.filter((group) => group.isApprover).length
        ) {
            return true;
        }

        // Is it a routing and are there approval groups?
        if (
            Boolean(organisationSettings.length) &&
            (organisationSettings[0].routingApproval === ProcessApprovalType.MANUAL ||
                organisationSettings[0].routingApproval === ProcessApprovalType.ELECTRONIC_SIGNATURE) &&
            !userGroups.filter((group) => group.isApprover).length
        ) {
            return true;
        }

        // Is there a changelog?
        if (!versionInfo.versionChangeLog?.trim()) {
            return true;
        }

        // Is a training requirement selected
        if (isTrainingEnabled && trainingRequirement === TRAINING_REQUIREMENT_NOT_SELECTED) {
            return true;
        }

        return false;
    };

    /**
     * Used to submit a review request
     */
    const handleOpenReviewRequestDialog = (): void => {
        setReviewRequestDialogOpened(true);
    };

    /**
     * Validates a process and returns whether it has errors or not.
     */
    const processIsValid = (editedProcess: IProcessUiModel): boolean => {
        return isProcessValid(
            editedProcess,
            (error) => {
                setProcessValidation(error);
                setProcessValidationDrawerOpened(true);
            },
            bom?.items
        );
    };

    /**
     * Used to submit a new process version
     */
    const handleSubmitVersion = (): void => {
        if (processIsValid(versionInfo)) {
            const isApproverGroups = userGroups.filter((group) => group.isApprover);
            const isMissingTrainingCommunique =
                trainingRequirement > ProcessTrainingRequirementEnum.NONE &&
                !getWorkInstructionsStepsWithTrainingCommunique(versionInfo).length;

            setMissingTrainingCommunique(isMissingTrainingCommunique);

            if (
                approvalGroups.length != isApproverGroups.length || // Invalid approval group count
                isMissingTrainingCommunique
            ) {
                setProcessApprovalGroupAreYouSureDialogOpen(true);
            } else {
                sendCreateVersion();
            }
        }
    };

    /**
     * Call create version callback with all info
     */
    const sendCreateVersion = (): void => {
        setIsSubmitInProgress(true);

        dispatch(
            processVersionInsert({
                changeLog: versionInfo.versionChangeLog ?? "",
                processId: versionInfo.processId,
                referenceId,
                version: selectedVersion,
                approvalUsers,
                approvalGroups,
                trainingRequirement: isTrainingEnabled ? trainingRequirement : 0,
                trainingsCopiedFromProcessId: trainingsCopyEnabled ? trainingsCopiedFromProcessId : undefined,
            })
        ).then(() => {
            setIsSubmitInProgress(false);
            setReferenceId("");
            setTrainingRequirement(TRAINING_REQUIREMENT_NOT_SELECTED);
        });
    };

    /**
     * Handle closing (Cancel) of the process approval are you sure dialog
     */
    const handleProcessApprovalGroupAreYouSureDialogCancel = (): void => {
        setProcessApprovalGroupAreYouSureDialogOpen(false);
    };

    /**
     * Handle closing (Confirm/Proceed) of the process approval are you sure dialog
     */
    const handleProcessApprovalGroupAreYouSureDialogConfirm = (): void => {
        sendCreateVersion();
        setProcessApprovalGroupAreYouSureDialogOpen(false);
    };

    /**
     * Used to complete a review
     */
    const handleReviewDone = (): void => {
        if (props.onReviewDone) {
            props.onReviewDone();
        }
    };

    /**
     * Handles the release date changes
     *
     * @param {MaterialUiPickersDate} date - selected date
     */
    const handleReleaseDateChange = (date: MaterialUiPickersDate): void => {
        setReleaseDateMs(date ? getTimestampFromDateStr(String(date)) : 0);
    };

    /**
     * Handles the retire date changes
     *
     * @param {MaterialUiPickersDate} date - change event data
     */
    const handleRetireDateChange = (date: MaterialUiPickersDate): void => {
        setRetireDateMs(date ? getTimestampFromDateStr(String(date)) : 0);
    };

    /**
     * Handles the process training requirement changes
     *
     * @param {object} event - change event data
     */
    const handleTrainingRequirementChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const udpatedTrainingRequirement = parseInt(event.target.value, 10);

        setTrainingRequirement(udpatedTrainingRequirement);

        if (!versionInfo.isDraft) {
            dispatch(
                processUpdateVersionTraining({
                    processId: versionInfo.processId,
                    trainingRequirement: udpatedTrainingRequirement,
                    trainingsCopiedFromProcessId,
                })
            );
        }
    };

    /**
     * Handles the process version changes
     *
     * @param {object} event - change event data
     */
    const handleVersionChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        setSelectedVersion(event.target.value);
    };

    /**
     * Handles the changelog changes
     *
     * @param {object} text - change event data
     */
    const handleChangeLogChanged = (text: ReactText): void => {
        dispatch(
            processUpdate({
                ...versionInfo,
                versionChangeLog: text.toString(),
            })
        );
    };

    /**
     * Handles reference id changes
     *
     * @param {object} event - change event data
     */
    const handleReferenceIdChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setReferenceId(event.target.value);
    };

    /**
     * Returns an error message if release date is invalid
     */
    const getInvalidReleaseDateErrorMessage = (): string => {
        if (retiredDateMs) {
            if (!releasedDateMs) {
                return translate("process.versioning.invalidDate");
            }

            if (retiredDateMs < releasedDateMs) {
                return translate("process.versioning.releasedDateAfterRetireDate");
            }

            if (releasedDateMs === retiredDateMs) {
                return translate("process.versioning.releasedDateEqualToRetireDate");
            }
        }

        return ""; // No error
    };

    /**
     * Returns an error message if retire date is invalid
     */
    const getInvalidRetireDateErrorMessage = (): string => {
        if (releasedDateMs) {
            if (releasedDateMs && retiredDateMs && releasedDateMs > retiredDateMs) {
                return translate("process.versioning.retiredDateBeforeReleasedDate");
            }

            if (retiredDateMs === releasedDateMs) {
                return translate("process.versioning.releasedDateEqualToRetireDate");
            }
        }

        return ""; // No error
    };

    /**
     * Get the name of a user group
     *
     * @param {number | undefined} userGroupId - user group id
     */
    const getUserGroupName = (userGroupId?: number): JSX.Element => {
        if (!userGroups) {
            return <></>;
        }

        const userGroup = userGroups.find((userGroup: UserGroupDbModel): boolean => userGroup.userGroupId === userGroupId);

        return userGroup ? <b>{userGroup.name}</b> : <></>;
    };
    /**
     * Get the name of a user required to approve
     *
     * @param {number | undefined} userGroupId - user group id
     */
    const getApproverGroupName = (userGroupId?: number): string => {
        if (!userGroups) {
            return "";
        }

        const userGroup = userGroups.find((userGroup: UserGroupDbModel): boolean => userGroup.userGroupId === userGroupId);

        return userGroup ? userGroup.name : "";
    };

    /**
     * Get the name of a user
     *
     * @param {number | undefined} userId - user group id
     */
    const getUserFullName = (userId?: number): string => {
        if (!users) {
            return "";
        }

        const user = users.find((user: UserDbModel): boolean => user.userId === userId);

        return user ? user.firstName + " " + user.lastName : "";
    };

    /**
     * Get the name of the user who signed
     *
     * @param {number} electronicSignatureId - user id
     */
    const getElectronicSignatureFullName = (electronicSignatureId: number): string => {
        if (!electronicSignatures) {
            return "";
        }

        const signature = electronicSignatures.find(
            (signature: IElectronicSignatureDbModel): boolean => signature.electronicSignatureId === electronicSignatureId
        );

        return signature ? getUserFullName(signature.userId) : "";
    };

    /**
     * Opens the process "Where used" dialog
     */
    const handleOpenWhereUsedDialog =
        (open: boolean): (() => void) =>
        (): void => {
            setProcessWhereUsedDialogOpened(open);
        };

    /**
     * Opens the process "Update Process" dialog
     */
    const handleUpdateProcessDialog =
        (open: boolean): (() => void) =>
        (): void => {
            setUpdateProcessDialogOpened(open);
        };

    /**
     * Opens the process "Update Process" dialog
     */
    const handleUpdateProcess = (jobProcessList: number[]): void => {
        setUpdateProcessDialogOpened(false);

        /* Updating Process List */
        dispatch(processJobProcessNewProcessVersionUpdate({ jobProcessIds: jobProcessList, processId: versionInfo.processId }));
    };

    /**
     * Close the review request dialog
     */
    const handleReviewRequestClose = (): void => {
        setReviewRequestDialogOpened(false);
    };

    /**
     * Handles the approval of a version
     *
     * @param {boolean} isApproved - true if approved, false if rejected
     */
    const handleApproveVersion =
        (isApproved: boolean, comment: string): (() => void) =>
        (): void => {
            if (isApproved) {
                if (
                    (versionInfo.type === ProcessType.PROCESS &&
                        organisationSettings.length &&
                        organisationSettings[0].processApproval !== ProcessApprovalType.AUTO) ||
                    (versionInfo.type === ProcessType.ROUTING &&
                        organisationSettings.length &&
                        organisationSettings.length &&
                        organisationSettings[0].routingApproval !== ProcessApprovalType.AUTO)
                ) {
                    setUserValidationApprovalOpen(true);
                    setButtonApproveDisabled(true);
                } else {
                    if (props.onApproveVersion) {
                        props.onApproveVersion(referenceId, isApproved, comment);
                    }
                }
            } else {
                if (props.onApproveVersion) {
                    props.onApproveVersion(referenceId, isApproved, comment);
                }
                setRejectVersionDialogOpened(false);
            }
        };

    /**
     * handles the rejection of the approval
     *
     * @param {string} comment - comment by the user who rejected the approval
     */
    const handleRejection = (comment: string): void => {
        handleApproveVersion(false, comment)();
    };

    /**
     * handles user validation close
     */
    const handleUserValidationClose =
        (type: ElectronicSignatureEnum): (() => void) =>
        (): void => {
            if (type === ElectronicSignatureEnum.APPROVAL) {
                setUserValidationApprovalOpen(false);
            } else {
                setUserValidationReleaseOpen(UserValidationReleaseEnum.NONE);
            }
        };

    /**
     * handles the user validation
     *
     * @param {ElectronicSignatureEnum} type - the type
     */
    const handleUserValidation =
        (
            type: ElectronicSignatureEnum
        ): ((validatedByUserId: number, username: string, password: string, consented: boolean) => Promise<void>) =>
        async (validatedByUserId: number, username: string, password: string, consented: boolean): Promise<void> => {
            if (type === ElectronicSignatureEnum.APPROVAL) {
                if (validatedByUserId) {
                    if (props.onApproveVersion) {
                        await props.onApproveVersion(referenceId, true, "", validatedByUserId, username, password, consented);
                    }
                }
                setButtonApproveDisabled(true);
                setUserValidationApprovalOpen(false);
            } else {
                if (validatedByUserId) {
                    if (userValidationReleaseOpen === UserValidationReleaseEnum.RELEASE) {
                        await props.onUpdateReleaseDates?.(
                            releasedDateMs,
                            retiredDateMs,
                            comments,
                            validatedByUserId,
                            username,
                            password,
                            consented
                        );
                    } else if (userValidationReleaseOpen === UserValidationReleaseEnum.CANCEL) {
                        await dispatch(
                            processVersionCancel({
                                comments,
                                password,
                                processId: versionInfo.processId,
                                userId: validatedByUserId,
                                username,
                            })
                        );
                    } else if (userValidationReleaseOpen === UserValidationReleaseEnum.HOLD) {
                        await dispatch(
                            processVersionHold({
                                comments,
                                password,
                                processId: versionInfo.processId,
                                userId: validatedByUserId,
                                username,
                            })
                        );
                    }

                    setComments(""); // Clear Comments
                }
                setUserValidationReleaseOpen(UserValidationReleaseEnum.NONE);
            }
            return void 0;
        };

    const requiresUserValidation = (): boolean => {
        return (
            (versionInfo.type === ProcessType.PROCESS &&
                organisationSettings.length > 0 &&
                organisationSettings[0].processRelease !== ProcessApprovalType.AUTO) ||
            (versionInfo.type === ProcessType.ROUTING &&
                organisationSettings.length > 0 &&
                organisationSettings[0].routingRelease !== ProcessApprovalType.AUTO)
        );
    };

    /**
     * Handles the release and retire date changes
     */
    const handleUpdateReleaseDates = (): void => {
        if (requiresUserValidation()) {
            setUserValidationReleaseOpen(UserValidationReleaseEnum.RELEASE);
        } else if (props.onUpdateReleaseDates) {
            props.onUpdateReleaseDates(releasedDateMs, retiredDateMs, comments, userInfo?.userId);
            setComments(""); // Clear Comments
        }
    };

    /**
     * Put a process version on hold
     */
    const handlePutVersionOnHold = (): void => {
        if (requiresUserValidation()) {
            setUserValidationReleaseOpen(UserValidationReleaseEnum.HOLD);
        } else {
            dispatch(
                processVersionHold({
                    comments,
                    processId: versionInfo.processId,
                    userId: userInfo?.userId,
                })
            );
            setComments(""); // Clear Comments
        }
    };

    /**
     * Handles the release and retire date changes
     */
    const handleCancelVersion = (): void => {
        if (requiresUserValidation()) {
            setUserValidationReleaseOpen(UserValidationReleaseEnum.CANCEL);
        } else {
            dispatch(
                processVersionCancel({
                    comments,
                    processId: versionInfo.processId,
                    userId: userInfo?.userId,
                })
            );
            setComments(""); // Clear Comments
        }
    };

    /**
     * returns if electronic signature is needed
     */
    const isElectronicSignature = (type: ElectronicSignatureEnum): boolean => {
        if (organisationSettings.length) {
            if (type === ElectronicSignatureEnum.APPROVAL) {
                if (versionInfo.type === ProcessType.PROCESS) {
                    return organisationSettings[0].processApproval === ProcessApprovalType.ELECTRONIC_SIGNATURE;
                } else {
                    return organisationSettings[0].routingApproval === ProcessApprovalType.ELECTRONIC_SIGNATURE;
                }
            } else {
                if (versionInfo.type === ProcessType.PROCESS) {
                    return organisationSettings[0].processRelease === ProcessApprovalType.ELECTRONIC_SIGNATURE;
                } else {
                    return organisationSettings[0].routingRelease === ProcessApprovalType.ELECTRONIC_SIGNATURE;
                }
            }
        } else {
            return false;
        }
    };

    /**
     * returns true if the process needs approval groups
     */
    const needsApprovalGroups = (): boolean => {
        if (organisationSettings.length) {
            return !(
                (versionInfo.type === ProcessType.PROCESS && organisationSettings[0].processApproval === ProcessApprovalType.AUTO) ||
                (versionInfo.type === ProcessType.ROUTING && organisationSettings[0].routingApproval === ProcessApprovalType.AUTO)
            );
        } else {
            return false;
        }
    };

    /**
     * get the process release logs released by info
     *
     * @param {number} userId - id
     */
    const getProcessReleaseLogReleasedByInfo = (userId: number): JSX.Element => {
        const user = users.find((user) => user.userId === userId);
        if (user) {
            return (
                <>
                    {user.firstName} {user.lastName}
                </>
            );
        }
        return <></>;
    };

    /**
     * handles release logs expand
     */
    const handleReleaseLogsExpand = (): void => {
        setReleaseLogsExpanded(!releaseLogsExpanded);
    };

    /**
     * User filter for user selection
     *
     * @param {IUserGroupDbModel} group - the current group
     * @param {string} searchValue - search string
     */
    const userFilter =
        (group: IUserGroupDbModel, searchValue: string) =>
        (user: IUserDbModel): boolean =>
            Boolean(group.userGroupId) &&
            user.groups.includes(group.userGroupId) &&
            (user.firstName + " " + user.lastName).toLocaleLowerCase("en").includes(searchValue.toLocaleLowerCase("en"));

    /**
     * handles user selection
     *
     * @param {number} selectedUsers - selected Users
     * param {number} selectedGroups - selected Groups
     */
    const handleUserSelect = (selectedUsers: UserDbModel["userId"][]): void => {
        setApprovalUsers(selectedUsers);
    };

    /**
     * handles group selection
     *
     * @param {number} selectedGroups - selected Groups
     */
    const handleGroupSelect = (selectedGroups: UserGroupDbModel["userGroupId"][]): void => {
        setApprovalGroups(selectedGroups);
    };

    /**
     * Get the group lables
     */
    const getGroupLabels = (): IGroupLabel[] => {
        return userGroups.map(
            (group, index): IGroupLabel => ({
                userGroupId: group.userGroupId,
                label: (
                    <div key={index}>
                        {translate("process.versioning.minimumApprovers")}: {group.approverQty}
                    </div>
                ),
            })
        );
    };

    /**
     * Get the group header lables
     */
    const getGroupHeaderLabels = (): IGroupLabel[] => {
        return userGroups.map(
            (group): IGroupLabel => ({
                userGroupId: group.userGroupId,
                label: (
                    <div className={classes.headerLabel}>
                        <EmailIcon /> {translate("process.versioning.selectPeopleToNotify")} :
                    </div>
                ),
            })
        );
    };

    /**
     * Gets the list of mandatory groups
     */
    const getMandatoryGroups = (): number[] =>
        userGroups.reduce((acc: number[], userGroup) => {
            if (userGroup.approvalMandatory && userGroup.userGroupId) {
                acc.push(userGroup.userGroupId);
            }
            return acc;
        }, []);

    /**
     * Get refused by user
     */
    const GetRefusalUserAndComment = (): JSX.Element => {
        const refusedByTask = taskProcessApproval.find(
            (approveInfo) => approveInfo.info.approverInfo.findIndex((approver) => approver.status === ApprovalStatusEnum.REJECTED) > -1
        );

        if (refusedByTask) {
            const refusedBy = refusedByTask.info.approverInfo.find((approver) => approver.status === ApprovalStatusEnum.REJECTED);
            if (refusedBy) {
                return (
                    <>
                        <div>
                            <KortexLabel label={translate("process.versioning.refusedBy")} variant="compact" />
                            {getUserFullName(refusedBy.userId)} - {getFormattedDate(refusedBy.completedDate, true)}
                        </div>
                        <br />
                        <div>
                            <KortexLabel label={translate("process.versioning.rejectVersionDialogReason")} variant="compact" />
                            {refusedBy.comment !== "" && <div className={classes.changeLogText}>{refusedBy.comment}</div>}
                        </div>
                    </>
                );
            }
        }
        return <></>;
    };

    /**
     * User can approve a version if all the following requirements are met:
     * 1. User must be in an approver group specified by the task
     * 2. User must not be the one who submitted the version
     */
    const userCanApprove = (): boolean => {
        if (userInfo && taskProcessApproval && taskProcessApproval.length) {
            return (
                taskProcessApproval.findIndex((task) => userInfo.userGroups.includes(task.groupId)) > -1 && // User is in approver group
                ((versionInfo.type === ProcessType.PROCESS && organisationSettings[0].allowSelfProcessApproval) || // User can self-approve their own process version
                    (versionInfo.type === ProcessType.ROUTING && organisationSettings[0].allowSelfRoutingApproval) || // User can self-approve their own routing version
                    userInfo.userId !== versionInfo.versionCreatorId) // User cannot self-approve and did not submit the version
            );
        }

        return false;
    };

    /**
     * User can reject a version if all the following requirements are met:
     * 1. User is in an approver group specified by the task
     */
    const userCanReject = (): boolean => {
        if (userInfo && taskProcessApproval && taskProcessApproval.length) {
            return (
                taskProcessApproval.findIndex((task) => userInfo.userGroups.includes(task.groupId)) > -1 // User is in approver group
            );
        }

        return false;
    };

    /**
     * Update Comments
     */
    const handleCommentsChanged = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setComments(event.target.value);
    };

    /**
     * Opens or closes the dialog for process version recovering
     */
    const handleRecoverVersionOpen =
        (opened: boolean): (() => void) =>
        (): void => {
            setRecoverVersionDialogOpened(opened);
        };

    /**
     * Opens or closes the dialog for process version reject
     */
    const handleRejectVersionOpen =
        (opened: boolean): (() => void) =>
        (): void => {
            setRejectVersionDialogOpened(opened);
        };

    /**
     * Recover a process version
     */
    const handleRecoverVersion = (): void => {
        dispatch(processVersionRecover({ processId: versionInfo.processId }));
        setRecoverVersionDialogOpened(false);
    };

    const releaseDisabled = !releasedDateMs || getInvalidReleaseDateErrorMessage() !== "" || getInvalidRetireDateErrorMessage() !== "";
    const updateDateDisabled =
        !releasedDateMs ||
        getInvalidReleaseDateErrorMessage() !== "" ||
        getInvalidRetireDateErrorMessage() !== "" ||
        (versionInfo.retiredOn === retiredDateMs && versionInfo.releasedOn === releasedDateMs);

    /**
     * Opens process validation drawer
     */
    const handleOpenProcessValidationDrawer =
        (open: boolean): (() => void) =>
        (): void => {
            setProcessValidationDrawerOpened(open);
        };

    /**
     * Set selected process to copy training from
     */
    const handleTraningsFromProcessChange = (_: TreeNodeId, processId?: ProcessId): void => {
        if (!trainingsCopyEnabled || processId === versionInfo.trainingsCopy?.processId) return void 0;

        setTrainingsCopiedFromProcessId(processId);

        if (versionInfo.isDraft) return void 0;

        dispatch(
            processUpdateVersionTraining({
                processId: versionInfo.processId,
                trainingRequirement: versionInfo.trainingRequirement,
                trainingsCopiedFromProcessId: processId,
            })
        );
    };

    /**
     * Set selected process version to copy training from
     */
    const handleTraningsFromProcessVersionChange = (processId: ProcessId, _ = false, changedOnLoad = false): void => {
        if (changedOnLoad || !trainingsCopyEnabled || processId === versionInfo.trainingsCopy?.processId) return void 0;

        setTrainingsCopiedFromProcessId(processId);

        if (versionInfo.isDraft) return void 0;

        dispatch(
            processUpdateVersionTraining({
                processId: versionInfo.processId,
                trainingRequirement: versionInfo.trainingRequirement,
                trainingsCopiedFromProcessId: processId,
            })
        );
    };

    // Rights
    const approvalEnabled = userCanApprove();

    // Training
    const isTrainingDisabled =
        props.reviewOnly ||
        props.anotherVersionPendingApproval ||
        isSubmitInProgress ||
        !(
            (processStatus === ProcessStatus.DRAFT && hasAccessLevelToInsertInProcessEditor && !isDraftLocked) ||
            (processStatus === ProcessStatus.WAITING_APPROVAL && approvalEnabled)
        );

    const handleToggleEnableTrainingsCopy = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        if (isTrainingDisabled) return void 0;

        setTrainingsCopyEnabled(checked);

        if (checked) return void 0;

        setTrainingsCopiedFromProcessId(undefined);

        if (versionInfo.isDraft) return void 0;

        dispatch(
            processUpdateVersionTraining({
                processId: versionInfo.processId,
                trainingRequirement: versionInfo.trainingRequirement,
                trainingsCopiedFromProcessId: undefined,
            })
        );
    };

    return (
        <Paper className={classes.root}>
            <div className={classes.rootGrid}>
                <div style={{ backgroundColor: statusColor(versionInfo) }} />
                <div className={classes.editorContainer}>
                    {versionInfo.isDraft && props.anotherVersionPendingApproval && (
                        <Alert className={classes.warningAnotherProcessPendingApproval} severity="warning">
                            <AlertTitle>{translate("general.warning")}</AlertTitle>
                            {translate("process.versioning.submitDisabledAnotherPendingVersion")}
                        </Alert>
                    )}
                    <div>
                        {/* DRAFT */}
                        {!isDraftLocked && !props.reviewOnly && versionInfo.isDraft && (
                            <div className={classes.contentBlock}>
                                <Typography variant="h2">{translate("process.versioning.submitNewVersion")}</Typography>
                                <div className={classes.contentGrid}>
                                    <KortexTextField
                                        className={classes.versionSelect}
                                        label={translate("process.versioning.version")}
                                        onChange={handleVersionChange}
                                        TextFieldProps={{
                                            disabled: props.anotherVersionPendingApproval || isSubmitInProgress,
                                            id: "versionId",
                                            required: true,
                                            select: true,
                                        }}
                                        value={selectedVersion}
                                    >
                                        {possibleVersions?.map((version, index) => (
                                            <MenuItem key={index} value={version.key}>
                                                {version.label}
                                            </MenuItem>
                                        ))}
                                    </KortexTextField>
                                    <KortexTextField
                                        className={classes.referenceIdTextField}
                                        InputProps={{
                                            id: "processVersioningReferenceIdId",
                                        }}
                                        TextFieldProps={{
                                            disabled: props.anotherVersionPendingApproval || isSubmitInProgress,
                                            required: true,
                                        }}
                                        label={translate("process.versioning.referenceId")}
                                        onChange={handleReferenceIdChange}
                                        value={referenceId}
                                    />
                                </div>
                                {needsApprovalGroups() && (
                                    <>
                                        <Typography variant="h4" className={classes.approvalsTitle}>
                                            {translate("process.versioning.approvals")}
                                        </Typography>
                                        <UserGroupSelector
                                            disabled={props.anotherVersionPendingApproval || isSubmitInProgress}
                                            variant="groupSelector"
                                            userFilter={userFilter}
                                            onUserSelect={handleUserSelect}
                                            onGroupSelect={handleGroupSelect}
                                            groupLabels={getGroupLabels()}
                                            groupHeaderLabels={getGroupHeaderLabels()}
                                            mandatoryGroups={getMandatoryGroups()}
                                        />
                                    </>
                                )}
                            </div>
                        )}

                        {/* VERSION INFORMATION */}
                        {(!versionInfo.isDraft || isDraftLocked) && (
                            <div className={classes.contentBlock} id={"versionInformationContentId"}>
                                <Typography variant="h2">{translate("process.versioning.versionInformation")}</Typography>
                                <div className={classes.contentGrid}>
                                    <KortexLabelText
                                        label={translate("process.versioning.version")}
                                        text={versionInfo.version || ""}
                                        id={"versionNumberId"}
                                    />
                                    <KortexLabelText
                                        label={translate("process.versioning.referenceId")}
                                        text={versionInfo.versionReferenceCode || ""}
                                    />
                                    <KortexLabelText
                                        label={translate("process.versioning.submittedBy")}
                                        text={getUserFullName(versionInfo.versionCreatorId)}
                                    />
                                    <KortexLabelText
                                        label={translate("process.versioning.submissionDate")}
                                        text={formatDate(submittedDate)}
                                    />
                                </div>
                            </div>
                        )}

                        {/* CHANGE LOG */}
                        <div className={classes.contentBlock}>
                            <Typography variant="h4">{translate("process.versioning.changelog")}</Typography>
                            <br />
                            {!isDraftLocked && versionInfo.isDraft ? (
                                <>
                                    <KortexTextField
                                        TextFieldProps={{
                                            id: "changeLogTextFieldId",
                                            multiline: true,
                                            rows: 6,
                                            required: true,
                                            disabled: props.reviewOnly || props.anotherVersionPendingApproval || isSubmitInProgress,
                                        }}
                                        className={classes.changeLog}
                                        label={translate("process.versioning.changelog")}
                                        value={versionInfo.versionChangeLog ?? ""}
                                        onChanged={handleChangeLogChanged}
                                    />

                                    {props.reviewOnly && (
                                        <div className={classes.buttonsBar}>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={handleReviewDone}
                                                className={classes.actionButton}
                                            >
                                                {translate("process.versioning.reviewDone")}
                                            </Button>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div className={classes.changeLogText}>{versionInfo.versionChangeLog}</div>
                            )}
                        </div>

                        {/* TRAINING */}
                        {isTrainingEnabled && (
                            <div className={classes.contentBlock} id="processApprovalCardEditorTrainingRequirementId">
                                <Typography variant="h2">{translate("process.versioning.training")}</Typography>
                                <div className={classes.trainingRequirementSection}>
                                    <KortexTextField
                                        className={classes.trainingRequirementSelector}
                                        label={translate("process.versioning.level")}
                                        onChange={handleTrainingRequirementChange}
                                        TextFieldProps={{
                                            disabled: isTrainingDisabled,
                                            id: "processApprovalCardEditorTrainingRequirementSelectorId",
                                            placeholder: translate("process.versioning.level.select"),
                                            required: true,
                                            select: true,
                                        }}
                                        value={trainingRequirement}
                                    >
                                        {trainingRequirement === TRAINING_REQUIREMENT_NOT_SELECTED ? (
                                            <MenuItem
                                                id="processApprovalCardEditorTrainingRequirementSelectorItemDefaultId"
                                                value={TRAINING_REQUIREMENT_NOT_SELECTED}
                                            >
                                                {translate("process.versioning.level.select")}
                                            </MenuItem>
                                        ) : (
                                            (null as unknown as React.ReactElement)
                                        )}
                                        <MenuItem
                                            id="processApprovalCardEditorTrainingRequirementSelectorItemNoneId"
                                            value={ProcessTrainingRequirementEnum.NONE}
                                        >
                                            {translate("process.versioning.level.none")}
                                        </MenuItem>
                                        <MenuItem
                                            id="processApprovalCardEditorTrainingRequirementSelectorItemReadingId"
                                            value={ProcessTrainingRequirementEnum.READING}
                                        >
                                            {translate("process.versioning.level.reading")}
                                        </MenuItem>
                                        <MenuItem
                                            id="processApprovalCardEditorTrainingRequirementSelectorItemTrainingId"
                                            value={ProcessTrainingRequirementEnum.TRAINING}
                                        >
                                            {translate("process.versioning.level.training")}
                                        </MenuItem>
                                        <MenuItem
                                            id="processApprovalCardEditorTrainingRequirementSelectorItemCertificationId"
                                            value={ProcessTrainingRequirementEnum.CERTIFICATION}
                                        >
                                            {translate("process.versioning.level.certification")}
                                        </MenuItem>
                                    </KortexTextField>
                                    {trainingsCopyEnabled &&
                                        trainingRequirement === ProcessTrainingRequirementEnum.NONE &&
                                        (versionInfo.isDraft || versionInfo.versionStatus === ApprovalStatusEnum.AWAITING) &&
                                        trainingsCopiedFromProcessId && (
                                            <Tooltip
                                                classes={{ tooltip: classes.tooltip }}
                                                placement="top"
                                                title={translate("process.versioning.trainingRequirementCopyWarning")}
                                            >
                                                <WarningIcon className={classes.iconWarning} id={"iconWarningId"} />
                                            </Tooltip>
                                        )}
                                    {!versionInfo.isDraft &&
                                        versionInfo.versionStatus !== ApprovalStatusEnum.AWAITING &&
                                        versionInfo.trainingsCopy?.copyTrainingRequirement && (
                                            <Tooltip
                                                classes={{ tooltip: classes.tooltip }}
                                                placement="top"
                                                title={translate("process.versioning.trainingRequirementCopyIcon")}
                                            >
                                                <InfoIcon className={classes.iconInfo} id={"iconInfoId"} />
                                            </Tooltip>
                                        )}
                                </div>
                                <Divider className={classes.processTrainingsCopyDivider} />
                                <div className={classes.processTrainingsCopyEnabledContainer}>
                                    <Checkbox
                                        checked={trainingsCopyEnabled}
                                        disabled={isTrainingDisabled}
                                        id="processApprovalCardEditorTrainingsCopyEnabledCheckboxId"
                                        onChange={handleToggleEnableTrainingsCopy}
                                    />
                                    <Typography className={classes.processTrainingsCopyTypography} variant="body1">
                                        {translate("process.versioning.processTrainingCopy")}
                                    </Typography>
                                </div>
                                {trainingsCopyEnabled ? (
                                    <ProcessTrainingCopyPicker
                                        disabled={isTrainingDisabled}
                                        onSelectProcess={handleTraningsFromProcessChange}
                                        onVersionChange={handleTraningsFromProcessVersionChange}
                                        processId={trainingsCopiedFromProcessId}
                                    />
                                ) : null}
                            </div>
                        )}

                        {/* SUBMIT BUTTON */}
                        {!isDraftLocked && versionInfo.isDraft && !props.reviewOnly && (
                            <div className={classes.buttonsBar}>
                                <Button
                                    id="submitVersionButtonId"
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleSubmitVersion}
                                    disabled={isSubmitDisabled()}
                                    className={classes.actionButton}
                                >
                                    {translate("process.versioning.submit")}
                                    {isSubmitInProgress && <CircularProgress className={classes.circularProgress} />}
                                </Button>
                            </div>
                        )}
                        {/* APPROVAL INFORMATION */}
                        {!props.reviewOnly && !versionInfo.isDraft && (
                            <>
                                <div className={classes.contentBlock} id={"approvalInformationId"}>
                                    <Typography variant="h2">{translate("process.versioning.approvalInformation")}</Typography>
                                    <div className={classes.contentGrid}>
                                        {taskProcessApproval.length === 0 && versionInfo.versionStatus === ApprovalStatusEnum.APPROVED ? (
                                            <KortexLabelText
                                                label={translate("process.versioning.approvalStatus")}
                                                text={translate("process.versioning.approvedAutomatically")}
                                                id={"approvalStatusId"}
                                            />
                                        ) : (
                                            <>
                                                <KortexLabelText
                                                    label={translate("process.versioning.approvalStatus")}
                                                    text={translate(getProcessVersionStatusLabelKey(versionInfo.versionStatus))}
                                                    id={"approvalStatusId"}
                                                />
                                                {taskProcessApproval.length > 0 && (
                                                    <>
                                                        <KortexLabelText
                                                            label={translate("process.versioning.referenceId")}
                                                            text={taskProcessApproval[0].referenceId}
                                                        />
                                                        <KortexLabelText
                                                            label={translate("process.versioning.requestedBy")}
                                                            text={getUserFullName(taskProcessApproval[0].userId)}
                                                        />
                                                        <KortexLabelText
                                                            label={translate("process.versioning.requestedDate")}
                                                            text={getFormattedDate(taskProcessApproval[0].createdOn || 0, true)}
                                                        />
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <br />
                                    {versionInfo.versionStatus !== ApprovalStatusEnum.REJECTED ? (
                                        <>
                                            {taskProcessApproval.length > 0 && (
                                                <>
                                                    <KortexLabel label={translate("process.versioning.approvals")} variant="compact" />
                                                    <div className={classes.cardApprobation}>
                                                        {taskProcessApproval.map((task, index) => (
                                                            <div key={index}>
                                                                <>
                                                                    <div
                                                                        className={classes.groupNameContainer}
                                                                        id={`${getApproverGroupName(task.groupId)}Id`}
                                                                    >
                                                                        {getUserGroupName(task.groupId)}&nbsp;(
                                                                        {task.info.approverInfo.length}/{task.info.qtyRequired})
                                                                    </div>
                                                                    <ol>
                                                                        {task.info.approverInfo.map((approverInfo, approverIndex) => {
                                                                            const reviewDate = getFormattedDate(
                                                                                approverInfo.completedDate,
                                                                                true
                                                                            );
                                                                            const signatureUserFullName = getElectronicSignatureFullName(
                                                                                approverInfo.electronicSignatureId
                                                                            );
                                                                            return (
                                                                                <li key={approverIndex}>
                                                                                    {getUserFullName(approverInfo.userId)}{" "}
                                                                                    {reviewDate ? `- ${reviewDate}` : ""}{" "}
                                                                                    {signatureUserFullName
                                                                                        ? `- ${signatureUserFullName}`
                                                                                        : ""}
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ol>
                                                                </>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>{GetRefusalUserAndComment()}</>
                                    )}
                                    <UserValidationDialog
                                        open={userValidationApprovalOpen}
                                        validationGroupsId={taskProcessApproval.map((userTask) => userTask.groupId)}
                                        isElectronicSignature={isElectronicSignature(ElectronicSignatureEnum.APPROVAL)}
                                        electronicSignatureContext={organisationSettings[0].approvalContextText}
                                        onClose={handleUserValidationClose(ElectronicSignatureEnum.APPROVAL)}
                                        preSelectedUserId={userInfo ? userInfo.userId : 0}
                                        onValidate={handleUserValidation(ElectronicSignatureEnum.APPROVAL)}
                                    />
                                    {props.onApproveVersion && versionInfo.versionStatus === ApprovalStatusEnum.AWAITING && (
                                        <div className={classes.buttonsBar}>
                                            {userCanReject() && (
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={handleRejectVersionOpen(true)}
                                                    className={classes.actionButton}
                                                    id={"rejectButtonId"}
                                                >
                                                    {translate("process.versioning.reject")}
                                                </Button>
                                            )}
                                            {approvalEnabled && (
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={handleApproveVersion(true, "")}
                                                    className={classes.actionButton}
                                                    id={"approveButtonId"}
                                                    disabled={buttonApproveDisabled}
                                                >
                                                    {translate("process.versioning.approve")}
                                                </Button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>

                    {/* CONTENT */}

                    {/* RELEASE AND RETIRE DATES */}
                    {!props.approvedInfoOnly &&
                        (versionInfo.versionStatus === ApprovalStatusEnum.APPROVED ||
                            versionInfo.versionStatus === ApprovalStatusEnum.HOLD ||
                            versionInfo.versionStatus === ApprovalStatusEnum.CANCELLED) && (
                            <div className={classes.contentBlock}>
                                <Typography variant="h2">{translate("process.versioning.releaseInformation")}</Typography>
                                <div className={classes.contentGrid}>
                                    <KortexLabelText
                                        id="processVersioningReleaseStatusLabelId"
                                        label={translate("process.versioning.releaseStatus")}
                                        text={translate(getProcessReleaseStatusLabelKey(versionInfo))}
                                    />
                                    {versionInfo.releasedOn && (
                                        <KortexLabelText
                                            id="processVersioningReleaseReleasedByLabelId"
                                            label={translate("process.versioning.releasedBy")}
                                            text={
                                                versionInfo.versionReleasedByUserId
                                                    ? getUserFullName(versionInfo.versionReleasedByUserId)
                                                    : "..."
                                            }
                                        />
                                    )}
                                </div>

                                {versionInfo.versionStatus !== ApprovalStatusEnum.CANCELLED && (
                                    <>
                                        <div className={classes.contentGrid}>
                                            {/* RELEASE DATE */}
                                            <KortexDateTimePicker
                                                value={releasedDateMs}
                                                onChange={handleReleaseDateChange}
                                                label={translate("process.versioning.releasedDate")}
                                                DateTimePickerProps={{
                                                    error: Boolean(getInvalidReleaseDateErrorMessage()),
                                                    disabled: !hasReleaseRights,
                                                    disablePast: true,
                                                    helperText: getInvalidReleaseDateErrorMessage(),
                                                    InputProps: {
                                                        inputProps: {
                                                            id: "releasedDateInputId",
                                                            className: classes.datePickerInput,
                                                        },
                                                    },
                                                    clearLabel: translate("general.clear"),
                                                    cancelLabel: translate("general.cancel"),
                                                    okLabel: translate("general.select"),
                                                }}
                                            />
                                            {/* RETIRE DATE */}
                                            <KortexDateTimePicker
                                                value={retiredDateMs}
                                                onChange={handleRetireDateChange}
                                                label={translate("process.versioning.retiredDate")}
                                                DateTimePickerProps={{
                                                    error: Boolean(getInvalidRetireDateErrorMessage()),
                                                    disabled: !hasReleaseRights,
                                                    disablePast: true,
                                                    helperText: getInvalidRetireDateErrorMessage(),
                                                    InputProps: {
                                                        inputProps: {
                                                            id: "retiredDateInputId",
                                                            className: classes.datePickerInput,
                                                        },
                                                    },
                                                    clearLabel: translate("general.clear"),
                                                    cancelLabel: translate("general.cancel"),
                                                    okLabel: translate("general.select"),
                                                }}
                                            />
                                        </div>
                                        {/* COMMENTS */}
                                        <div className={classes.contentGrid}>
                                            <KortexTextField
                                                TextFieldProps={{ multiline: true, rows: 3, disabled: props.reviewOnly }}
                                                className={classes.changeLog}
                                                label={translate("process.versioning.comments")}
                                                value={comments}
                                                onChange={handleCommentsChanged}
                                            />
                                        </div>
                                    </>
                                )}
                                <UserValidationDialog
                                    open={userValidationReleaseOpen !== UserValidationReleaseEnum.NONE}
                                    validationGroupsId={undefined}
                                    isElectronicSignature={isElectronicSignature(ElectronicSignatureEnum.RELEASE)}
                                    electronicSignatureContext={organisationSettings[0].releaseContextText}
                                    onClose={handleUserValidationClose(ElectronicSignatureEnum.RELEASE)}
                                    preSelectedUserId={userInfo ? userInfo.userId : 0}
                                    onValidate={handleUserValidation(ElectronicSignatureEnum.RELEASE)}
                                />
                                <div className={classes.buttonsBar}>
                                    {/* UPDATE PROCESS */}
                                    {versionInfo.versionStatus === ApprovalStatusEnum.APPROVED &&
                                        versionInfo.releasedOn &&
                                        hasReleaseRights && (
                                            <Button
                                                id="updateButtonId"
                                                variant="contained"
                                                color="secondary"
                                                onClick={handleUpdateReleaseDates}
                                                disabled={updateDateDisabled}
                                                className={classes.actionButton}
                                            >
                                                {translate("process.versioning.update")}
                                            </Button>
                                        )}

                                    {/* CANCEL BUTTON */}
                                    {canBeCancelled && (
                                        <Button
                                            id="cancelVersionButtonId"
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleCancelVersion}
                                            disabled={!hasReleaseRights}
                                            className={classes.actionButton}
                                        >
                                            {translate("process.versioning.cancel")}
                                        </Button>
                                    )}
                                    {/* HOLD BUTTON */}
                                    {canBeOnHold && (
                                        <Button
                                            id="holdButtonId"
                                            variant="contained"
                                            color="secondary"
                                            onClick={handlePutVersionOnHold}
                                            disabled={!hasReleaseRights}
                                            className={classes.actionButton}
                                        >
                                            {translate("process.versioning.hold")}
                                        </Button>
                                    )}
                                    {/* RELEASE BUTTON */}
                                    {canBeReleased && (
                                        <Button
                                            id="releaseButtonId"
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleUpdateReleaseDates}
                                            disabled={releaseDisabled}
                                            className={classes.actionButton}
                                        >
                                            {translate("process.versioning.release")}
                                        </Button>
                                    )}

                                    {/* UPDATE PROCESS VERSION */}
                                    {processStatus === ProcessStatus.RELEASED && versionInfo.type === ProcessType.PROCESS && (
                                        <>
                                            <Button
                                                variant="contained"
                                                onClick={handleUpdateProcessDialog(true)}
                                                className={classes.actionButton}
                                            >
                                                {translate("process.versioning.updateVersion")}
                                            </Button>
                                        </>
                                    )}
                                </div>

                                <Accordion expanded={releaseLogsExpanded} className={classes.accordion}>
                                    <AccordionSummary onClick={handleReleaseLogsExpand} expandIcon={<ExpandMore />} id={"releaseHistoryID"}>
                                        <Typography variant="h6">{translate("process.versioning.showHistory")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>{translate("process.versioning.action")}</TableCell>
                                                    <TableCell>{translate("process.versioning.releasedDate")}</TableCell>
                                                    <TableCell>{translate("process.versioning.retiredDate")}</TableCell>
                                                    <TableCell>{translate("process.versioning.updatedDate")}</TableCell>
                                                    <TableCell>{translate("process.versioning.updatedBy")}</TableCell>
                                                    <TableCell>{translate("process.versioning.comments")}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {versionInfo.releaseLogs
                                                    .sort((a, b) => (a.createdOn && b.createdOn ? a.createdOn - b.createdOn : 1))
                                                    .map((row: IProcessReleaseLogDbModel, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell id={`processReleaseLogActionLabel${index}Id`}>
                                                                {getprocessReleaseLogActionLabel(row.action, translate)}
                                                            </TableCell>
                                                            <TableCell id={`releaseDateLabel${index}Id`} scope="row">
                                                                {getFormattedDate(row.releasedDate, true)}
                                                            </TableCell>
                                                            <TableCell id={`retiredDateLabel${index}Id`}>
                                                                {getFormattedDate(row.retiredDate, true)}
                                                            </TableCell>
                                                            <TableCell id={`updateDateLabel${index}Id`}>
                                                                {getFormattedDate(row.updatedOn ? row.updatedOn : 0, true)}
                                                            </TableCell>
                                                            <TableCell id={`releasedByLabel${index}Id`}>
                                                                {getProcessReleaseLogReleasedByInfo(row.releasedBy)}
                                                            </TableCell>
                                                            <TableCell id={`commentsLabel${index}Id`} className={classes.changeLogText}>
                                                                {row.comments}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        )}
                    <div />
                    {/* BOTTOM MENU */}
                    {!props.approvedInfoOnly && (
                        <List className={classes.bottomMenu}>
                            {props.onPrintVersion && (
                                <ListItem
                                    className={classes.menuItem}
                                    button={true}
                                    id="processVersioningPDFId"
                                    onClick={props.onPrintVersion}
                                >
                                    <KortexLabelIcon label={translate("process.versioning.pdf")}>
                                        <PictureAsPdfIcon />
                                    </KortexLabelIcon>
                                </ListItem>
                            )}

                            {!versionInfo.archived && versionInfo.isDraft && (
                                <ListItem
                                    className={classes.menuItem}
                                    button={true}
                                    onClick={handleOpenReviewRequestDialog}
                                    id="processVersioningRequestReviewId"
                                >
                                    <KortexLabelIcon label={translate("process.versioning.reviewRequest")}>
                                        <UnarchiveIcon />
                                    </KortexLabelIcon>
                                </ListItem>
                            )}
                            {!versionInfo.archived && (
                                <ListItem
                                    className={classes.menuItem}
                                    button={true}
                                    id="processVersioningWhereUsedId"
                                    onClick={handleOpenWhereUsedDialog(true)}
                                >
                                    <KortexLabelIcon label={translate("process.versioning.whereUsed")}>
                                        <FindIcon />
                                    </KortexLabelIcon>
                                </ListItem>
                            )}
                            <div className={classes.spacer} />
                            {!versionInfo.isDraft && !isDraftLocked && hasAccessLevelToInsertInProcessEditor && (
                                <ListItem
                                    className={classes.menuItem}
                                    button={true}
                                    id="processVersioningRecoverId"
                                    onClick={handleRecoverVersionOpen(true)}
                                >
                                    <KortexLabelIcon label={translate("process.versioning.recover")}>
                                        <RecoverIcon />
                                    </KortexLabelIcon>
                                </ListItem>
                            )}
                        </List>
                    )}
                </div>
            </div>
            <ProcessApprovalReviewRequestDialog
                referenceId={referenceId}
                onCancel={handleReviewRequestClose}
                onConfirm={handleReviewRequestClose}
                opened={reviewRequestDialogOpened}
                processId={versionInfo.processId}
            />
            <ProcessApprovalRecoverVersionDialog
                onCancel={handleRecoverVersionOpen(false)}
                onConfirm={handleRecoverVersion}
                opened={recoverVersionDialogOpened}
            />
            <ProcessApprovalWhereUsedDialog
                onClose={handleOpenWhereUsedDialog(false)}
                opened={processWhereUsedDialogOpened}
                process={versionInfo}
            />
            <ProcessUpdateVersionDialog
                opened={updateProcessDialogOpened}
                updateToProcess={versionInfo}
                onClose={handleUpdateProcessDialog(false)}
                onConfirm={handleUpdateProcess}
            />
            <ProcessApprovalGroupAreYouSureDialog
                missingTrainingCommunique={missingTrainingCommunique}
                opened={processApprovalGroupAreYouSureDialogOpen}
                onCancel={handleProcessApprovalGroupAreYouSureDialogCancel}
                onConfirm={handleProcessApprovalGroupAreYouSureDialogConfirm}
                selectedApproverGroups={approvalGroups}
            />

            <ProcessValidationDrawer
                labels={{
                    fail: translate("processEditor.validation.errorFoundSubmitVersion"),
                    success: translate("processEditor.validation.successSubmitVersion"),
                }}
                open={processValidationDrawerOpened}
                onClose={handleOpenProcessValidationDrawer(false)}
                process={versionInfo}
                snackbarOnly={true}
                processValidation={processValidation}
            />

            <ProcessRejectVersionDialog
                onCancel={handleRejectVersionOpen(false)}
                onConfirm={handleRejection}
                opened={rejectVersionDialogOpened}
            />
        </Paper>
    );
}
