import { KortexTextField } from "@aos/react-components";
import { BomFollowUpId, BomFollowUpSerializedItemId, IBomFollowUp, ISerializedItem, IWoBomItem, OrUndefined } from "@kortex/aos-common";
import { isBomItemTraceable } from "@kortex/aos-ui/components/pages/bom/woBomTable/utils";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import {
    processPlayerBomFollowUpInsertFollowUp,
    processPlayerBomFollowUpInsertFollowUpSerializedItem,
} from "@kortex/aos-ui/redux/player-manager/player-thunk-bom-followUp";
import { IconButton, InputAdornment, PopoverPosition, TableCell, TableRow, makeStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import React, { FC, useState } from "react";

import { MenuType, NonTraceableItemMenu, TraceabilityMenu } from "../../../bom";
import { usePlayerContext } from "../../context";
import { PlayerControlsBomItemInfoTooltip } from "../../controls/content/bom/utilities";

const useStyles = makeStyles({
    cellSerialNumber: {
        borderBottom: "none",
    },
    container: {
        width: "100%",
    },
    iconButton: {
        padding: 0,
        "&:disabled": {
            padding: 0,
        },
    },
    root: {}, // To overwrite with props
    rowSerialNumber: {
        alignItems: "center",
        display: "grid",
        justifyContent: "center",
    },
    traceability: {
        margin: 0,
    },
});

interface IOwnProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    item: IWoBomItem;
    itemIndex: number;
    processActionLabel: string;
    processActionStepId: number;
    processActionStepLabel: string;
    validateBom: boolean;
}

const ProcessBomDialogRow: FC<IOwnProps> = (props) => {
    const { item, itemIndex, processActionLabel, processActionStepId, processActionStepLabel, validateBom } = props;

    const classes = useStyles(props);
    const dispatch = useThunkDispatch();
    const translate = useTranslate();
    const { jobProcessInfo, playerState, woBom, process } = usePlayerContext();

    const [menuPosition, setMenuPosition] = useState<PopoverPosition | undefined>(undefined);
    const [menuType, setMenuType] = useState<MenuType>("traceability");
    const [followUpLineSelected, setFollowUpLineSelected] = useState<OrUndefined<IBomFollowUp>>(undefined);
    const [serializedItemSelected, setSerializedItemSelected] = useState<ISerializedItem | undefined>(undefined);

    const isBomDisabled = !(playerState.processState.bom.enabled && Boolean(jobProcessInfo));
    const isItemTraceable = isBomItemTraceable(item);
    const optionsButtonDisabled = isBomDisabled || !item.followUp[processActionStepId];
    const serialized = item.serialized;

    const handleMenuClose = (): void => {
        setMenuPosition(undefined);
    };

    const handleDialogClose = (): void => {
        setFollowUpLineSelected(undefined);
        setSerializedItemSelected(undefined);
    };

    /**
     * Opens the options menu
     */
    const handleMoreClick =
        (
            type: MenuType,
            followUp: OrUndefined<IBomFollowUp>,
            serializedItem?: ISerializedItem
        ): ((event: React.MouseEvent<HTMLElement>) => void) =>
        (event: React.MouseEvent<HTMLElement>): void => {
            if (type === "serial") setSerializedItemSelected(serializedItem);

            setMenuType(type);
            setFollowUpLineSelected(followUp);
            setMenuPosition({
                left: event.clientX,
                top: event.clientY,
            });
        };

    /**
     * Changes the traceability
     */
    const handleTraceabilityChange =
        (bomFollowUpId?: BomFollowUpId, quantity?: number): ((event: React.FocusEvent<HTMLInputElement>) => void) =>
        (event: React.FocusEvent<HTMLInputElement>): void => {
            const newValue = event.target.value;

            if (!newValue.trim().length) return void 0;

            dispatch(
                processPlayerBomFollowUpInsertFollowUp({
                    jobRefId: jobProcessInfo!.job.jobRefId,
                    partNumber: item.partNumber,
                    processActionStepId,
                    quantity: quantity ?? item.quantity,
                    traceability: newValue,
                    trackingId: playerState.processState.serialNumber,
                    trackingInstances: playerState.processState.serialNumberInstances.toString(),
                    bomFollowUpId,
                })
            );
        };

    /**
     * Handles serial number change
     **/
    const handleSerialNumberChange =
        (
            followUpId: OrUndefined<IBomFollowUp["bomFollowUpId"]>,
            bomFollowUpSerializedItemId?: BomFollowUpSerializedItemId
        ): ((event: React.FocusEvent<HTMLInputElement>) => void) =>
        (event: React.FocusEvent<HTMLInputElement>): void => {
            const serialNumber = event.target.value;

            if (!followUpId || !serialNumber) return void 0;

            dispatch(
                processPlayerBomFollowUpInsertFollowUpSerializedItem({
                    bomFollowUpId: followUpId,
                    serialNumber,
                    type: bomFollowUpSerializedItemId ? "reassignment" : "standard",
                    bomFollowUpSerializedItemId,
                })
            );
        };

    const renderRowsSerialNumber = (qty: number, bomFollowUpLine?: IBomFollowUp): JSX.Element[] => {
        const elements: JSX.Element[] = [];

        for (let i = 0; i < (serialized ? qty : 1); i++) {
            const emptyTraceability = !Boolean(bomFollowUpLine?.traceability);
            const emptySerialNumber = !Boolean(bomFollowUpLine?.serializedItems[i]?.serialNumber);
            const textfiledDisabled =
                isBomDisabled ||
                emptyTraceability ||
                !emptySerialNumber ||
                (i !== 0 && !bomFollowUpLine?.serializedItems[i - 1]?.serialNumber); // Previous index does not have a serial number;
            const editIconDisabled = isBomDisabled || emptyTraceability || emptySerialNumber;

            elements.push(
                <div className={classes.cellSerialNumber} key={i}>
                    <KortexTextField
                        error={validateBom && emptySerialNumber ? translate("general.required") : undefined}
                        TextFieldProps={{
                            autoComplete: "off",
                            id: `processSerialNumberInput${item.partNumber}-${i}Id`,
                            disabled: textfiledDisabled,
                            placeholder: serialized ? translate("player.bom.serialNumber") : translate("general.na"),
                        }}
                        onBlur={handleSerialNumberChange(
                            bomFollowUpLine?.bomFollowUpId,
                            bomFollowUpLine?.serializedItems[i]?.bomFollowUpSerializedItemId
                        )}
                        value={bomFollowUpLine?.serializedItems[i]?.serialNumber ?? ""}
                        variant="standard"
                        InputProps={{
                            endAdornment: serialized ? (
                                <InputAdornment position="end">
                                    <IconButton
                                        disabled={editIconDisabled}
                                        id={`processBomItemSerialMore${item.partNumber}-${i}Id`}
                                        onClick={handleMoreClick("serial", bomFollowUpLine, bomFollowUpLine?.serializedItems[i])}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </InputAdornment>
                            ) : (
                                <></>
                            ),
                        }}
                    />
                </div>
            );
        }

        return elements;
    };

    /**
     * Renders a table row for each traceability
     */
    const renderRows = (): JSX.Element[] => {
        const elements: JSX.Element[] = [];
        let remainingQuantity = item.quantity;
        // Add initial row if item is non-traceable
        if (!isItemTraceable) {
            elements.push(
                <TableRow key={item.partNumber} className={classes.root}>
                    {/* ACTION LABEL */}
                    <TableCell width="10%">{processActionLabel}</TableCell>
                    {/* STEP LABEL */}
                    <TableCell width="10%">{processActionStepLabel}</TableCell>
                    {/* PART NUMBER */}
                    <PlayerControlsBomItemInfoTooltip item={item} placement="right">
                        <TableCell width="10%" id={`${item.partNumber}Id`}>
                            {item.partNumber}
                        </TableCell>
                    </PlayerControlsBomItemInfoTooltip>
                    {/* DESCRIPTION */}
                    <TableCell width="35%" id={`description${item.partNumber}Id`}>
                        {item.description}
                    </TableCell>
                    {/* QUANTITY */}
                    <TableCell width="5%" id={`quantity${item.partNumber}Id`}>
                        {item.quantity}
                    </TableCell>
                    {/* OPTIONS BUTTON */}
                    <TableCell width="30%" colSpan={2}>
                        <IconButton
                            disabled={isBomDisabled}
                            id={`processBomItemMore${item.partNumber}Id`}
                            onClick={handleMoreClick("non-traceable", followUpLineSelected)}
                        >
                            <EditIcon />
                        </IconButton>
                    </TableCell>
                </TableRow>
            );

            remainingQuantity = 0;
        }

        // Add traceability rows
        for (const [index, line] of (item.followUp[processActionStepId] ?? []).entries()) {
            elements.push(
                <TableRow key={`processBomDialogRow${itemIndex}-${index}`} className={classes.root}>
                    {elements.length === 0 ? (
                        <>
                            {/* ACTION LABEL */}
                            <TableCell width="10%">{processActionLabel}</TableCell>
                            {/* STEP LABEL */}
                            <TableCell width="10%">{processActionStepLabel}</TableCell>
                            {/* PART NUMBER */}
                            <PlayerControlsBomItemInfoTooltip item={item} placement="right">
                                <TableCell width="10%" id={`${item.partNumber}Id`}>
                                    {item.partNumber}
                                </TableCell>
                            </PlayerControlsBomItemInfoTooltip>
                            {/* DESCRIPTION */}
                            <TableCell width="35%" id={`description${line.partNumber}${line.processActionStepId}Id`}>
                                {item.description}
                            </TableCell>
                        </>
                    ) : (
                        <TableCell colSpan={4} width="75%" />
                    )}
                    {/* QUANTITY */}
                    <TableCell width="5%" id={`quantity${line.partNumber}${line.processActionStepId}Id`}>
                        {`${line.isOverconsumption ? "+" : ""}${line.quantity}`}
                    </TableCell>
                    {/* TRACEABILITY */}
                    <TableCell width="10%" id={`traceability${item.partNumber}Id`}>
                        {isItemTraceable ? (
                            <KortexTextField
                                error={
                                    validateBom && isItemTraceable && line.traceability === "" ? translate("general.required") : undefined
                                }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {/* OPTIONS BUTTON */}
                                            <IconButton
                                                disabled={optionsButtonDisabled || !Boolean(line.traceability)}
                                                id={`processBomItemMore${line.partNumber}${index}Id`}
                                                onClick={handleMoreClick("traceability", line)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                TextFieldProps={{
                                    autoComplete: "off",
                                    id: `processTraceabilityInput${line.partNumber}-${index}Id`,
                                    disabled: Boolean(line.traceability),
                                    placeholder: item.lotSerialType,
                                    className: classes.traceability,
                                    required: validateBom && isItemTraceable,
                                }}
                                onBlur={handleTraceabilityChange(line.bomFollowUpId, line.quantity)}
                                value={line.traceability}
                                variant="standard"
                            />
                        ) : null}
                    </TableCell>
                    {/* SERIAL NUMBER */}
                    <TableCell width="10%" id={`processSerialNumber${item.partNumber} Id`}>
                        {item.serialized ? renderRowsSerialNumber(line.quantity, line) : null}
                    </TableCell>
                </TableRow>
            );

            if (!line.isOverconsumption) remainingQuantity -= line.quantity;
        }

        // Add empty row (no traceability)
        if (remainingQuantity > 0 || (isItemTraceable && item.quantity === 0 && !item.followUp[processActionStepId]?.length)) {
            elements.push(
                <TableRow key={item.partNumber} className={classes.root}>
                    {/* ACTION LABEL */}
                    <TableCell width="10%">{processActionLabel}</TableCell>
                    {/* STEP LABEL */}
                    <TableCell width="10%">{processActionStepLabel}</TableCell>
                    {/* PART NUMBER */}
                    <PlayerControlsBomItemInfoTooltip item={item} placement="right">
                        <TableCell width="10%" id={`${item.partNumber}Id`}>
                            {item.partNumber}
                        </TableCell>
                    </PlayerControlsBomItemInfoTooltip>
                    {/* DESCRIPTION */}
                    <TableCell width="35%" id={`description${item.partNumber}Id`}>
                        {item.description}
                    </TableCell>
                    {/* QUANTITY */}
                    <TableCell width="5%" id={`quantity${item.partNumber}Id`}>
                        {remainingQuantity}
                    </TableCell>
                    {/* TRACEABILITY */}
                    <TableCell width="10%" id={`traceability${item.partNumber}Id`}>
                        {isItemTraceable ? (
                            <KortexTextField
                                error={validateBom && isItemTraceable ? translate("general.required") : undefined} // TODO: AOS-2220
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {/* OPTIONS BUTTON */}
                                            <IconButton
                                                disabled={optionsButtonDisabled}
                                                id={`processBomItemMore${item.partNumber}Id`}
                                                onClick={handleMoreClick("traceability", followUpLineSelected)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onBlur={handleTraceabilityChange()}
                                TextFieldProps={{
                                    autoComplete: "off",
                                    id: `processTraceabilityInput${item.partNumber}Id`,
                                    placeholder: isItemTraceable ? item.lotSerialType : translate("general.none").toUpperCase(),
                                    disabled: isBomDisabled || !isItemTraceable,
                                    className: classes.traceability,
                                    required: validateBom && isItemTraceable,
                                }}
                                value=""
                                variant="standard"
                            />
                        ) : null}
                    </TableCell>
                    {/* SERIAL NUMBER */}
                    <TableCell width="10%" id={`processSerialNumber${item.partNumber}Id`}>
                        {item.serialized ? renderRowsSerialNumber(item.quantity) : null}
                    </TableCell>
                </TableRow>
            );
        }

        return elements;
    };

    return (
        <>
            {/* ROWS */}
            {renderRows()}
            {/* OPTIONS MENU */}
            {!optionsButtonDisabled && followUpLineSelected ? (
                <TraceabilityMenu
                    followUp={followUpLineSelected}
                    menuPosition={menuPosition}
                    onDialogClose={handleDialogClose}
                    onMenuClose={handleMenuClose}
                    serializedItem={serializedItemSelected}
                    type={menuType}
                    variant="player"
                    treeNodeId={process?.treeNodeId}
                />
            ) : null}
            {!isItemTraceable && woBom ? (
                <NonTraceableItemMenu
                    item={item}
                    menuPosition={menuPosition}
                    onDialogClose={handleDialogClose}
                    onMenuClose={handleMenuClose}
                    processActionStepId={processActionStepId}
                    woBom={woBom}
                    variant="player"
                />
            ) : null}
        </>
    );
};

export default ProcessBomDialogRow;
