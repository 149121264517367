import { theme } from "@aos/react-components";
import { IBomItem, ProcessActionStepWorkInstructions } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { deepClone } from "@kortex/utilities";
import { IconButton, makeStyles, TableCell, TableRow, Tooltip, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ErrorIcon from "@material-ui/icons/Error";
import React, { useContext } from "react";

import { ActionEditorContext } from "../../../../../../context";

const useStyles = makeStyles({
    errorIconContainer: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        width: "100%",
    },
    iconButton: {
        padding: 0,
    },
    partNumberContainer: {
        alignItems: "center",
        display: "flex",
    },
    tableRow: {
        height: "40px",
    },
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
        textAlign: "center",
    },
});

interface IOwnProps {
    item: Pick<IBomItem, "partNumber">;
}

function WorkInstructionsBomStepInvalidItemsRow(props: IOwnProps): JSX.Element {
    const { item } = props;

    const classes = useStyles();
    const actionEditorContext = useContext(ActionEditorContext);
    const translate = useTranslate();

    const editedActionStep = actionEditorContext.editedActionStep as ProcessActionStepWorkInstructions;

    const handleDeleteItem = (): void => {
        const step = deepClone(editedActionStep);
        delete step.config.bomItems[item.partNumber];

        actionEditorContext.onChangedStep(step);
    };

    return (
        <TableRow className={classes.tableRow}>
            <TableCell>
                <div className={classes.partNumberContainer}>
                    <Typography id={`${item.partNumber}StepItemPartNumberId`} variant="body2" color="error">
                        {item.partNumber}
                    </Typography>
                </div>
            </TableCell>
            <TableCell>
                <div className={classes.errorIconContainer}>
                    <Tooltip
                        classes={{ tooltip: classes.tooltip }}
                        title={translate("processEditor.validation.errorCode.processAction.bomItemMessage")}
                    >
                        <ErrorIcon color="error" id={`${item.partNumber}MessageErrorId`} />
                    </Tooltip>
                </div>
            </TableCell>
            <TableCell align="right">
                <IconButton className={classes.iconButton} id={`${item.partNumber}DeleteButtonId`} onClick={handleDeleteItem}>
                    <DeleteIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    );
}

export default WorkInstructionsBomStepInvalidItemsRow;
