import { makeStyles } from "@material-ui/core";
import React, { FC, PropsWithChildren } from "react";
import { Link, LinkProps } from "react-router-dom";

const useStyles = makeStyles({
    link: {
        color: "inherit",
        textDecoration: "inherit",
    },
});

const MenuLink: FC<PropsWithChildren<LinkProps>> = (props) => {
    const { children, ...linkProps } = props;

    const classes = useStyles();

    return (
        <Link className={classes.link} {...linkProps}>
            {children}
        </Link>
    );
};

export default MenuLink;
