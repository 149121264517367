import {
    BomFollowUpEditItemReq,
    BomFollowUpEditItemRes,
    BomFollowUpEditSerializedItemReq,
    BomFollowUpEditSerializedItemRes,
    BomFollowUpInsertReq,
    BomFollowUpInsertRes,
    BomFollowUpInsertSerializedItemReq,
    BomFollowUpInsertSerializedItemRes,
    BomFollowUpMultipleTraceabilitiesItemReq,
    BomFollowUpMultipleTraceabilitiesItemRes,
    BomFollowUpOverconsumeItemReq,
    BomFollowUpOverconsumeItemRes,
    BomFollowUpOverconsumeNonTraceableItemReq,
    BomFollowUpOverconsumeNonTraceableItemRes,
    BomFollowUpRemoveItemReq,
    BomFollowUpRemoveItemRes,
    BomFollowUpRemoveSerializedItemReq,
    BomFollowUpRemoveSerializedItemRes,
    BomFollowUpReplaceItemReq,
    BomFollowUpReplaceItemRes,
    BomFollowUpReplaceSerializedItemReq,
    BomFollowUpReplaceSerializedItemRes,
    ErpGetBomByTrackingIdRes,
    ErpGetWoBomRes,
    IBomFollowUp,
    TrackingId,
    UnwrapAOSPayload,
} from "@kortex/aos-common";

import { handleAPIError } from "../handleAPIError";
import { AppState, StandardDispatch, StandardThunk } from "../store";

import {
    bomItemEditedAction,
    bomItemInsertedAction,
    bomItemMultipleTraceabilitiesAction,
    bomItemOverconsumedAction,
    bomItemRemovedAction,
    bomItemReplacedAction,
    bomSerializedItemInsertedAction,
    bomSetAction,
    bomsClearAction,
} from "./bom-actions";

/**
 * Clear BOMs from state
 *
 */
export function bomClearBoms(): StandardThunk<void> {
    return async (dispatch: StandardDispatch): Promise<void> => {
        dispatch(bomsClearAction());
    };
}

/**
 * Get WO BOM
 */
export function bomGetWoBom(
    jobRefId: IBomFollowUp["jobRefId"],
    trackingId: IBomFollowUp["trackingId"]
): StandardThunk<UnwrapAOSPayload<ErpGetWoBomRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<ErpGetWoBomRes> | undefined> {
        return api.services.erp
            .getWoBom({ jobRefId, trackingId })({ timeout: 30_000 /* 30 seconds */ })
            .then((res) => {
                // Set bom list
                dispatch(bomSetAction([{ woBom: res }]));

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}

/**
 * Get boms by tracking ID
 */
export function bomGetByTrackingId(trackingId: TrackingId): StandardThunk<UnwrapAOSPayload<ErpGetBomByTrackingIdRes>> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<ErpGetBomByTrackingIdRes>> {
        return api.services.erp
            .getBomByTrackingId({ trackingId })({ timeout: 30_000 /* 30 seconds */ })
            .then((res) => {
                // Set bom list
                dispatch(bomSetAction(res.woBoms));

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return { woBoms: [], workInstructionsItems: {} };
            });
    };
}

/**
 * Edit a BOM item
 */
export function bomEditItem(
    followUp: UnwrapAOSPayload<BomFollowUpEditItemReq>
): StandardThunk<UnwrapAOSPayload<BomFollowUpEditItemRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpEditItemRes> | undefined> {
        return api.services.bomFollowUp
            .editItem(followUp)()
            .then((res) => {
                dispatch(bomItemEditedAction(res));

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}

/**
 * Edit a BOM item serialized
 */
export function bomEditSerializedItem(
    followUp: UnwrapAOSPayload<BomFollowUpEditSerializedItemReq>
): StandardThunk<UnwrapAOSPayload<BomFollowUpEditSerializedItemRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpEditSerializedItemRes> | undefined> {
        return api.services.bomFollowUp
            .editSerializedItem(followUp)()
            .then((res) => {
                dispatch(bomItemEditedAction(res));

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}

/**
 * Create a BOM follow-up
 */
export function bomInsertFollowUp(
    followUp: UnwrapAOSPayload<BomFollowUpInsertReq>
): StandardThunk<UnwrapAOSPayload<BomFollowUpInsertRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpInsertRes> | undefined> {
        return api.services.bomFollowUp
            .insert(followUp)()
            .then((res) => {
                if (followUp?.bomFollowUpId) {
                    dispatch(bomItemEditedAction(res));
                } else {
                    dispatch(bomItemInsertedAction(res));
                }

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}

/**
 * Create a BOM follow-up serialized item
 */
export function bomInsertFollowUpSerializedItem(
    followUp: UnwrapAOSPayload<BomFollowUpInsertSerializedItemReq>
): StandardThunk<UnwrapAOSPayload<BomFollowUpInsertSerializedItemRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpInsertSerializedItemRes> | undefined> {
        return api.services.bomFollowUp
            .insertSerializedItem(followUp)()
            .then((res) => {
                dispatch(bomSerializedItemInsertedAction(res));

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}

/**
 * Overconsume a BOM item
 */
export function bomOverconsumeItem(
    followUp: UnwrapAOSPayload<BomFollowUpOverconsumeItemReq>
): StandardThunk<UnwrapAOSPayload<BomFollowUpOverconsumeItemRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpOverconsumeItemRes> | undefined> {
        return api.services.bomFollowUp
            .overconsumeItem(followUp)()
            .then((res) => {
                dispatch(bomItemOverconsumedAction(res));

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}

/**
 * Overconsume a BOM item
 */
export function bomOverconsumeNonTraceableItem(
    followUp: UnwrapAOSPayload<BomFollowUpOverconsumeNonTraceableItemReq>
): StandardThunk<UnwrapAOSPayload<BomFollowUpOverconsumeNonTraceableItemRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpOverconsumeNonTraceableItemRes> | undefined> {
        return api.services.bomFollowUp
            .overconsumeNonTraceableItem(followUp)()
            .then((res) => {
                dispatch(bomItemInsertedAction(res));

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}

/**
 * Remove a BOM item
 */
export function bomRemoveItem(
    followUp: UnwrapAOSPayload<BomFollowUpRemoveItemReq>
): StandardThunk<UnwrapAOSPayload<BomFollowUpRemoveItemRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpRemoveItemRes> | undefined> {
        return api.services.bomFollowUp
            .removeItem(followUp)()
            .then(([...res]) => {
                dispatch(bomItemRemovedAction(res));

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}

/**
 * Remove a BOM item serialized
 */
export function bomRemoveSerializedItem(
    followUp: UnwrapAOSPayload<BomFollowUpRemoveSerializedItemReq>
): StandardThunk<UnwrapAOSPayload<BomFollowUpRemoveSerializedItemRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpRemoveSerializedItemRes> | undefined> {
        return api.services.bomFollowUp
            .removeSerializedItem(followUp)()
            .then((res) => {
                dispatch(bomItemRemovedAction([res]));

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}

/**
 * Replace a BOM item
 */
export function bomReplaceItem(
    followUp: UnwrapAOSPayload<BomFollowUpReplaceItemReq>
): StandardThunk<UnwrapAOSPayload<BomFollowUpReplaceItemRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpReplaceItemRes> | undefined> {
        return api.services.bomFollowUp
            .replaceItem(followUp)()
            .then(([...res]) => {
                dispatch(bomItemReplacedAction(res));

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}

/**
 * Replace a BOM item serialized
 */
export function bomReplaceSerializedItem(
    followUp: UnwrapAOSPayload<BomFollowUpReplaceSerializedItemReq>
): StandardThunk<UnwrapAOSPayload<BomFollowUpReplaceSerializedItemRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpReplaceSerializedItemRes> | undefined> {
        return api.services.bomFollowUp
            .replaceSerializedItem(followUp)()
            .then((res) => {
                dispatch(bomItemReplacedAction([res]));

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}

/**
 * Make a BOM item have multiple traceabilities
 */
export function bomMultipleTraceabilitiesItem(
    followUp: UnwrapAOSPayload<BomFollowUpMultipleTraceabilitiesItemReq>
): StandardThunk<UnwrapAOSPayload<BomFollowUpMultipleTraceabilitiesItemRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<BomFollowUpMultipleTraceabilitiesItemRes> | undefined> {
        return api.services.bomFollowUp
            .multipleTraceabilitiesItem(followUp)()
            .then(([...res]) => {
                dispatch(bomItemMultipleTraceabilitiesAction(res));

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}
