import { KortexDialogConfirmation, KortexTextField } from "@aos/react-components";
import { BomFollowUpSymptomId, ISerializedItem } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { CircularProgress, makeStyles } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";

import { BomFollowUpSymptomSelector } from "../../../BomFollowUpSymptomSelector";
import { serviceMap, ServiceVariant } from "../../utils";

const useStyles = makeStyles({
    dialogContent: {
        display: "grid",
        gridGap: "5px",
    },
    traceability: {
        display: "flex",
        alignItems: "center",
    },
    quantityTextField: {
        width: "150px",
    },
    item: {
        fontSize: "1rem",
    },
});

interface IOwnProps {
    onClose: () => void;
    open: boolean;
    serializedItem: ISerializedItem;
    variant: ServiceVariant;
    treeNodeId?: number;
    jobRefId?: string;
}

const RemoveSerializedItemDialog: FC<IOwnProps> = (props) => {
    const { onClose, open, serializedItem, variant, treeNodeId, jobRefId } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const translate = useTranslate();

    const [justification, setJustification] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [serialNumber, setSerialNumber] = useState<string>(serializedItem.serialNumber);
    const [bomFollowUpSymptomId, setBomFollowUpSymptomId] = useState<BomFollowUpSymptomId>(0);

    const confirmButtonDisabled = loading || serialNumber.length === 0 || justification.length < 3 || bomFollowUpSymptomId === 0;

    /**
     * Resets the fields when the dialog is opened
     */
    useEffect(() => {
        if (open) {
            setJustification("");
            setSerialNumber(serializedItem.serialNumber);
            setBomFollowUpSymptomId(0);
        }
    }, [open]);

    /**
     * Closes the dialog
     */
    const handleClose = (): void => {
        onClose();
        setSerialNumber("");
        setJustification("");
    };

    /**
     * Proceeds to replace the item
     */
    const handleConfirm = (): void => {
        setLoading(true);
        const {} = serializedItem;
        dispatch(
            serviceMap[variant].removeSerializedItem({
                bomFollowUpId: serializedItem.bomFollowUpId,
                bomFollowUpSerializedItemId: serializedItem.bomFollowUpSerializedItemId,
                bomFollowUpSymptomId,
                justification,
            })
        )
            .then(handleClose)
            .finally(() => {
                setLoading(false);
            });
    };

    /**
     * Updates the justification (inner state)
     */
    const handleJustificationChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setJustification(event.target.value);
    };

    /**
     * Updates symptom of the changement
     */
    const handleBomFollowUpSymptomChange = (symptomId: BomFollowUpSymptomId): void => {
        setBomFollowUpSymptomId(symptomId);
    };

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            confirmDisabled={confirmButtonDisabled}
            onCancel={handleClose}
            onConfirm={handleConfirm}
            open={open}
            textLabels={{
                cancelButtonLabel: translate("general.cancel"),
                proceedButtonLabel: translate("general.confirm"),
                titleLabel: translate("bomPage.bomTable.replacement"),
            }}
            textLabelsIcons={{
                proceedButtonIcon: loading ? <CircularProgress /> : undefined,
            }}
        >
            <div className={classes.dialogContent}>
                {/* SERIAL NUMBER */}
                <div className={classes.traceability}>
                    <KortexTextField
                        label={translate("bomPage.bomTable.serialNumber")}
                        TextFieldProps={{
                            autoComplete: "off",
                            id: "replacementSerializedItemDialogSerialNumberId",
                            disabled: true,
                        }}
                        value={serializedItem.serialNumber}
                        variant="outlined"
                    />
                </div>

                {/* BOM FOLLOW-UP SYMPTOMS */}
                <BomFollowUpSymptomSelector
                    classes={{ item: classes.item }}
                    onChange={handleBomFollowUpSymptomChange}
                    treeNodeId={treeNodeId}
                    jobRefId={jobRefId}
                    value={bomFollowUpSymptomId}
                    KortexTextFieldProps={{ required: true }}
                />

                {/* JUSTIFICATION */}
                <KortexTextField
                    label={translate("bomPage.bomTable.justification")}
                    onChange={handleJustificationChange}
                    TextFieldProps={{
                        autoComplete: "off",
                        id: "replacementSerializedItemDialogJustificationId",
                        multiline: true,
                        rows: 5,
                        required: true,
                    }}
                    value={justification}
                    variant="outlined"
                />
            </div>
        </KortexDialogConfirmation>
    );
};

export default RemoveSerializedItemDialog;
