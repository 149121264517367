import {
    IProcessActionDbModel,
    OrUndefined,
    ProcessAction,
    ProcessActionSettingsDbModel,
    ProcessActionStep,
    ProcessActionType,
    ProcessUiModel,
    TreeNodeDbModel,
} from "@kortex/aos-common";

import { BareAction, ComplexAction, Unpack } from "../app.types";

export interface ICopiedActionStep {
    actionType: ProcessActionType;
    actionStep: ProcessActionStep;
}

export enum ProcessActionTypeRedux {
    CLEAR_PROCESSES = "@@PROCESS/CLEAR_PROCESSES",
    DELETE_PROCESS_ACTION = "@@PROCESS/DELETE_PROCESS_ACTION",
    DELETE_PROCESS_ACTION_STEP = "@@PROCESS/DELETE_PROCESS_ACTION_STEP",
    INSERT_PROCESS = "@@PROCESS/INSERT_PROCESS",
    INSERT_PROCESS_ACTION = "@@PROCESS/UPDATE_PROCESS_INSERT",
    INSERT_PROCESS_ACTION_STEP = "@@PROCESS/INSERT_PROCESS_ACTION_STEP",
    SET_COPIED_PROCESS_ACTION = "@@PROCESS/SET_COPIED_PROCESS_ACTION",
    SET_COPIED_PROCESS_ACTION_STEP = "@@PROCESS/SET_COPIED_PROCESS_ACTION_STEP",
    SET_EDITED_PROCESS_ID = "@@PROCESS/SET_EDITED_PROCESS_ID",
    SET_PROCESS_ACTION_SETTINGS = "@@PROCESS/SET_PROCESS_ACTION_SETTINGS",
    SET_PROCESSES = "@@PROCESS/SET_PROCESSES",
    UPDATE_PROCESS = "@@PROCESS/UPDATE_PROCESS",
    UPDATE_PROCESS_ACTION = "@@PROCESS/UPDATE_PROCESS_ACTION",
    UPDATE_PROCESS_ACTION_STEP = "@@PROCESS/UPDATE_PROCESS_ACTION_STEP",
    UPDATE_PROCESSES = "@@PROCESS/UPDATE_PROCESSES",
    UPDATE_PROCESS_ACTION_SETTINGS = "@@PROCESS/UPDATE_PROCESS_ACTION_SETTINGS",
    UPDATE_PROCESS_LOOKUP_FROM_TREENODEID = "@@PROCESS/UPDATE_PROCESS_LOOKUP_FROM_TREENODEID",
    UPDATE_PROCESSES_LOOKUP_FROM_TREENODESID = "@@PROCESS/UPDATE_PROCESSES_LOOKUP_FROM_TREENODESID",
}

interface IProcessState {
    copiedActions: Pick<ProcessAction, "steps">[]; // TODO: semble ne pas être utilisé.
    copiedActionStep: OrUndefined<ICopiedActionStep>;
    editedProcessId: OrUndefined<ProcessUiModel["processId"]>;
    processes: ProcessUiModel[];
    settings: ProcessActionSettingsDbModel[]; // TODO: semble ne pas être utilisé
    processFromTreeNodeIdLookupTable: TreeNodeDbModel["treeNodeId"][];
}

export type ProcessState = IProcessState;

export type ClearProcesses = BareAction<ProcessActionTypeRedux.CLEAR_PROCESSES>;
export type ProcessActionDeletedAction = ComplexAction<ProcessActionTypeRedux.DELETE_PROCESS_ACTION, IProcessActionDbModel[]>;
export type ProcessActionStepDeletedAction = ComplexAction<ProcessActionTypeRedux.DELETE_PROCESS_ACTION_STEP, IProcessActionDbModel>;
export type ProcessActionInsertedAction = ComplexAction<
    ProcessActionTypeRedux.INSERT_PROCESS_ACTION,
    Unpack<IProcessState["processes"]>["actions"]
>;
export type ProcessActionStepInsertedAction = ComplexAction<ProcessActionTypeRedux.INSERT_PROCESS_ACTION_STEP, IProcessActionDbModel>;
export type ProcessActionUpdatedAction = ComplexAction<
    ProcessActionTypeRedux.UPDATE_PROCESS_ACTION,
    Unpack<IProcessState["processes"]>["actions"]
>;
export type ProcessActionStepUpdatedAction = ComplexAction<ProcessActionTypeRedux.UPDATE_PROCESS_ACTION_STEP, IProcessActionDbModel>;
export type ProcessInsertedAction = ComplexAction<ProcessActionTypeRedux.INSERT_PROCESS, Unpack<IProcessState["processes"]>>;
export type ProcessUpdatedAction = ComplexAction<ProcessActionTypeRedux.UPDATE_PROCESS, Unpack<IProcessState["processes"]>>;
export type ProcessesUpdatedAction = ComplexAction<ProcessActionTypeRedux.UPDATE_PROCESSES, IProcessState["processes"]>;
export type ProcessActionSettingsUpdatedAction = ComplexAction<
    ProcessActionTypeRedux.UPDATE_PROCESS_ACTION_SETTINGS,
    Unpack<IProcessState["settings"]>
>;
export type SetCopiedProcessActionAction = ComplexAction<ProcessActionTypeRedux.SET_COPIED_PROCESS_ACTION, IProcessState["copiedActions"]>;
export type SetCopiedProcessActionStepAction = ComplexAction<
    ProcessActionTypeRedux.SET_COPIED_PROCESS_ACTION_STEP,
    IProcessState["copiedActionStep"]
>;
export type SetEditedProcessIdAction = ComplexAction<ProcessActionTypeRedux.SET_EDITED_PROCESS_ID, IProcessState["editedProcessId"]>;
export type SetProcessActionSettingsAction = ComplexAction<ProcessActionTypeRedux.SET_PROCESS_ACTION_SETTINGS, IProcessState["settings"]>;
export type SetProcessesAction = ComplexAction<ProcessActionTypeRedux.SET_PROCESSES, IProcessState["processes"]>;
export type ProcessLookupTableFromTreeNodeIdUpdated = ComplexAction<
    ProcessActionTypeRedux.UPDATE_PROCESS_LOOKUP_FROM_TREENODEID,
    Unpack<TreeNodeDbModel["treeNodeId"]>
>;
export type ProcessesLookupTableFromTreeNodesIdUpdated = ComplexAction<
    ProcessActionTypeRedux.UPDATE_PROCESSES_LOOKUP_FROM_TREENODESID,
    IProcessState["processFromTreeNodeIdLookupTable"]
>;

export type ProcessActions =
    | ClearProcesses
    | ProcessActionDeletedAction
    | ProcessActionStepDeletedAction
    | ProcessActionInsertedAction
    | ProcessActionStepInsertedAction
    | ProcessActionUpdatedAction
    | ProcessActionStepUpdatedAction
    | ProcessInsertedAction
    | ProcessUpdatedAction
    | ProcessesUpdatedAction
    | ProcessActionSettingsUpdatedAction
    | SetCopiedProcessActionAction
    | SetCopiedProcessActionStepAction
    | SetEditedProcessIdAction
    | SetProcessActionSettingsAction
    | SetProcessesAction
    | ProcessLookupTableFromTreeNodeIdUpdated
    | ProcessesLookupTableFromTreeNodesIdUpdated;
