import { IAllConnectionInfo, IConnectionListChangedNotif, LocationKeys } from "@kortex/aos-common";

import { BareAction, ComplexAction } from "../app.types";

export interface IGeneralState {
    clientId: string;
    uplink: {
        authenticated: boolean;
        state: ConnectionState;
        reconnecting: boolean;
    };
    connectionList: IAllConnectionInfo;
    currentPage: string;
    desktopUpdateReady: boolean;
    language: LocationKeys;
    mobileOpen: boolean;
    timezoneOffset: number;
}

export enum ConnectionState {
    NONE = 0,
    CONNECTING,
    CONNECTED,
    READY,
    CLOSING,
    CLOSED,
}

export enum EnumPageTypes {
    BOM_FOLLOW_UP = "bomFollowUp",
    HOME = "home",
    INFO = "info",
    KANBAN = "kanban",
    LOGIN = "login",
    NO_PAGE = "noPage",
    OPERATOR_SCHEDULER = "operator",
    PASSWORD = "password",
    PROCESS = "process",
    REPORTS = "reports",
    REWORKS = "reworks",
    SCHEDULER = "scheduler",
    SETTINGS = "settings",
    SETTINGS_GLOBALS = "settingsGlobals",
    SETTINGS_GROUPS = "settingsGroups",
    SETTINGS_ROLES = "settingsRoles",
    SETTINGS_TAGS = "settingsTags",
    SETTINGS_USERS = "settingsUsers",
    STORAGE_MANAGER = "storage",
    TASKS = "tasks",
    TRAINING = "training",
}

export enum GeneralActionType {
    TOGGLE_APP_DRAWER = "@@GENERAL/TOGGLE_APP_DRAWER",
    SET_ROUTE = "@@GENERAL/SET_ROUTE",
    SET_UPDATE_READY = "@@GENERAL/SET_UPDATE_READY",
    SET_LANGUAGE = "@@GENERAL/SET_LANGUAGE",
    SET_ALL_CONNECTION_INFO = "@@GENERAL/SET_ALL_CONNECTION_INFO",
    SET_CONNECTION_LIST_CHANGED_ACTION = "@@GENERAL/SET_CONNECTION_LIST_CHANGED_ACTION",
    UPLINK_CONNECTED = "@@GENERAL/UPLINK_CONNECTED",
    UPLINK_CONNECTING = "@@GENERAL/UPLINK_CONNECTING",
    UPLINK_CLOSING = "@@GENERAL/UPLINK_CLOSING",
    UPLINK_CLOSED = "@@GENERAL/UPLINK_CLOSED",
    UPLINK_NONE = "@@GENERAL/UPLINK_NONE",
    UPLINK_READY = "@@GENERAL/UPLINK_READY",
    UPLINK_AUTHENTICATED = "@@GENERAL/UPLINK_AUTHENTICATED",
    RECONNECTING = "@@GENERAL/RECONNECTING",
    RECONNECTING_FAILED = "@@GENERAL/RECONNECTING_FAILED",
}

export type GeneralState = IGeneralState;

export type SetConnectionListAction = ComplexAction<GeneralActionType.SET_ALL_CONNECTION_INFO, IAllConnectionInfo>;
export type ToggleAppDrawerAction = ComplexAction<GeneralActionType.TOGGLE_APP_DRAWER, boolean>;
export type SetRouteAction = ComplexAction<GeneralActionType.SET_ROUTE, string>;
export type SetUpdateReadyAction = ComplexAction<GeneralActionType.SET_UPDATE_READY, boolean>;
export type SetLanguageAction = ComplexAction<GeneralActionType.SET_LANGUAGE, LocationKeys>;
export type SetConnectionListChangedAction = ComplexAction<
    GeneralActionType.SET_CONNECTION_LIST_CHANGED_ACTION,
    IConnectionListChangedNotif
>;
export type UplinkAuthenticated = ComplexAction<GeneralActionType.UPLINK_AUTHENTICATED, boolean>;
export type UplinkClosed = BareAction<GeneralActionType.UPLINK_CLOSED>;
export type UplinkClosing = BareAction<GeneralActionType.UPLINK_CLOSING>;
export type UplinkConnected = BareAction<GeneralActionType.UPLINK_CONNECTED>;
export type UplinkConnecting = BareAction<GeneralActionType.UPLINK_CONNECTING>;
export type UplinkNone = BareAction<GeneralActionType.UPLINK_NONE>;
export type UplinkReady = BareAction<GeneralActionType.UPLINK_READY>;
export type SetReconnecting = BareAction<GeneralActionType.RECONNECTING>;
export type ReconnectingFailed = BareAction<GeneralActionType.RECONNECTING_FAILED>;

export type GeneralActions =
    | SetConnectionListAction
    | ToggleAppDrawerAction
    | SetRouteAction
    | SetUpdateReadyAction
    | SetLanguageAction
    | SetConnectionListChangedAction
    | UplinkAuthenticated
    | UplinkClosed
    | UplinkClosing
    | UplinkConnected
    | UplinkConnecting
    | UplinkNone
    | UplinkReady
    | SetReconnecting
    | ReconnectingFailed;
